export const LEDGERS = "Ledgers"
export const ADD_LEDGER  = "Add Ledger"
export const EDIT_LEDGER  = "Edit Ledger"
export const SAVE = "Save Ledger"
export const DELETE_LEDGER = "Delete Ledger";

export const FETCH = "Fetch"


export const LEDGER_TABLE_CELLS = [
    { id: 'name',label: 'Name',align: "left"},
    { id: 'balance',label: 'Balance',align: "left"},
    { id: 'details',label: ''},
];

export const CARRIER_TABLE_CELLS = [
    {id: 'carrierName', label: 'Standard Carrier', align: 'left'},
    {id: 'CY_SUBTOTAL_PAYABLE', label: 'Amount', align: 'left'},
];

export const FTL_CARRIER_TABLE_CELLS = [
    {id: 'carrierName', label: 'LTL/FTL Carrier', align: 'left'},
    {id: 'CY_SUBTOTAL_PAYABLE', label: 'Amount', align: 'left'},
];

export const SUPPLIER_TABLE_CELLS = [
    {id: 'supplierName', label: 'Supplier', align: 'left'},
    {id: 'CY_SUBTOTAL_PAYABLE', label: 'Amount', align: 'left'},
];

export const STOCK_IN_HAND_TABLE_CELLS = [
    {id: 'name', label: 'Stock', align: 'left'},
    {id: 'balance', label: 'Amount', align: 'left'},
];

export const LEDGER_ENTRIES_CELLS = [

    {id: 'date', label: 'Date', align: "left"},
    { id: 'description',label: 'Description',align: "left"},
    { id: 'debit',label: 'Debit',align: "left"},
    { id: 'credit',label: 'Credit',align: "left"},
    { id: 'balance',label: 'Balance',align: "center"},

];

export const LEDGER_PAYABLE_ENTRIES_CELLS = [
     {id: 'description', label: 'Other', align: "left"},
     { id: 'credit',label: 'Amount',align: "left"},
];


export const LEDGERS_PAGES = {
    TABLE: "table",
    EDIT: "Edit Ledgers",
    SETTINGS: "Settings",

}

export const SOURCE_OPTIONS = [
    "Transactions",
    "",
];

export const SUM_RULE_OPTIONS = [
    "Sum of Subtotal",
    "Sum of Taxes",
    "Sum of Total",
];

export const CARRY_FORWARD_OPTIONS = [
    "Yes",
    "No",
];

// tooltip names
export const BACK = "Back to Menu"
