import React, {useEffect, useState} from 'react';

// custom components
import MarketplaceStepper from "./MarketplaceStepper";
import SettingsToolbar from "../SettingsToolbar";
// material components
import {
    Button,
} from "@material-ui/core";

// constants
import {
    BACK,
    CREATE_MARKETPLACE,
    CANCEL,
    MARKETPLACE_STEPPER_LABELS,
    NEXT, VALIDATE_CREDENTIALS
} from "./constants";

// styling
import styles from './CreateMarketplaceDialog.module.css';
import PropTypes from "prop-types";

function CreateMarketplaceDialog(props) {
    const [state, setState] = useState({
        error: false,
        errorMessage: '',
        activeStep: 0,
        isDisabled: true,
        nextDisabled: true,
        marketplaceName: props.selected.marketplaceName,
        marketplaceCompany: props.selected.marketplaceCompany,
        marketplaceCountry: '',
        marketplaceEnabled: props.selected.marketplaceEnabled,
        url: '',
        sellerId: '',
        key: '',
        token: '',
        consumerChannelType: '',
        applicationName: "",
        lwaClientId: "",
        lwaClientSecret: "",
        externalMarketplaceId: "",
        refreshToken:"",
        endPoint:"",
        expiryDate:"",

        routeValidate: '',
        routeUpdate: '',
        routeFetch: '',

        isValid: false
        // errors: {
        //     Marketplace: false,
        //     defaultService: false,
        //     nickname: false,
        //     username: false,
        //     password: false,
        //     accountNumber: false,
        //     addressLine1: false,
        //     addressLine2: false,
        //     city: false,
        //     state: false,
        //     country: false,
        //     companyName: false,
        //     contactName: false,
        //     email: false,
        //     phone: false,
        //     name: false,
        //     postalCode: false
        // }
    });
    const [initialState, setInitialState] = useState(state)
    // const whiteList = ['activeStep', 'isAddress', 'isDisabled', 'nextDisabled', 'errors', 'error', 'address', 'meterNumber', 'errorMessage', 'addressLine2'];
    //
    // const requiredList0 = ['Marketplace', 'defaultService'];
    // const requiredList1 = ['username', 'password', 'accountNumber'];
    // const requiredList1F = ['username', 'password', 'accountNumber', 'meterNumber'];
    // const requiredList2 = ['name'];
    useEffect(() => {
        if (props.isEdit) {
            setState({
                ...state,
                marketplaceName: props.selected.marketplaceName,
                marketplaceCompany: props.selected.marketplaceCompany,
                marketplaceCountry: props.selected.marketplaceCountry,
                marketplaceEnabled: props.selected.marketplaceEnabled,
                url: props.selected.url,
                sellerId: props.selected.sellerId,
                key: props.selected.key,
                token: props.selected.token,
                consumerChannelType: props.selected.consumerChannelType,
                lwaClientId: props.selected.lwaClientId,
                applicationName: props.selected.applicationName,
                lwaClientSecret: props.selected.lwaClientSecret,
                externalMarketplaceId: props.selected.externalMarketplaceId,
                refreshToken: props.selected.refreshToken,
                endPoint: props.selected.endPoint,
                expiryDate: props.selected.expiryDate,

                routeValidate: props.selected.routeValidate,
                routeUpdate: props.selected.routeUpdate,
                routeFetch: props.selected.routeFetch,
            });
        }
    }, [props.isEdit])

    const handleChange = (e) => {
        setState({
            ...state,
            [e.target.name]: e.target.value,
        })
    }
    const handleCheckboxChange = (e) => {
        setState({
            ...state,
            [e.target.name]: e.target.checked,
        })
    }

    const handleAutocompleteChange = (value, name) => {
        setState({
            ...state,
            [name]: value ?? '',
        })
    }

    const checkNext = () => {
        if(state.activeStep===0){
            if (state.marketplaceCompany && state.marketplaceName) {
                switch(state.marketplaceCompany) {
                    case "Custom Marketplace":
                        return !state.url
                    case "eBay":
                    case "Amazon":
                        return !state.marketplaceCountry
                    case "Walmart":
                        return !state.marketplaceCountry
                    default:
                        return true
                }
            }
            return true
        }

        if(state.activeStep===1){
            let otherfieldChanged =
                    state.marketplaceName != props.selected.marketplaceName||
                    state.marketplaceCompany != props.selected.marketplaceCompany||
                    state.marketplaceCountry != props.selected.marketplaceCountry||
                    state.url != props.selected.url||
                    state.sellerId != props.selected.sellerId||
                    state.key != props.selected.key||
                    state.token != props.selected.token||
                    state.consumerChannelType != props.selected.consumerChannelType||
                    state.lwaClientId != props.selected.lwaClientId||
                    state.applicationName != props.selected.applicationName||
                    state.lwaClientSecret != props.selected.lwaClientSecret||
                    state.externalMarketplaceId != props.selected.externalMarketplaceId||
                    state.refreshToken != props.selected.refreshToken||
                    state.endPoint != props.selected.endPoint||
                    state.expiryDate != props.selected.expiryDate||
                    state.routeValidate != props.selected.routeValidate||
                    state.routeUpdate != props.selected.routeUpdate ||
                    state.routeFetch != props.selected.routeFetch

            if(!otherfieldChanged && state.marketplaceEnabled != props.selected.marketplaceEnabled){
                return false
            }
            return !state.isValid
        }

        return false
    }

    const handleStepNext = () => {
        if (state.activeStep === MARKETPLACE_STEPPER_LABELS.length - 1) {
            props.handleClose();
        } else {
            setState({
                ...state,
                activeStep: state.activeStep + 1,
                isDisabled: false,
                error: false
            })
        }
    }

    const handleStepBack = () => {
        if (state.activeStep === 1) {
            setState({
                ...state,
                isDisabled: true,
                activeStep: state.activeStep - 1
            })
        } else {
            setState({
                ...state,
                activeStep: state.activeStep - 1
            })
        }
    }

    const handleCancel = () => {
        setState(initialState)
        props.handleClose();
    }

    const handleSave = () => {
        const errors = {...state.errors};
        const keys = Object.keys(state);
        let error = false;

        for (let i = 0; i < keys.length; i++) {
            //TODO add errors for marketplaces

            // if (!whiteList.includes(keys[i]) && state[keys[i]]?.length === 0) {
            //     error = true;
            //     errors[keys[i]] = true;
            // }
        }

        if (error) {
            setState({
                ...state,
                errors: errors,
                error: true,
                errorMessage: JSON.stringify(errors)
            })
        } else {
            const data = {
                marketplaceId: props.marketplaceId,
                marketplaceName: state.marketplaceName,
                marketplaceCompany: state.marketplaceCompany,
                marketplaceCountry: state.marketplaceCountry,
                marketplaceEnabled: state.marketplaceEnabled,
                key: state.key,
                sellerId: state.sellerId,
                token: state.token,
                consumerChannelType: state.consumerChannelType,

                routeValidate: state.routeValidate,
                routeUpdate: state.routeUpdate,
                routeFetch: state.routeFetch,

                url: state.url,
                companyId: props.company,
                lwaClientId: state.lwaClientId,
                applicationName: state.applicationName,
                lwaClientSecret: state.lwaClientSecret,
                externalMarketplaceId: state.externalMarketplaceId,
                refreshToken: state.refreshToken,
                endPoint: state.endPoint,
                expiryDate: state.expiryDate,
            }

            if (props.isEdit) {
                props.editMarketplace(data, () => window.location.reload())
            } else {
                props.createMarketplace(data, handleCancel)
            }
        }

    }

    const handleBack = () => {
        if(state.activeStep === 0){
            handleCancel();
        }else{
            handleStepBack();
        }

    }

    const handleValidate = () => {
        const data = {
            marketplaceId: props.marketplaceId,
            marketplaceName: state.marketplaceName,
            marketplaceCompany: state.marketplaceCompany,
            marketplaceCountry: state.marketplaceCountry,
            marketplaceEnabled: state.marketplaceEnabled,
            key: state.key,
            sellerId: state.sellerId,
            token: state.token,
            consumerChannelType: state.consumerChannelType,

            routeValidate: state.routeValidate,
            routeUpdate: state.routeUpdate,
            routeFetch: state.routeFetch,

            url: state.url,
            companyId: props.company,
            lwaClientId: state.lwaClientId,
            applicationName: state.applicationName,
            lwaClientSecret: state.lwaClientSecret,
            externalMarketplaceId: state.externalMarketplaceId,
            refreshToken: state.refreshToken,
            endPoint: state.endPoint,
            expiryDate: state.expiryDate,
        }
        const setIsValid = (isValid) => {
            setState({
                ...state,
                isValid
            })
        }
        props.handleValidate(data, setIsValid)
    }

    const checkValidation = () => {
        switch(state.marketplaceCompany) {
            case "Amazon":
                return (!state.marketplaceCountry || !state.externalMarketplaceId || !state.sellerId
                        || !state.applicationName || !state.refreshToken || !state.lwaClientId || !state.lwaClientSecret
                        || !state.endPoint || !state.expiryDate)
            case "eBay":
                return (!state.marketplaceCountry || !state.url)
            case "Walmart":
                if (state.marketplaceCountry == "Canada"){
                    return (!state.marketplaceCountry || !state.key || !state.sellerId || !state.consumerChannelType)
                }
                else{
                    return (!state.marketplaceCountry || !state.key || !state.sellerId)
                }
            case "Custom Marketplace":
                return (!state.token || !state.sellerId || !state.url || !state.routeValidate || !state.routeFetch || !state.routeUpdate)
            default:
                return true
        }
    }

    //TODO figure out how we want to save this url + how we can send them to the right link (prod vs dev)
    const openEbay = () => {
       window.open("https://auth.sandbox.ebay.com/oauth2/authorize?client_id=ExponetC-exponetw-SBX-8a5965275-615ae36e&response_type=code&redirect_uri=Exponet_Canada_-ExponetC-expone-vekwcoerb&scope=https://api.ebay.com/oauth/api_scope https://api.ebay.com/oauth/api_scope/buy.order.readonly https://api.ebay.com/oauth/api_scope/buy.guest.order https://api.ebay.com/oauth/api_scope/sell.marketing.readonly https://api.ebay.com/oauth/api_scope/sell.marketing https://api.ebay.com/oauth/api_scope/sell.inventory.readonly https://api.ebay.com/oauth/api_scope/sell.inventory https://api.ebay.com/oauth/api_scope/sell.account.readonly https://api.ebay.com/oauth/api_scope/sell.account https://api.ebay.com/oauth/api_scope/sell.fulfillment.readonly https://api.ebay.com/oauth/api_scope/sell.fulfillment https://api.ebay.com/oauth/api_scope/sell.analytics.readonly https://api.ebay.com/oauth/api_scope/sell.marketplace.insights.readonly https://api.ebay.com/oauth/api_scope/commerce.catalog.readonly https://api.ebay.com/oauth/api_scope/buy.shopping.cart https://api.ebay.com/oauth/api_scope/buy.offer.auction https://api.ebay.com/oauth/api_scope/commerce.identity.readonly https://api.ebay.com/oauth/api_scope/commerce.identity.email.readonly https://api.ebay.com/oauth/api_scope/commerce.identity.phone.readonly https://api.ebay.com/oauth/api_scope/commerce.identity.address.readonly https://api.ebay.com/oauth/api_scope/commerce.identity.name.readonly https://api.ebay.com/oauth/api_scope/commerce.identity.status.readonly https://api.ebay.com/oauth/api_scope/sell.finances https://api.ebay.com/oauth/api_scope/sell.item.draft https://api.ebay.com/oauth/api_scope/sell.payment.dispute https://api.ebay.com/oauth/api_scope/sell.item https://api.ebay.com/oauth/api_scope/sell.reputation https://api.ebay.com/oauth/api_scope/sell.reputation.readonly")
    }

    return(
        <div className={styles.marketplace_stepper_align}>
            <div>
                <SettingsToolbar
                    title={props.title}
                    selected={props.selected}
                    handleDeleteOpen={props.handleDeleteOpen}
                    handleDeleteClose={props.handleDeleteClose}
                    handleDelete={props.handleDelete}
                    deleteOpen={props.deleteOpen}
                    handleBack={handleBack}
                    addingOrEditing={props.addingOrEditing}
                />
                <MarketplaceStepper
                    handleChange={handleChange}
                    handleAutocompleteChange={handleAutocompleteChange}
                    handleCheckboxChange={handleCheckboxChange}
                    addresses={props.addresses}
                    parentState={state}
                    handleClose={props.handleClose}
                    activeStep={state.activeStep}
                    isEdit={props.isEdit}
                    openEbay={openEbay}
                />
            </div>

            <div>
                <Button
                    variant="contained"
                    onClick={handleCancel}
                    className={styles.marketplace_stepper_btn}

                >
                    {CANCEL}
                </Button>
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleStepBack}
                    disabled={state.isDisabled}
                >
                    {BACK}
                </Button>
                {state.activeStep === MARKETPLACE_STEPPER_LABELS.length - 1 ?
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleValidate}
                        className={styles.marketplace_stepper_btn}
                        disabled={checkValidation()}
                        >
                        {VALIDATE_CREDENTIALS}
                    </Button> : null }
                <Button
                    variant="contained"
                    color="primary"
                    onClick={state.activeStep === MARKETPLACE_STEPPER_LABELS.length - 1 ? handleSave : handleStepNext}
                    className={styles.marketplace_stepper_btn}
                    disabled={checkNext()}
                >
                    {state.activeStep === MARKETPLACE_STEPPER_LABELS.length - 1 ? CREATE_MARKETPLACE : NEXT}
                </Button>
            </div>
        </div>
    )

}

CreateMarketplaceDialog.propTypes = {
    open: PropTypes.bool,
    isEdit: PropTypes.bool,
    selectedAddress: PropTypes.object,
    selectedMarketplace: PropTypes.object,
    marketplaceId: PropTypes.string,
    shipAddressId: PropTypes.string,
    handleClose: PropTypes.func,
    addresses: PropTypes.array,
    createMarketplace: PropTypes.func,
    editMarketplace: PropTypes.func,
    company: PropTypes.string,
    handleValidate: PropTypes.func,
}

CreateMarketplaceDialog.defaultProps = {
    open: false,
    isEdit: false,
    selectedAddress: {},
    selectedMarketplace: {},
    marketplaceId: "",
    shipAddressId: "",
    handleClose: ()=>{},
    addresses: [],
    createMarketplace: ()=>{},
    editMarketplace: ()=>{},
    company: "",
    handleValidate: ()=>{}
}

export default CreateMarketplaceDialog;