import {GET_TAXTYPE_WITH_STATE_PROVINCE} from "../../../../redux/constants/accounting";
import {LIST_FTL_CARRIER_INVOICE_TEMPLATES} from "../../../../redux/constants/fullTruckloadCarriers";
import {GET_INVENTORY_SUMMARY_FOR_FTL} from "../../../../redux/constants/inventory";
import {RESOLVE_ALERT} from "../../../../redux/constants/alerts";
import {FETCH_LISTINGS_PRICE} from "../../../../redux/constants/settings";


export const BASE_URL = process.env.REACT_APP_API_ENDPOINT

export const AUTHENTICATION = {
    GET_USER_INFO: `${BASE_URL}/account/getUser`,
    AUTH_LOGIN: `${BASE_URL}/api/login`,
    AUTH_LOGOUT: `${BASE_URL}/api/logout`,
    AUTH_REGISTER: `${BASE_URL}/register/save`,
    GET_USERS: `${BASE_URL}/account/getUsers`
};
export const ACCOUNT = {
    GET_USER_SETTING: `${BASE_URL}/account/getUserSetting`,
    SAVE_USER_SETTING: `${BASE_URL}/account/saveUserSetting`,
    GET_USER_TICKET_TABLE_FILTER: `${BASE_URL}/account/getTicketTableFilter`,
    UPDATE_USER_TICKET_TABLE_FILTER: `${BASE_URL}/account/updateTicketTableFilter`,
};
export const ADMIN = {
    REFRESH_ORDER_ITEMS: `${BASE_URL}/order/refreshOrderItems`,
    LIST_ORDERS: `${BASE_URL}/order/list`,
    FETCH_ORDERS_BY_ORDER_ID: `${BASE_URL}/order/fetchOrdersByOrderId`,
    GET_MARKETPLACE_NAMES_BY_COMPANY: `${BASE_URL}/order/getMarketplaceNamesByCompany`,
    GET_MARKETPLACE_COMPANIES: `${BASE_URL}/order/getMarketplaceCompanies`
};
export const AUTOMATION_RULES = {
    CREATE_RULE: `${BASE_URL}/rule/create`,
    LIST_ALL_RULES: `${BASE_URL}/rule/list`,
    EDIT_RULE: `${BASE_URL}/rule/edit`,
    COPY_RULE: `${BASE_URL}/rule/copy`,
    DELETE_RULE: `${BASE_URL}/rule/delete`,
    PREPARE_EDIT_RULE: `${BASE_URL}/rule/prepEdit`,
    APPLY_RULE: `${BASE_URL}/rule/apply`,
    UPDATE_PROFIT_SETTINGS: `${BASE_URL}/rule/updateProfitSettings`,
    GET_PROFIT_SETTINGS: `${BASE_URL}/rule/getProfitSettings`,
    APPLY_TRANSACTIONS_RULES: `${BASE_URL}/rule/applySelectedTransactionsRules`,
};

export const ORDERS = {
    LIST_UNSHIPPED: `${BASE_URL}/shipment/listUnshipped`,
    LIST_SHIPPED: `${BASE_URL}/shipment/listShipped`,
    LIST_CANCELLED: `${BASE_URL}/shipment/listCanceled`,
    LIST_RETURNS: `${BASE_URL}/shipment/listReturns`,
    LIST_CLONED: `${BASE_URL}/shipment/listCloned`,
    LIST_BY_ORDER_ID: `${BASE_URL}/shipment/listByOrderId`,
    LIST_BY_TRACKING_ID: `${BASE_URL}/shipment/listByTrackingId`,
    FETCH_ALL_SHIPMENTS: `${BASE_URL}/shipment/listAll`,
    PRINT_ORDER: `${BASE_URL}/shipment/print`,
    PRINT_TO_PDF: `${BASE_URL}/shipment/printToPdf`,
    EDIT_ORDER: `${BASE_URL}/shipment/edit`,
    EDIT_MULTIPLE_ORDER: `${BASE_URL}/shipment/editMultipleShipments`,
    EDIT_CARRIER: `${BASE_URL}/shipment/editCarrier`,
    CANCEL_ORDER: `${BASE_URL}/shipment/cancel`,
    CREATE_ORDER: `${BASE_URL}/shipment/custom`,
    CREATE_RETURN_SHIPMENTS: `${BASE_URL}/shipment/createReturnShipments`,
    UPDATE_SHIPMENTS: `${BASE_URL}/shipment/update`,
    DELETE_SHIPMENTS: `${BASE_URL}/shipment/delete`,
    RECREATE_ORDERS: `${BASE_URL}/shipment/reCreate`,
    REPRINT_ORDER: `${BASE_URL}/shipment/reprint`,
    ARCHIVE_LABEL: `${BASE_URL}/shipment/archiveLabel`,
    ARCHIVE_LABELS: `${BASE_URL}/shipment/archiveLabels`,
    VOID_ORDER: `${BASE_URL}/shipment/voidShipment`,
    ADD_PACKAGE: `${BASE_URL}/shipment/addPackage`,
    COPY_PACKAGE: `${BASE_URL}/shipment/copyPackage`,
    DELETE_PACKAGE: `${BASE_URL}/shipment/deletePackage`,
    GET_DETAIL: `${BASE_URL}/shipment/detail`,
    EDIT_FROM_ADDRESS: `${BASE_URL}/shipment/editFromAddress`,
    RATE_SHIPMENT: `${BASE_URL}/shipment/rate`,
    TRACK_SHIPMENT: `${BASE_URL}/shipment/trackShipment`,
    UPLOAD_SHIPMENT: `${BASE_URL}/shipment/upload`,
    RATE_SHIPMENT_DEFAULT: `${BASE_URL}/shipment/rateDefault`,
    RATE_SHIPMENT_CHEAPEST: `${BASE_URL}/shipment/rateCheapest`,
    VALIDATE_ADDRESS: `${BASE_URL}/shipment/validateAddress`,
    EDIT_SHIPMENT_CARRIER: `${BASE_URL}/shipment/editShipmentCarrier`,
    ADD_COMMODITY: `${BASE_URL}/shipment/addCommodity`,
    DELETE_COMMODITY: `${BASE_URL}/shipment/deleteCommodity`,
    GET_SHIPMENT_COMMERCIAL_INVOICE: `${BASE_URL}/shipment/getShipmentCommercialInvoice`,
    UPDATE_BOX_TYPE: `${BASE_URL}/shipment/changeBoxType`,
    MARK_AS_SHIPPED: `${BASE_URL}/shipment/markAsShipped`,
    MARK_AS_UNSHIPPED: `${BASE_URL}/shipment/markAsUnshipped`,
    MARK_AS_REDIRECTED: `${BASE_URL}/shipment/markAsRedirected`,
    MARK_AS_CANCELLED: `${BASE_URL}/shipment/markAsCancelled`,
    MARK_AS_UNCANCELLED: `${BASE_URL}/shipment/markAsUncancelled`,
    SAVE_REDIRECT_ORDER_ID: `${BASE_URL}/shipment/linkOrderId`,
    DELETE_REDIRECT_ORDER_ID: `${BASE_URL}/shipment/unlinkOrderId`,
    FIND_LINKED_ORDER: `${BASE_URL}/shipment/findLinkedOrder`,
    REPRINT_PACKAGE: `${BASE_URL}/shipment/reprintPackage`,
    FETCH_BUY_SHIPPING_SERVICES: `${BASE_URL}/shipment/fetchBuyShippingServices`,
    SAVE_BUY_SHIPPING_SERVICE: `${BASE_URL}/shipment/saveBuyShippingService`,
    UPDATE_AND_VALIDATE_SHIPMENTS: `${BASE_URL}/shipment/updateAndValidateShipments`,
    PREVIEW_SAMPLE_COMMERCIAL_INVOICE: `${BASE_URL}/shipment/previewSampleCommercialInvoice`,
    SORT_ORDERS_BEFORE_PRINT: `${BASE_URL}/shipment/sortOrdersBeforePrint`
};

export const USER_MANAGEMENT = {
    FIND_USERS: `${BASE_URL}/team/fetchLinkedUsers`,
    UPDATE_USER: `${BASE_URL}/team/update`,
    DELETE_USER: `${BASE_URL}/team/delete`,
    GET_ROLES: `${BASE_URL}/team/getRoles`,
};
export const DASHBOARD = {
    GET_DASHBOARD: `${BASE_URL}/dashboard/info`,
    SCAN: `${BASE_URL}/dashboard/scan`,
    PACK: `${BASE_URL}/dashboard/pack`,
    MANUAL_PACK: `${BASE_URL}/dashboard/manualPack`,
    MANUAL_VOID: `${BASE_URL}/dashboard/manualVoid`
};
export const CARRIERS = {
    CREATE_CARRIER: `${BASE_URL}/carrier/create`,
    VALIDATE_FEDEX_REST_CREDENTIALS: `${BASE_URL}/carrier/validateFedExRestCredentials`,
    LIST_CARRIERS: `${BASE_URL}/carrier/list`,
    LIST_CARRIER_NAMES: `${BASE_URL}/carrier/listCarrierNames`,
    LIST_UNIQUE_CARRIERS: `${BASE_URL}/carrier/listUnique`,
    LIST_ADDRESS: `${BASE_URL}/carrier/addressList`,
    LINKED_ADDRESSES: `${BASE_URL}/carrier/linkedAddresses`,
    EDIT_CARRIER: `${BASE_URL}/carrier/edit`,
    SET_DEFAULT_CARRIER: `${BASE_URL}/carrier/setDefault`,
    DELETE_CARRIER: `${BASE_URL}/carrier/delete`,
    SET_ADDRESS_VALIDATION: `${BASE_URL}/carrier/setAddressValidation`,
    MANUAL_EXECUTE_AUTOMATION_SETTINGS:`${BASE_URL}/carrier/manualExecuteCarrierAutoSetting`,
}

export const HISTORICAL = {
    IMPORT_HISTORICAL_DATA: `${BASE_URL}/historicalDataImport/importData`,
    FETCH_SHIPRUSH_IMPORT_STATUS: `${BASE_URL}/historicalDataImport/fetchShiprushImportStatus`,
    LIST_SHEETS: `${BASE_URL}/historicalDataImport/listSheets`,
    IMPORT_FTL_DATA: `${BASE_URL}/historicalDataImport/importFTL`
}

export const BANK_ACCOUNTS = {
    LIST_ALL_BANK_ACCOUNTS: `${BASE_URL}/bankAccount/list`,
    SAVE_BANK_ACCOUNT: `${BASE_URL}/bankAccount/save`,
    DELETE_BANK_ACCOUNT: `${BASE_URL}/bankAccount/delete`,
    ADD_FILES: `${BASE_URL}/bankAccount/addFiles`,
    DELETE_FILES: `${BASE_URL}/bankAccount/deleteFiles`,
    PREVIEW_FILE: `${BASE_URL}/bankAccount/previewFile`,
}

export const TRANSACTIONS = {
    LIST_ALL_TRANSACTIONS: `${BASE_URL}/transaction/list`,
    SAVE_TRANSACTION: `${BASE_URL}/transaction/save`,
    ADD_TAX_INFO: `${BASE_URL}/transaction/addTaxInfo`,
    ASSIGN_CATEGORY: `${BASE_URL}/transaction/assignCategory`,
    DELETE_TRANSACTIONS: `${BASE_URL}/transaction/delete`,
    IMPORT_TRANSACTION: `${BASE_URL}/transaction/importTransactions`,
    GET_TRANSACTION_ATTACHMENT: `${BASE_URL}/transaction/getAttachment`,
    DELETE_TRANSACTION_ATTACHMENT: `${BASE_URL}/transaction/deleteAttachment`,
    GET_SUPPLIER_REFERENCES: `${BASE_URL}/transaction/getSupplierReferences`,
    GET_INVOICE_BY_INVOICE_NUMBER: `${BASE_URL}/transaction/getInvoiceByInvoiceNumber`,
    GET_TRANSACTION_TAX_AMOUNT: `${BASE_URL}/transaction/getTransactionTaxAmount`,
    GET_TAXTYPE_WITH_STATE_PROVINCE: `${BASE_URL}/transaction/getTaxTypeWithStateProvince`,
    SAVE_AND_NAVIGATE_TO_CHILD_TRANSACTION: `${BASE_URL}/transaction/saveAndNavigateToChildTransaction`,
}
export const NOTE = {
    GET_NOTE: `${BASE_URL}/note/getNote`,
    SAVE_NOTE: `${BASE_URL}/note/saveNote`,
}

export const LEDGER = {
    LIST_ALL_LEDGER: `${BASE_URL}/ledger/list`,
    SAVE_LEDGER: `${BASE_URL}/ledger/save`,
    FETCH_TRANSACTION_LEDGER: `${BASE_URL}/ledger/fetch`,
    SAVE_TRANSACTION_LEDGER: `${BASE_URL}/ledger/saveTransactionLedger`,
    DELETE_LEDGER_BY_YEAR: `${BASE_URL}/ledger/deleteLedgerByYear`,
    LEDGER_ENTRIES: `${BASE_URL}/ledger/ledgerEntries`,
    UPDATE_LEDGER: `${BASE_URL}/ledger/updateLedger`,
    DELETE_LEDGER: `${BASE_URL}/ledger/deleteLedger`,
    UPDATE_ENTRY: `${BASE_URL}/ledger/updateEntry`,
    DELETE_ENTRY: `${BASE_URL}/ledger/deleteEntry`,

}

export const CHEQUES = {
    LIST_ALL_CHEQUES: `${BASE_URL}/cheque/list`,
    SAVE_CHEQUE: `${BASE_URL}/cheque/save`,
    DELETE_CHEQUE: `${BASE_URL}/cheque/delete`,
    IMPORT_CHEQUE: `${BASE_URL}/cheque/importCSV`,
    VALIDATE_CHEQUE: `${BASE_URL}/cheque/validateChequeNumber`,
}

export const SHIPPING_SUMMARY = {
    LIST_SHIPPING_SUMMARY: `${BASE_URL}/shippingSummary/list`,
    LIST_SHIPPING_SETTINGS: `${BASE_URL}/shippingSummary/rateList`,
    SAVE_SHIPPING_SETTINGS: `${BASE_URL}/shippingSummary/save`,
}

export const PURCHASE_SUMMARY = {
    LIST_PURCHASE_SUMMARY: `${BASE_URL}/purchaseSummary/list`,
}

export const STATEMENT_OF_TAXES = {
    LIST_STATEMENT_TAXES: `${BASE_URL}/statementTaxes/list`,
    LIST_STATEMENT_TAXES_TEMPLATE: `${BASE_URL}/statementTaxes/templateList`,
    SAVE_STATEMENT_TAXES_TEMPLATE: `${BASE_URL}/statementTaxes/save`,
}

export const TRANSACTION_CATEGORY = {
    LIST_TRANSACTION_CATEGORIES: `${BASE_URL}/transactionCategory/list`,
    SAVE_TRANSACTION_CATEGORY: `${BASE_URL}/transactionCategory/save`,
    DELETE_TRANSACTION_CATEGORY: `${BASE_URL}/transactionCategory/delete`,
}

export const MARKETPLACES = {
    CREATE_MARKETPLACE: `${BASE_URL}/marketplace/create`,
    LIST_MARKETPLACES: `${BASE_URL}/marketplace/list`,
    EDIT_MARKETPLACE: `${BASE_URL}/marketplace/edit`,
    DELETE_MARKETPLACE: `${BASE_URL}/marketplace/delete`,
    VALIDATE_MARKETPLACE: `${BASE_URL}/marketplace/validate`,
    UPDATE_INVENTORY_ALLOCATION: `${BASE_URL}/marketplace/updateInventoryAllocation`,
    LIST_SHIPPING_TEMPLATES : `${BASE_URL}/marketplace/listShippingTemplates`,
}


export const PURCHASE_ORDERS = {
    LIST_SUPPLIERS: `${BASE_URL}/purchaseOrder/listSupplierInfo`,
    CREATE_ORDER: `${BASE_URL}/purchaseOrder/create`,
    PREVIEW_ORDER_PDF: `${BASE_URL}/purchaseOrder/previewPDF`,
    CREATE_SEND_ORDER: `${BASE_URL}/purchaseOrder/createSend`,
    LIST_PENDING_ORDERS: `${BASE_URL}/purchaseOrder/listPendingPurchaseOrders`,
    LIST_ALL_PURCHASE_ORDERS: `${BASE_URL}/purchaseOrder/listPurchaseOrders`,
    UPDATE_PURCHASE_ORDER: `${BASE_URL}/purchaseOrder/updatePurchaseOrder`,
    DELETE_INVENTORY_RECEIVED: `${BASE_URL}/purchaseOrder/deleteInventoryReceived`,
    DELETE_PACKING_SLIP: `${BASE_URL}/purchaseOrder/deletePackingSlipFile`,
    PREVIEW_PACKING_SLIP_FILE: `${BASE_URL}/purchaseOrder/previewPackingSlipFile`,
    PREVIEW_CREATED_PURCHASE_ORDER: `${BASE_URL}/purchaseOrder/previewCreatedPurchaseOrder`,
    CANCEL_PURCHASE_ORDER: `${BASE_URL}/purchaseOrder/cancelPurchaseOrder`,
    EDIT_PURCHASE_ORDER: `${BASE_URL}/purchaseOrder/editPurchaseOrder`,
    UNDO_CANCEL: `${BASE_URL}/purchaseOrder/undoCancel`,
    UPDATE_EXPECTED_DELIVERY_DATE: `${BASE_URL}/purchaseOrder/updateExpectedDeliveryDate`,
    DELETE_CUSTOMER_ORDER: `${BASE_URL}/customerPurchaseOrder/deleteOrder`
}

export const INVENTORY = {
    LIST_ALL_INVENTORY: `${BASE_URL}/inventory/listAllInventory`,
    LIST_INVENTORY_ADJUSTMENT_TYPES: `${BASE_URL}/inventory/listInventoryAdjustmentTypes`,
    LIST_MANUFACTURERS: `${BASE_URL}/inventory/listManufacturers`,
    LIST_INVENTORY_ALERTS: `${BASE_URL}/inventory/listAlerts`,
    LIST_BOXES_INVENTORY: `${BASE_URL}/inventory/getBoxInventorySummary`,
    GET_INVENTORY_SUMMARY_FOR_FTL: `${BASE_URL}/inventory/getInventorySummaryForFTL`,
    LIST_PRODUCTS_INVENTORY: `${BASE_URL}/inventory/getProductInventorySummary`,

    LIST_ALL_INVENTORY_ADJUSTMENTS: `${BASE_URL}/inventory/listAllInventoryAdjustments`,
    LIST_INVENTORY_RECEIVED: `${BASE_URL}/inventory/listInventoryReceived`,
    LIST_SHIPPED_INVENTORY: `${BASE_URL}/inventory/listShippedInventory`,
    LIST_INVENTORY_TRANSFERRED: `${BASE_URL}/inventory/listInventoryTransferred`,

    CREATE_INVENTORY_ADJUSTMENT: `${BASE_URL}/inventory/createInventoryAdjustment`,

    LIST_INVENTORY_ENTRIES: `${BASE_URL}/inventory/listInventoryEntries`,

    UPDATE_INVENTORY_THRESHOLD: `${BASE_URL}/inventory/updateInventoryThreshold`,

    UPDATE_FTL_RECEIVE: `${BASE_URL}/inventory/updateFTLReceive`,
}

export const DAMAGE = {
    SAVE_DAMAGED_ENTRY: `${BASE_URL}/damage/saveDamagedEntry`,
    FIND_PO_INFORMATION: `${BASE_URL}/damage/findPoInformation`,
    LIST_ALL_DAMAGES: `${BASE_URL}/damage/listAllDamages`,
    FETCH_SPECIFIC_DAMAGE: `${BASE_URL}/damage/fetchSpecificDamage`,
    DELETE_DAMAGE_ENTRY: `${BASE_URL}/damage/deleteDamageEntry`,
    UPDATE_DAMAGE_ENTRY: `${BASE_URL}/damage/updateDamageEntry`,
}


export const ALERTS = {
    FETCH_ALERTS: `${BASE_URL}/alert/listAlerts`,
    SNOOZE_ALERT: `${BASE_URL}/alert/snoozeAlert`,
    RESOLVE_ALERT: `${BASE_URL}/alert/resolveAlert`,
}

export const RETURNS = {
    CREATE_RETURN: `${BASE_URL}/return/create`,
    LIST_RETURNS: `${BASE_URL}/return/list`,
    UPDATE_RETURN: `${BASE_URL}/return/update`,
    LIST_RETURN_ACTIONS: `${BASE_URL}/return/listReturnActions`,
    LIST_RETURN_CONDITIONS: `${BASE_URL}/return/listReturnConditions`,
    LIST_RETURN_REASONS: `${BASE_URL}/return/listReturnReasons`,
    LIST_UNCOMPLETED_RETURNS: `${BASE_URL}/return/listUncompletedReturns`,

    ADD_FILES: `${BASE_URL}/return/addFiles`,
    DELETE_FILES: `${BASE_URL}/return/deleteFiles`,
    PREVIEW_FILE: `${BASE_URL}/return/previewFile`,
    FETCH_RMA: `${BASE_URL}/return/rmaFetch`,
    FETCH_DUPLICATE_RMAS: `${BASE_URL}/return/fetchDuplicateRmas`,
}

export const TICKETS = {
    CREATE_TICKET: `${BASE_URL}/ticket/create`,
    LIST_TICKETS: `${BASE_URL}/ticket/list`,
    UPDATE_TICKET: `${BASE_URL}/ticket/update`,
    DELETE_TICKET: `${BASE_URL}/ticket/delete`,
    LIST_TICKET_ISSUES: `${BASE_URL}/ticket/listTicketIssues`,
    LIST_ISSUE_SPECIFIC_ACTIONS: `${BASE_URL}/ticket/listIssueSpecificActions`,
    LIST_ISSUE_ACTION_SPECIFIC_TASKS: `${BASE_URL}/ticket/listIssueActionSpecificTasks`,
    LIST_STATUS: `${BASE_URL}/ticket/listStatus`,

    ADD_FILES: `${BASE_URL}/ticket/addFiles`,
    DELETE_FILES: `${BASE_URL}/ticket/deleteFiles`,
    PREVIEW_FILE: `${BASE_URL}/ticket/previewFile`,
    FETCH_ORDER_INFO: `${BASE_URL}/ticket/fetchOrderInfo`,
}


export const PRODUCTS = {
    LIST_PRODUCTS: `${BASE_URL}/product/list`,
    CREATE_PRODUCT: `${BASE_URL}/product/create`,
    UPDATE_PRODUCT: `${BASE_URL}/product/update`,
    DELETE_PRODUCT: `${BASE_URL}/product/delete`,
    GET_ALL_COUNTRIES: `${BASE_URL}/product/getAllCountryCodes`,
}

export const SUPPLIERS = {
    LIST_SUPPLIERS: `${BASE_URL}/supplier/list`,
    CREATE_SUPPLIER: `${BASE_URL}/supplier/create`,
    UPDATE_SUPPLIER: `${BASE_URL}/supplier/update`,
    PROCESS_SUPPLIER_INVOICES: `${BASE_URL}/supplier/processInvoicesFromEmail`,
}

export const CUSTOMERS = {
    LIST_CUSTOMERS: `${BASE_URL}/customer/list`,
    CREATE_CUSTOMER: `${BASE_URL}/customer/create`,
    UPDATE_CUSTOMER: `${BASE_URL}/customer/update`,
    PROCESS_CUSTOMER_INVOICES: `${BASE_URL}/customer/processInvoicesFromEmail`,
    CREATE_ORDER: `${BASE_URL}/customerPurchaseOrder/create`,
    PREVIEW_ORDER_PDF: `${BASE_URL}/customerPurchaseOrder/previewPDF`,
    CREATE_SEND_ORDER: `${BASE_URL}/customerPurchaseOrder/createSend`,
    LIST_PENDING_ORDERS: `${BASE_URL}/customerPurchaseOrder/listPendingPurchaseOrders`,
    LIST_ALL_PURCHASE_ORDERS: `${BASE_URL}/customerPurchaseOrder/listPurchaseOrders`,
    UPDATE_PURCHASE_ORDER: `${BASE_URL}/customerPurchaseOrder/updatePurchaseOrder`,
    DELETE_INVENTORY_RECEIVED: `${BASE_URL}/customerPurchaseOrder/deleteInventoryReceived`,
    DELETE_PACKING_SLIP: `${BASE_URL}/customerPurchaseOrder/deletePackingSlipFile`,
    PREVIEW_PACKING_SLIP_FILE: `${BASE_URL}/customerPurchaseOrder/previewPackingSlipFile`,
    PREVIEW_CREATED_PURCHASE_ORDER: `${BASE_URL}/customerPurchaseOrder/previewCreatedPurchaseOrder`,
    CANCEL_PURCHASE_ORDER: `${BASE_URL}/customerPurchaseOrder/cancelPurchaseOrder`,
    EDIT_PURCHASE_ORDER: `${BASE_URL}/customerPurchaseOrder/editPurchaseOrder`,
    UNDO_CANCEL: `${BASE_URL}/customerPurchaseOrder/undoCancel`,
    UPDATE_EXPECTED_DELIVERY_DATE: `${BASE_URL}/customerPurchaseOrder/updateExpectedDeliveryDate`,
    PREVIEW_SLIP_PDF: `${BASE_URL}/customerPurchaseOrder/previewSlipPDF`,
    GET_ALL_CUSTOMER_INVOICES: `${BASE_URL}/customerPurchaseOrder/listInvoices`,
    UPDATE_CUSTOMER_INVOICE: `${BASE_URL}/customerPurchaseOrder/updateInvoice`,
    UPDATE_VALIDATE_CUSTOMER_INVOICE: `${BASE_URL}/customerPurchaseOrder/updateValidateInvoice`,
    CREATE_CUSTOMER_INVOICE: `${BASE_URL}/customerPurchaseOrder/createInvoice`,
    DELETE_CUSTOMER_INVOICE: `${BASE_URL}/customerPurchaseOrder/deleteInvoice`,
    GET_CUSTOMER_INVOICE_PO: `${BASE_URL}/customerPurchaseOrder/getPurchaseOrder`,
    GET_CUSTOMER_INVOICE_PDF: `${BASE_URL}/customerPurchaseOrder/getInvoicePDF`,
    UPDATE_CUSTOMER_INVOICE_STATUS: `${BASE_URL}/customerPurchaseOrder/updateInvoiceStatus`,
    DELETE_CUSTOMER_INVOICE_FILE: `${BASE_URL}/customerPurchaseOrder/deleteAttachmentFile`,
    PREVIEW_CUSTOMER_INVOICE_FILE: `${BASE_URL}/customerPurchaseOrder/previewAttachmentFile`,
    SAVE_CUSTOMER_INVOICE_PAYMENT_DETAILS: `${BASE_URL}/customerPurchaseOrder/savePaymentDetails`,
    LIST_SALES_CATEGORY: `${BASE_URL}/salesPipeline/list`,
    EDIT_SALES_CATEGORY: `${BASE_URL}/salesPipeline/edit`,
    LIST_ALL_SALES_PIPELINES: `${BASE_URL}/salesPipeline/listPipeline`,
    SAVE_SALES_PIPELINE: `${BASE_URL}/salesPipeline/save`,
    DELETE_SALES_PIPELINE: `${BASE_URL}/salesPipeline/delete`,
    DELETE_SALES_PIPELINE_FILE: `${BASE_URL}/salesPipeline/deleteAttachmentFile`,
    PREVIEW_SALES_PIPELINE_FILE: `${BASE_URL}/salesPipeline/previewAttachmentFile`,
    IMPORT_SALES_PIPELINE: `${BASE_URL}/salesPipeline/importCSV`,
}

export const LOCATIONS = {
    LIST_LOCATIONS: `${BASE_URL}/shipAddress/list`,
    LIST_AVAILABLE_LOCATIONS: `${BASE_URL}/shipAddress/listAvailableLocations`,
    CREATE_LOCATION: `${BASE_URL}/shipAddress/create`,
    UPDATE_LOCATION: `${BASE_URL}/shipAddress/update`
}

export const PRINTERS = {
    LIST_PRINTERS: `${BASE_URL}/printer/list`,
    UPDATE_PRINTERS: `${BASE_URL}/printer/update`,
    REFRESH_PRINTERS: `${BASE_URL}/printer/refresh`,
    UPLOAD_LOGO: `${BASE_URL}/printer/uploadLogo`,
    FETCH_LOGO: `${BASE_URL}/printer/fetchLogo`,
    DELETE_LOGO: `${BASE_URL}/printer/deleteLogo`,
    FETCH_CONFIG: `${BASE_URL}/printer/getConfig`,
}

export const TAGS = {
    LIST_TAGS: `${BASE_URL}/tag/list`,
    EDIT_TAGS: `${BASE_URL}/tag/edit`,
}

export const UUID = {
    GET_UUID: `${BASE_URL}/uuid/getUUID`,
    GET_UUIDS: `${BASE_URL}/uuid/getUUIDs`,
}

export const PACKAGES = {
    LIST_PACKGES: `${BASE_URL}/box/list`,
    CREATE_PACKAGE: `${BASE_URL}/box/create`,
    UPDATE_PACKAGE: `${BASE_URL}/box/update`
}

export const CARRIER_INVOICES = {
    GET_ALL_CARRIER_INVOICES: `${BASE_URL}/carrierInvoice/list`,
    GET_CARRIER_INVOICE_PDF: `${BASE_URL}/carrierInvoice/getInvoicePDF`,
    UPDATE_CARRIER_INVOICE_STATUS: `${BASE_URL}/carrierInvoice/updateInvoiceStatus`,
    DELETE_CARRIER_INVOICE: `${BASE_URL}/carrierInvoice/deleteInvoice`,
    PROCESS_CARRIER_INVOICE: `${BASE_URL}/carrierInvoice/processInvoice`,
    GET_PAGINATED_CARRIER_INVOICE_ITEMS: `${BASE_URL}/carrierInvoice/getPaginatedItems`,
    FETCH_INVOICE_BY_TRACKING_ID: `${BASE_URL}/carrierInvoice/listByTrackingId`,
    REPROCESS_CARRIER_INVOICE: `${BASE_URL}/carrierInvoice/reprocessInvoice`,
    SAVE_CARRIER_INVOICE_ITEM_DETAILS: `${BASE_URL}/carrierInvoice/saveCarrierInvoiceItemDetails`,
    SAVE_CARRIER_INVOICE_PAYMENT_DETAILS: `${BASE_URL}/carrierInvoice/saveCarrierInvoicePaymentDetails`,
    SAVE_CARRIER_INVOICE: `${BASE_URL}/carrierInvoice/saveCarrierInvoice`,
}

export const FTL_CARRIER_INVOICES = {
    PROCESS_FTL_CARRIER_INVOICE: `${BASE_URL}/FTLCarrierInvoice/processInvoice`,
    GET_FTL_CARRIER_INVOICES: `${BASE_URL}/FTLCarrierInvoice/list`,
    GET_FTL_CARRIER_INVOICE_PDF: `${BASE_URL}/FTLCarrierInvoice/getInvoicePDF`,
    DELETE_FTL_CARRIER_INVOICE: `${BASE_URL}/FTLCarrierInvoice/deleteInvoice`,
    UPDATE_FTL_CARRIER_INVOICE_STATUS: `${BASE_URL}/FTLCarrierInvoice/updateInvoiceStatus`,
    SAVE_FTL_CARRIER_INVOICE_PAYMENT_DETAILS: `${BASE_URL}/FTLCarrierInvoice/saveInvoicePaymentDetails`,
    REPROCESS_FTL_CARRIER_INVOICE: `${BASE_URL}/FTLCarrierInvoice/reprocessInvoice`,
    SAVE_FTL_CARRIER_INVOICE: `${BASE_URL}/FTLCarrierInvoice/saveFTLCarrierInvoice`,
}

export const CARRIER_INVOICE_TEMPLATES = {
    LIST_CARRIER_INVOICE_TEMPLATES: `${BASE_URL}/carrierInvoiceTemplate/list`,
    LIST_CARRIERS_WITHOUT_TEMPLATE: `${BASE_URL}/carrierInvoiceTemplate/listCarriersWithoutTemplate`,
    DELETE_CARRIER_INVOICE_TEMPLATE: `${BASE_URL}/carrierInvoiceTemplate/deleteTemplate`,
    CREATE_SAVE_CARRIER_INVOICE_TEMPLATE: `${BASE_URL}/carrierInvoiceTemplate/createSaveTemplate`,
    GET_CARRIER_INVOICE_TEMPLATE_PDF: `${BASE_URL}/carrierInvoiceTemplate/getTemplatePDF`,
    REIMPORT_CARRIER_INVOICE_TEMPLATE: `${BASE_URL}/carrierInvoiceTemplate/reImportCarrierInvoiceTemplatePDF`,
}

export const SUPPLIER_INVOICE_TEMPLATES = {
    REIMPORT_SUPPLIER_INVOICE_TEMPLATE: `${BASE_URL}/supplierInvoiceTemplate/reImportSupplierInvoiceTemplate`,
    FETCH_SUPPLIER_INVOICE_TEMPLATES: `${BASE_URL}/supplierInvoiceTemplate/listSupplierTemplates`,
    FETCH_SUPPLIER_WITHOUT_TEMPLATE: `${BASE_URL}/supplierInvoiceTemplate/listSuppliersWithoutTemplates`,
    CREATE_SAVE_SUPPLIER_INVOICE_TEMPLATE:`${BASE_URL}/supplierInvoiceTemplate/createSaveTemplate`,
    GET_SUPPLIER_INVOICE_TEMPLATE_PDF: `${BASE_URL}/supplierInvoiceTemplate/getTemplate`,
    DELETE_SUPPLIER_INVOICE_TEMPLATE: `${BASE_URL}/supplierInvoiceTemplate/deleteTemplate`,
    GET_DATE_FORMATS: `${BASE_URL}/supplierInvoiceTemplate/getFormats`
}

export const SUPPLIER_INVOICES = {
    GET_ALL_SUPPLIER_INVOICES: `${BASE_URL}/supplierInvoice/listInvoices`,
    PROCESS_SUPPLIER_INVOICE: `${BASE_URL}/supplierInvoice/processInvoice`,
    REPROCESS_SUPPLIER_INVOICE: `${BASE_URL}/supplierInvoice/reprocessInvoice`,
    GET_SUPPLIER_INVOICE_PDF: `${BASE_URL}/supplierInvoice/getInvoicePDF`,
    GET_SUPPLIER_INVOICE_PO: `${BASE_URL}/supplierInvoice/getPurchaseOrder`,
    UPDATE_SUPPLIER_INVOICE: `${BASE_URL}/supplierInvoice/updateInvoice`,
    UPDATE_VALIDATE_SUPPLIER_INVOICE: `${BASE_URL}/supplierInvoice/updateValidateInvoice`,
    DELETE_SUPPLIER_INVOICE: `${BASE_URL}/supplierInvoice/deleteInvoice`,
    UPDATE_SUPPLIER_INVOICE_STATUS: `${BASE_URL}/supplierInvoice/updateInvoiceStatus`,
    OVERWRITE_SUPPLIER_INVOICE: `${BASE_URL}/supplierInvoice/overwriteInvoice`,
    PREVIEW_SUPPLIER_INVOICE_FILE: `${BASE_URL}/supplierInvoice/previewAttachmentFile`,
    DELETE_SUPPLIER_INVOICE_FILE: `${BASE_URL}/supplierInvoice/deleteAttachmentFile`,
    SAVE_SUPPLIER_INVOICE_PAYMENT_DETAILS: `${BASE_URL}/supplierInvoice/savePaymentDetails`,
}

export const FULL_TRUCKLOAD = {
    FETCH_FULL_TRUCKLOADS: `${BASE_URL}/fullTruckload/list`,
    SAVE_FULL_TRUCKLOAD: `${BASE_URL}/fullTruckload/create`,
    UPDATE_FULL_TRUCKLOAD: `${BASE_URL}/fullTruckload/update`,
    UPLOAD_SIGNED_DOCUMENTS: `${BASE_URL}/fullTruckload/uploadSignedDocuments`,
    SAVE_FULL_TRUCKLOAD_SETTINGS: `${BASE_URL}/fullTruckload/saveSettings`,
    FETCH_FULL_TRUCKLOAD_SETTINGS: `${BASE_URL}/fullTruckload/getSettings`,
    PREVIEW_FULL_TRUCKLOAD_FILE: `${BASE_URL}/fullTruckload/previewFile`,
    PREVIEW_FULL_TRUCKLOAD_DOCUMENT: `${BASE_URL}/fullTruckload/previewDocument`,
    LIST_FULL_TRUCKLOAD_CARRIERS: `${BASE_URL}/fullTruckload/listCarriers`,
    LIST_FULL_TRUCKLOAD_BROKERS: `${BASE_URL}/fullTruckload/listBrokers`,
}

export const CLAIM = {
    LIST_CLAIMS: `${BASE_URL}/claim/list`,
    CREATE_CLAIM: `${BASE_URL}/claim/create`,
    UPDATE_CLAIM: `${BASE_URL}/claim/update`,
    DELETE_CLAIM: `${BASE_URL}/claim/delete`,
    PREVIEW_CLAIM_FILE: `${BASE_URL}/claim/previewFile`,
    DELETE_CLAIM_FILE: `${BASE_URL}/claim/deleteFile`,
}

export const FULL_TRUCKLOAD_BROKERS = {
    FETCH_FULL_TRUCKLOADS_BROKERS: `${BASE_URL}/fullTruckloadBroker/list`,
    SAVE_FULL_TRUCKLOAD_BROKERS: `${BASE_URL}/fullTruckloadBroker/save`,
    DELETE_FULL_TRUCKLOAD_BROKER: `${BASE_URL}/fullTruckloadBroker/delete`
}

export const FULL_TRUCKLOAD_CARRIERS = {
    FETCH_FULL_TRUCKLOADS_CARRIERS: `${BASE_URL}/fullTruckloadCarrier/list`,
    SAVE_FULL_TRUCKLOAD_CARRIERS: `${BASE_URL}/fullTruckloadCarrier/save`,
    DELETE_FULL_TRUCKLOAD_CARRIERS: `${BASE_URL}/fullTruckloadCarrier/delete`,
    MANUAL_EXECUTE_AUTOMATION_SETTINGS:`${BASE_URL}/fullTruckloadCarrier/manualExecuteCarrierAutoSetting`,
}

export const FTL_CARRIER_INVOICE_TEMPLATES = {
    LIST_FTL_CARRIER_INVOICE_TEMPLATES: `${BASE_URL}/FTLCarrierInvoiceTemplate/list`,
    LIST_FTL_CARRIERS_WITHOUT_TEMPLATE: `${BASE_URL}/FTLCarrierInvoiceTemplate/listCarriersWithoutTemplate`,
    CREATE_OR_EDIT_FTL_CARRIERS_INVOICE_TEMPLATE: `${BASE_URL}/FTLCarrierInvoiceTemplate/createOrEditFTLCarriersInvoiceTemplate`,
    GET_FTL_CARRIER_INVOICE_TEMPLATE_PDF: `${BASE_URL}/FTLCarrierInvoiceTemplate/getTemplate`,
    DELETE_INVOICE_TEMPLATE: `${BASE_URL}/FTLCarrierInvoiceTemplate/deleteTemplate`,
}

export const BOX_SUGGESTIONS = {
    getSuggestions: `${BASE_URL}/boxSuggestions/getSuggestions`,
    ignoreSuggestion: `${BASE_URL}/boxSuggestions/ignoreSuggestion`
}

export const LISTINGS = {
    GET_LISTINGS: `${BASE_URL}/skuMapping/listSkus`,
    GET_PRICING_LISTINGS: `${BASE_URL}/skuMapping/listPricingSkus`,
    FETCH_AMAZON_LISTINGS_PRICE: `${BASE_URL}/skuMapping/fetchAmazonListingsPrice`,
    GET_BARCODES: `${BASE_URL}/skuMapping/listBarcodes`,
    UPDATE_LISTING: `${BASE_URL}/skuMapping/update`,
    UPDATE_PRICING_LISTING: `${BASE_URL}/skuMapping/updatePricingListing`,
    UPDATE_RRICING: `${BASE_URL}/skuMapping/updateRepricing`,
    GET_TOP_3_BUY_BOX_OFFERS: `${BASE_URL}/skuMapping/getTop3BuyBoxOffers`,
    DELETE_LISTING: `${BASE_URL}/skuMapping/delete`,
    UPDATE_BARCODES: `${BASE_URL}/skuMapping/updateBarcodes`,
    GET_UNMAPPED_LISTINGS: `${BASE_URL}/skuMapping/listUnmappedProducts`,
    GET_ACTIVE_LISTINGS: `${BASE_URL}/skuMapping/listActiveProducts`,
    GET_INACTIVE_LISTINGS: `${BASE_URL}/skuMapping/listInactiveProducts`,
    GET_DELETED_LISTINGS: `${BASE_URL}/skuMapping/listDeletedProducts`,
    FETCH_AMAZON_SKUS: `${BASE_URL}/skuMapping/fetchAmazonSkus`,
    UPDATE_PRIMARY: `${BASE_URL}/skuMapping/changePrimary`,
    ADD_NEW_CHILD: `${BASE_URL}/skuMapping/addNewChild`,
    DELETE_CHILD: `${BASE_URL}/skuMapping/deleteChild`,
    GET_SALES_RANK: `${BASE_URL}/skuMapping/getSalesRank`,
    GET_BUY_BOX_PRICES: `${BASE_URL}/skuMapping/getBuyBoxPrices`,
    GET_RELATED_PRODUCTS: `${BASE_URL}/skuMapping/getRelatedProducts`,
    ADD_RELATED_PRODUCT: `${BASE_URL}/skuMapping/addRelatedProduct`,
    EDIT_RELATED_PRODUCT: `${BASE_URL}/skuMapping/editRelatedProduct`,
    UPDATE_RELATED_PRODUCTS: `${BASE_URL}/skuMapping/updateRelatedProducts`,
    SET_SKU_LOCATION: `${BASE_URL}/skuMapping/setSkuLocation`,
}

export const DATA_SCRUBBING = {
    SCRUB: `${BASE_URL}/scrubData/scrubData`
}

export const PRODUCT_CATEGORY = {
    SAVE_PRODUCT_CATEGORY_CHANGES: `${BASE_URL}/productCategory/updateCategories`,
    REMOVE_PRODUCT_CATEGORY: `${BASE_URL}/productCategory/remove`,
    GET_ALL_CATEGORY: `${BASE_URL}/productCategory/getAllCategory`,
    GET_ALL_COUNTRIES: `${BASE_URL}/productCategory/getAllCountries`,
}

export const SYSTEM_EVENT_LOG = {
    GET_SYSTEM_LOG_EVENTS: `${BASE_URL}/systemEventLog/getSystemEventLogs`,
    GET_ALL_MESSAGES: `${BASE_URL}/systemEventLog/getAllMessages`,
    GET_MESSAGE: `${BASE_URL}/systemEventLog/getMessage`,
}

export const AMAZON = {
    GET_AMAZON_FEEDS: `${BASE_URL}/amz/getFeeds`,
    RESTART_FEED_FETCH: `${BASE_URL}/amz/restartFeedFetch`
}

export const EMAIL_TEMPLATES = {
    GET_EMAIL_TEMPLATE: `${BASE_URL}/emailTemplate/getEmailTemplates`,
    UPDATE_EMAIL_TEMPLATE: `${BASE_URL}/emailTemplate/updateEmailTemplates`,
    CREATE_NEW_EMAIL_TEMPLATE: `${BASE_URL}/emailTemplate/createEmailTemplates`,
    DELETE_NEW_EMAIL_TEMPLATE: `${BASE_URL}/emailTemplate/deleteEmailTemplates`,
    GET_EMAIL_TEMPLATE_TYPES: `${BASE_URL}/emailTemplate/getEmailTemplateTypes`,
    UPDATE_EMAIL_TEMPLATE_TYPE: `${BASE_URL}/emailTemplate/updateEmailTemplateType`,
    CREATE_NEW_EMAIL_TEMPLATE_TYPE: `${BASE_URL}/emailTemplate/createEmailTemplateType`,
    DELETE_NEW_EMAIL_TEMPLATE_TYPE: `${BASE_URL}/emailTemplate/deleteEmailTemplateType`,
    FETCH_SENDGRID_TEMPLATES: `${BASE_URL}/emailTemplate/fetchSendgridTemplates`,
}

export const SEND_GRID = {
    SEND_CUSTOMER_PO_EMAIL: `${BASE_URL}/sendGrid/customerPurchaseOrderEmail`,
    SEND_PO_EMAIL: `${BASE_URL}/sendGrid/purchaseOrderEmail`,
    SEND_INVOICE_EMAIL: `${BASE_URL}/sendGrid/saveSendInvoiceAndPackingSlipEmail`,
    GET_CUSTOMER_MAILING_LIST: `${BASE_URL}/sendGrid/customerMailingList`,
    FETCH_PURCHASE_ORDER_EMAIL_TEMPLATE: `${BASE_URL}/sendGrid/fetchPurchaseOrderEmailTemplate`,
    SEND_EMAIL_PURCHASE_ORDER: `${BASE_URL}/sendGrid/sendEmailPurchaseOrder`,
}

export const STATE_AND_PROVINCE = {
    GET_STATE_AND_PROVINCE_MAPPING: `${BASE_URL}/stateAndProvince/getStateAndProvinceMapping`,
}

