import React from 'react';

// material components
import {
    Toolbar,
    Typography,
    IconButton,
    Tooltip,
    Popper
} from '@material-ui/core';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';

// material icons
import DeleteIcon from '@material-ui/icons/Delete';
import PlaylistPlayIcon from '@material-ui/icons/PlaylistPlay';
import BlockIcon from '@material-ui/icons/Block';
import PrintIcon from '@material-ui/icons/Print';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import PublishIcon from '@material-ui/icons/Publish';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import PlayForWorkIcon from '@material-ui/icons/PlayForWork';
import CompareIcon from '@material-ui/icons/Compare';
import AssignmentLateIcon from '@material-ui/icons/AssignmentLate';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';

// custom component
import LinearProgressWithLabel from "../../pages/Dashboard/LinearProgressWithLabel";
import AutomationRuleDialog from "./AutomationRuleDialog";
import FeedbackBar from "../feedbackBar/FeedbackBar";

// import styling
import "../global/TableToolbar.css";
import {Archive, Assignment, Cancel, MoreHoriz, MoveToInbox} from "@material-ui/icons";

// import constants
import {
    APPLY_RULES,
    VOID_ORDERS,
    SHIP_ORDERS,
    PRINT_TO_PDF,
    REPRINT_LABELS,
    CANCEL_ORDERS,
    ERROR,
    CANCEL_ORDER_ERROR,
    SUCCESS,
    CANCEL_ORDER_REQUEST_ERROR,
    ORDERS_CANCELLED_SUCCESSFULLY,
    CREATE_ORDER_TITLE,
    IMPORT_ORDER_TITLE,
    PENDING_SHIPMENTS,
    CREATE_ORDER_SUCCESS,
    CREATE_ORDER_ERROR,
    FEDEX,
    APPLY_DEFAULT_SERVICE,
    APPLY_CHEAPEST_SERVICE,
    RATE_AND_COMPARE,
    BACK_TO_MENU_TITLE,
    MARK_AS_SHIPPED,
    MARK_AS_REDIRECTED,
    CREATE_RETURN_SHIPMENT,
    CREATE_CLONE_SHIPMENT,
    CREATE_RETURN_SUCCESS,
    CREATE_CLONED_SUCCESS,
    MARK_AS_UNSHIPPED,
    DELETE_SHIPMENTS,
    ADD_TAG,
    VALIDATE_ADDRESS,
    REFRESH,
    BUY_SHIPPING_SERVICES,
    ARCHIVE_LABELS,
    CANCEL,
    SAVE,
    MARK_AS_CANCELLED,
    MARK_AS_UNCANCELLED, SHIPPED_SHIPMENTS
} from './constants';
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import RefreshIcon from '@material-ui/icons/Refresh';

// redux
import withShipment from "../../withShipment";
import {
    printOrder,
    updatePrintedCount,
    cancelOrder,
    reprintOrder,
    archiveLabel,
    voidOrder,
    editOrder,
    editMultipleOrder,
    markAsShipped,
    markAsRedirected,
    markAsUnshipped,
    markAsCancelled,
    deleteShipments,
    printToPdf,
    markAsUncancelled, getMessages,
} from "../../redux/actions/orders";
import {
    setCreateClonedOpen,
    setCreateOrderOpen,
    setCreateReturnOpen,
    setOpen,
    setVoidOpen,
    setArchiveOpen,
} from "../../redux/actions/global";
import {
    fetchRules,
    runRules,
} from "../../redux/actions/automationRules";
import {
    printedCountSelector, testZplSelector
} from "../../redux/selectors/orders";
import {
    rulesSelector
} from "../../redux/selectors/automationRules";
import {printersSelector} from "../../redux/selectors/settings"
import {
    createOrderOpenSelector,
    createReturnOpenSelector,
    createClonedOpenSelector,
    loadingSelector,
    openSelector,
    statusSelector,
    voidOpenSelector,
    voidStatusSelector,
    archiveOpenSelector,
    archiveStatusSelector,
} from "../../redux/selectors/global";
import {CANCELLED, CLONED, RETURN, SHIPPED, UNSHIPPED} from "../stages/constants";
import {carriersSelector} from "../../redux/selectors/settings";
import {listCarriers, listPrinters} from "../../redux/actions/settings";
import {BASE_URL} from "../global/Server/endpoints";
import PropTypes from "prop-types";
import ShipmentsTable from "./ShipmentsTable";
import {AssignmentTurnedIn, Directions} from "@material-ui/icons";
import {AssignmentReturn, FileCopy} from "@material-ui/icons";
import CancelIcon from "@material-ui/icons/Cancel";
import ScheduleIcon from "@material-ui/icons/Schedule";
import EditAttributesIcon from "@material-ui/icons/EditAttributes";
import {Autocomplete} from "@material-ui/lab";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import SaveAltIcon from "@material-ui/icons/SaveAlt";
import {FormatListBulleted} from "@material-ui/icons";
import {BUY_SHIPPING} from "../settings/ManageCarriers/constants";
import CustomToolbar from "../global/CustomToolbar";
import SaveIcon from "@material-ui/icons/Save";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import {Box, Stack} from "@mui/material";
import DateFnsUtils from "@date-io/date-fns";
import Button from "@material-ui/core/Button";
import CancelScheduleSendIcon from '@mui/icons-material/CancelScheduleSend';
import MarkAsCancelledDialog from "./MarkAsCancelledDialog";
import ShipmentPrintConfirmationDialog from "./ShipmentPrintConfirmationDialog";

class ShipmentsTableToolbar extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            printing: false,
            openRuleDialog: false,
            selectedRules: [],
            allSelected: false,
            numSelected: 0,
            error: false,
            open: false,
            shipOpen: false,
            printCount: false,
            shipMessage: "",
            tagOpen: false,
            shippedDialogOpen: false,
            cancelOpen: false,
            anchorEl: null,
            tagsSelected: [],
            listIndeterminate: [],
            rateDefaultopen: false,
            rateCheapestopen: false,
            openMenu: false,
            validateAddressOpen: false,
            shipmentDate: new Date(),
            shipmentNote: "",
            printingWithNoRate: false,
        }
        this.anchorRef = React.createRef();
        this.prevOpen = React.createRef();
    }

    componentDidMount() {
        if (this.props.shipmentStatus!=SHIPPED)
            this.props.fetchRules()
        this.props.listPrinters()
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.openMenu !== this.state.openMenu) {
            // if (this.prevOpen.current === true && this.state.openMenu === false) {
            //     this.anchorRef.current.focus();
            // }
            this.prevOpen.current = this.state.openMenu;
        }
        if (((prevProps.selected !== this.props.selected || prevProps.rows !== this.props.rows) && this.props.selected.length > 0) /*&& this.state.tagOpen*/) {
            this.getValueTagAutocomplete()
            this.checkIndeterminate()
        }
    }

    shipOrders = () => {
        let isSelectedPrinter = false
        this.props.printers.forEach(row => row.selected == true ? isSelectedPrinter = true : null)
        if (isSelectedPrinter) {
            this.setState({
                numSelected: this.props.selected.length
            })
            const setShipOpen = (shipOpen, message) => {
                this.setState({
                    shipOpen: shipOpen,
                    shipMessage: message ? message : ""
                })
            }
            const setPrintCount = (printCount, message) => {
                this.setState({
                    printCount: printCount,
                    shipMessage: message ? message : ""
                })
            }
            const exitShipment = () => {
                if (this.props.editOrderOpen) {
                    this.props.handleEditShipmentClose()
                } else {
                    this.props.clearSelected()
                }
            }
            this.props.printOrder(this.props.selected, setShipOpen, exitShipment, setPrintCount, this.props.editOrderOpen)
        } else {
            this.setState({
                open: true,
            })
        }

    }

    shipPdfOrders = () => {
        this.setState({
            numSelected: this.props.selected.length
        })
        const setShipOpen = (shipOpen, message) => {
            this.setState({
                shipOpen: shipOpen,
                shipMessage: message ? message : ""
            })
        }
        const setPrintCount = (printCount, message) => {
            this.setState({
                printCount: printCount,
                shipMessage: message ? message : ""
            })
        }

        const downloadPdfs = (pdfArrays, shipment) => {
            pdfArrays.forEach((array, index) => {
                    let blob = new Blob([new Uint8Array(array)], {type: "application/pdf"});
                    let link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    if (shipment.redirectFromOrderId && shipment.redirectToOrderId) {
                        link.download = shipment.redirectFromOrderId + ' - ' + shipment.packages[index].trackingNumber
                    } else {
                        link.download = shipment.packages[index].reference1 + ' - ' + shipment.packages[index].trackingNumber
                    }
                    link.click();
                    // now clear it from memory
                    window.URL.revokeObjectURL(link.href)
                }
            )
        }
        this.props.printToPdf(this.props.selected, setShipOpen, downloadPdfs,setPrintCount)
        if (!this.props.editOrderOpen) {
            this.props.clearSelected()
        }
        if (this.props.editOrderOpen) {
            this.props.handleEditShipmentClose()
        }
    }

    markAsShipped = () => {
        const readyShipments = this.props.selected.map(selected => selected.order).filter(selected => this.props.isShipmentReadyMarkAsShipped(selected))
        this.props.markAsShipped(readyShipments, this.state.shipmentDate, this.state.shipmentNote)
        if (this.props.editOrderOpen) {
            this.props.handleEditShipmentClose()
        } else {
            this.props.clearSelected()
        }
    }

    markAsRedirected = () => {
        const readyShipments = this.props.selected.map(selected => selected.order).filter(selected => this.props.isShipmentReadyForMarkRedirected(selected))
        this.props.markAsRedirected(readyShipments)
        if (this.props.editOrderOpen) {
            this.props.handleEditShipmentClose()
        } else {
            this.props.clearSelected()
        }
    }

    handleSelectAll = () => {
        if (!this.state.allSelected) {
            let newSelected = []
            this.props.rules.map(rule => {
                newSelected.push(rule.name)
            })
            this.setState({
                selectedRules: [...newSelected],
                allSelected: true,
            })
        } else {
            this.setState({
                selectedRules: [],
                allSelected: false,
            })
        }
    }

    handleSelect = (ruleName) => {
        const selectedIndex = this.state.selectedRules.findIndex(item => item === ruleName);

        let selectedRules = [...this.state.selectedRules];

        if (selectedIndex === -1) {
            selectedRules = [...selectedRules, ruleName];
        } else {
            selectedRules.splice(selectedIndex, 1);
        }
        this.setState({
            selectedRules,
        });
    }

    isSelected = (item) => this.state.selectedRules.indexOf(item) !== -1;

    runRules = () => {
        const data = {
            allRules: false,
            shipments: this.props.selected,
            rules: this.state.selectedRules
        };

        this.props.runRules(data);
        this.setState({
            openRuleDialog: false,
            selectedRules: []
        })
    }

    cancelOrders = () => {
        let error = false;
        this.props.selected.map(order => {
            const index = this.props.rows.findIndex(row => row.shipmentId === order.order)
            if (index !== -1 && !this.props.rows[index].isCancellable) {
                error = true
            }
        });
        if (error) {
            this.setState({error,})
        } else {
            let shipments = []
            this.props.selected.map(order => {
                shipments.push(order.order)
            });
            const request = {
                shipments: shipments
            };
            this.props.cancelOrder(request, this.props.fromDate?.toISOString(), this.props.toDate?.toISOString(), this.props.shipByDate?.toISOString(), this.props.selectedLocationId)
            if (this.props.editOrderOpen) {
                this.props.handleEditShipmentClose()
            } else {
                this.props.clearSelected()
            }
        }
    }

    deleteOrders = () => {
        let error = false;
        this.props.selected.map(order => {
            const index = this.props.rows.findIndex(row => row.shipmentId === order.order)
            if (index !== -1 && !this.props.rows[index].isCancellable) {
                error = true
            }
        });
        if (error) {
            this.setState({error,})
        } else {
            let shipments = []
            this.props.selected.map(order => {
                shipments.push(order.order)
            });
            this.props.deleteShipments(shipments, this.props.shipmentStatus, () => {
            })
            if (this.props.editOrderOpen) {
                this.props.handleEditShipmentClose()
            } else {
                this.props.clearSelected()
            }
        }
    }

    handleErrorClose = () => {
        this.setState({error: false})
    }

    reprintOrders = () => {
        let selectedIds = [...this.props.selected.map(row => ({
            id: row.order
        }))]
        const data = selectedIds
        const downloadPdfs = (pdfArrays, shipment) => {
            pdfArrays.forEach((array, index) => {
                    let blob = new Blob([new Uint8Array(array)], {type: "application/pdf"});
                    let link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = shipment.packages[index].reference1 + ' - ' + shipment.packages[index].trackingNumber
                    link.click();
                    // now clear it from memory
                    window.URL.revokeObjectURL(link.href)
                }
            )
        }
        this.props.reprintOrder(data, downloadPdfs, this.props.fromDate?.toISOString(), this.props.toDate?.toISOString(), this.props.selectedLocationId);
        if (this.props.editOrderOpen) {
            this.props.handleEditShipmentClose()
        } else {
            this.props.clearSelected()
        }
    }

    archiveLabels = () => {
        let selectedIds = [...this.props.selected.map(row => ({
            id: row.order
        }))]
        const data = selectedIds
        this.props.archiveLabel(data);
        if (!this.props.editOrderOpen) {
            this.props.clearSelected()
        }
    }

    voidOrders = () => {
        const shipmentsToVoid = this.props.selected.filter((selected) => {
            const foundRow = this.props.rows.find((row) => {
                return row.shipmentId === selected.order
            })
            return (!(foundRow.isMarkShippedShipment) && !(foundRow.isRedirectShipment))
        })
        this.props.voidOrder([...shipmentsToVoid], this.props.fromDate?.toISOString(), this.props.toDate?.toISOString(), this.props.selectedLocationId);
        if (this.props.editOrderOpen) {
            this.props.handleEditShipmentClose()
        } else {
            this.props.clearSelected()
        }
    }

    markAsUnshipped = () => {
        const readyShipments = this.props.selected.filter((selected) => {
            const foundRow = this.props.rows.find((row) => {
                return row.shipmentId === selected.order
            })
            return (foundRow.isMarkShippedShipment || foundRow.isRedirectShipment)
        })
        const shipmentIds = []
        readyShipments.forEach(shipment => shipmentIds.push(shipment.order))
        this.props.markAsUnshipped(shipmentIds)
        if (this.props.editOrderOpen) {
            this.props.handleEditShipmentClose()
        } else {
            this.props.clearSelected()
        }
    }

    markAsUncancelled = () => {
        const readyShipments = this.props.selected.map(selected => selected.order)
            this.props.markAsUncancelled(readyShipments)
            if (this.props.editOrderOpen) {
                this.props.handleEditShipmentClose()
            } else {
                this.props.clearSelected()
            }
        }

    createReturnShipment = () => {
        let selectedIds = [...this.props.selected.map(row => ({
            id: row.order
        }))]
        this.props.createReturnShipment(selectedIds);
        if (this.props.editOrderOpen) {
            this.props.handleEditShipmentClose()
        } else {
            this.props.clearSelected()
        }
    }

    createCloneShipment = () => {
        let selectedIds = [...this.props.selected.map(row => ({
            id: row.order
        }))]
        this.props.createCloneShipment(selectedIds);
        if (this.props.editOrderOpen) {
            this.props.handleEditShipmentClose()
        } else {
            this.props.clearSelected()
        }
    }

    voidOrders = () => {
        this.props.voidOrder([...this.props.selected], this.props.fromDate?.toISOString(), this.props.toDate?.toISOString(), this.props.selectedLocationId);
        if (this.props.editOrderOpen) {
            this.props.handleEditShipmentClose()
        } else {
            this.props.clearSelected()
        }
    }

    rateToDefault = () => {
        let selectedIds = [...this.props.selected.map(row => ({
            id: row.order
        }))]
        const data = selectedIds
        const setState = () => {
            this.setState({
                rateDefaultopen: true,
            })
        }
        this.props.handleRateToDefault(data, setState);
        if (!this.props.editOrderOpen) {
            this.props.clearSelected()
        }
    }

    validateAddress = async () => {
        let data = []
        if (this.props.logsDetailsPage) {
            data = [this.props.shipmentIdFromLogs]
        } else {
            data = [...this.props.selected.map(row => (
                row.order
            ))]
        }
        const setState = () => {
            this.setState({
                validateAddressOpen: true,
            })
        }
        // created wrapper function for validate address & get shipments
        this.props.updateShipments({fromDate: this.props.fromDateLogs, toDate: this.props.toDateLogs, shipmentIds: data, setState: setState})
        if (!this.props.editOrderOpen) {
            this.props.clearSelected()
        }
    }

    rateToCheapest = () => {
        let selectedIds = [...this.props.selected.map(row => ({
            id: row.order
        }))]
        const data = selectedIds
        const setState = () => {
            this.setState({
                rateCheapestopen: true,
            })
        }
        this.props.handleRateToCheapest(data, setState)
        if (!this.props.editOrderOpen) {
            this.props.clearSelected()
        }
    }

    toggleMenu = () => {
        this.setState({
            openMenu: !this.state.openMenu
        })
    }

    handleMenuDropdownClose = (e) => {
        if (this.anchorRef.current && this.anchorRef.current.contains(e.target)) {
            return;
        }
        this.setState({
            openMenu: false
        })
    }

    printCheck = (rate) => {
        // if in the edit shipment view and there is no rate then trigger the pop up
        if (this.props.editOrderOpen && !rate) {
            this.setState({
                printingWithNoRate: true
            })
        }
        // if in the table view then don't check if the rate is applied or not
        else {
            this.shipOrders()
        }
    }

    blockPrint = () => {
        let block = false
        if (this.props.selected && !this.props.editOrderOpen){
            this.props.selected.forEach((shipment) => {
                let foundRow = this.props.rows.find((row) => {
                    return row.shipmentId === shipment.order
                })
                let foundCarrier = this.props.carriers.find((carrier) => {
                    return carrier.accountNumber === foundRow?.fromAddressAccountNumber
                })
                if (foundCarrier === undefined) {
                    block = true
                } else if (foundRow.isPrime && foundRow.marketplace === "Amazon.com" && !foundCarrier.isBuyShipping) {
                    block = true
                }
            })
            return block
            // console.log(this.props.selectedShipment)
            // return this.props.selectedShipment.isPrime && this.props.selectedShipment.marketplace === "Amazon.com" && this.props.selectedShipment.fromAddressAccountNumber.indexOf(BUY_SHIPPING) === -1
        } else if (this.props.editOrderOpen) {
            let row = this.props.getRow()
            let foundCarrier = this.props.carriers.find((carrier) => {return carrier.accountNumber === row?.fromAddressAccountNumber})
            if (foundCarrier !== undefined) {
                return row.isPrime && row.marketplace === "Amazon.com" && !foundCarrier.isBuyShipping
            }
        }
        return true
    }

    checkIndeterminate = () => {
        let tagNames = this.props.tags.map(tag => tag.tagName)
        let rows = []
        let indeterminates = []
        let rowTags
        for (let i = 0; i < this.props.selected.length; i++) {
            let row = this.props.rows.find((shipment) =>
                (this.props.selected[i].order === shipment.shipmentId))
            if (row === undefined || row === null) {
                rowTags = []
            } else if (row.tags === undefined || row.tags === null || row.tags.length <= 0) {
                rowTags = []
            } else {
                rowTags = row.tags.map(tag => (
                    tag.tagName
                ))

            }
            rows = [...rows, rowTags]
        }
        if (rows.length > 0) {
            for (let i = 0; i < tagNames.length; i++) {
                let filteredRow = rows.filter(tags => tags.includes(tagNames[i]))
                if (filteredRow.length !== rows.length && filteredRow.length > 0) {
                    indeterminates = [...indeterminates, tagNames[i]]
                }
            }
        }
        //this.setState({listIndeterminate: indeterminates})
        return indeterminates
    };

    getValueTagAutocomplete = () => {
        let tagNames = this.props.tags.map(tag => tag.tagName)
        let tagsSelected = []
        for (let i = 0; i < tagNames.length; i++) {
            let notFound = false
            for (let j = 0; j < this.props.selected.length; j++) {
                let row = this.props.rows.find((shipment) => this.props.selected[j].order === shipment.shipmentId)
                if (row === undefined || row === null) {
                    notFound = true
                    break
                }
                if (row.tags === undefined || row.tags === null || row.tags.length <= 0) {
                    notFound = true
                    break
                }
                if (!row.tags.map(tag => tag.tagName).includes(tagNames[i])) {
                    notFound = true
                    break
                }
            }
            if (!notFound) {
                tagsSelected = [...tagsSelected, this.props.tags[i]]
            }
        }
        this.setState({tagsSelected: tagsSelected})
    };

    saveTagChange = (value, name, isPackage, index, tagName) => {
        let rows = []
        for (let i = 0; i < this.props.selected.length; i++) {
            let row = this.props.rows.find((shipment) =>
                (this.props.selected[i].order === shipment.shipmentId))
            rows = [...rows, row]
        }
        if (name === "tags") {
            rows = rows.filter(row => {
                return !row.tags.map(tag => tag.tagName).includes(tagName)
            })
        }
        let orders = []
        for (let i = 0; i < rows.length; i++) {
            const order = {
                id: rows[i].shipmentId,
                field_to_edit: name,
                value: value,
                isPackage: isPackage,
                index: index,
                isProduct: false,
                productInfo: {}
            }
            orders = [...orders, order]
        }
        this.props.editMultipleOrder(orders)
        this.getValueTagAutocomplete()
    }


    displayActions = () => {
        switch (this.props.shipmentStatus) {
            //Return and cloned shipments have the same functionality as unshipped shipments
            case RETURN:
            case CLONED:
            case UNSHIPPED:
                return (
                    <>
                        {window.screen.width <= 800 ?
                            <div>
                                <IconButton onClick={this.toggleMenu} ref={this.anchorRef}>
                                    <MoreHoriz/>
                                </IconButton>
                                <Popper open={this.state.openMenu} anchorEl={this.anchorRef.current} role={undefined}
                                        transition disablePortal>
                                    {({TransitionProps, placement}) => (
                                        <Grow
                                            {...TransitionProps}
                                            style={{transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'}}
                                        >
                                            <Paper>
                                                <ClickAwayListener onClickAway={this.handleMenuDropdownClose}>
                                                    <MenuList autoFocusItem={this.state.openMenu} id="menu-list-grow">
                                                        <MenuItem onClick={(e) => {
                                                            this.deleteOrders()
                                                            this.handleMenuDropdownClose(e)
                                                        }}>
                                                            <Tooltip title={DELETE_SHIPMENTS}>
                                                                <IconButton aria-label={DELETE_SHIPMENTS}>
                                                                    <DeleteIcon/>
                                                                </IconButton>
                                                            </Tooltip>
                                                            {DELETE_SHIPMENTS}
                                                        </MenuItem>
                                                        <MenuItem onClick={(e) => {
                                                            this.setState({openRuleDialog: true})
                                                            this.handleMenuDropdownClose(e)
                                                        }}>
                                                            <Tooltip title={APPLY_RULES}>
                                                                <IconButton aria-label={APPLY_RULES}>
                                                                    <PlaylistPlayIcon/>
                                                                </IconButton>
                                                            </Tooltip>
                                                            {APPLY_RULES}
                                                        </MenuItem>
                                                        {!this.props.editOrderOpen ?
                                                            <Tooltip title={ADD_TAG}>
                                                                <IconButton aria-label={ADD_TAG}
                                                                            onClick={(e) => this.handleAddTagOpen(e)}>
                                                                    <EditAttributesIcon/>
                                                                </IconButton>
                                                            </Tooltip> : null
                                                        }
                                                        <MenuItem onClick={(e) => {
                                                            this.rateToDefault()
                                                            this.handleMenuDropdownClose(e)
                                                        }}>
                                                            <Tooltip title={APPLY_DEFAULT_SERVICE}>
                                                                <IconButton aria-label={APPLY_RULES}>
                                                                    <MonetizationOnIcon/>
                                                                </IconButton>
                                                            </Tooltip>
                                                            {APPLY_DEFAULT_SERVICE}
                                                        </MenuItem>
                                                        <MenuItem onClick={(e) => {
                                                            this.handleMenuDropdownClose(e)
                                                            this.rateToCheapest()
                                                        }} disabled={!this.props.isSelectedShipmentsReady()}>
                                                            <Tooltip title={APPLY_CHEAPEST_SERVICE}>
                                                                <IconButton aria-label={APPLY_RULES}>
                                                                    <PlayForWorkIcon/>
                                                                </IconButton>
                                                            </Tooltip>
                                                            {APPLY_CHEAPEST_SERVICE}
                                                        </MenuItem>
                                                        <MenuItem onClick={(e) => {
                                                            this.props.rateAndCompare()
                                                            this.handleMenuDropdownClose(e)
                                                        }}
                                                                  disabled={!this.props.isSelectedShipmentsReady() || this.props.numSelected !== 1}>
                                                            <Tooltip title={RATE_AND_COMPARE}>
                                                                <IconButton aria-label={RATE_AND_COMPARE}>
                                                                    <CompareIcon/>
                                                                </IconButton>
                                                            </Tooltip>
                                                            {RATE_AND_COMPARE}
                                                        </MenuItem>
                                                        <MenuItem onClick={(e) => {
                                                            this.props.handleBuyShippingServicesOpen()
                                                        }}>
                                                            <Tooltip title={"Amazon Buy Shipping"}>
                                                                <IconButton aria-label={BUY_SHIPPING_SERVICES}
                                                                            disabled={!this.props.enableBuyShipping()}>
                                                                    <ShoppingCartIcon/>
                                                                </IconButton>
                                                            </Tooltip>
                                                            {BUY_SHIPPING_SERVICES}
                                                        </MenuItem>
                                                        <MenuItem onClick={(e) => {
                                                            this.createCloneShipment()
                                                            this.handleMenuDropdownClose(e)
                                                        }}>
                                                            <Tooltip title={CREATE_CLONE_SHIPMENT}>
                                                                <IconButton aria-label={CREATE_CLONE_SHIPMENT}>
                                                                    <FileCopy/>
                                                                </IconButton>
                                                            </Tooltip>
                                                            {CREATE_CLONE_SHIPMENT}
                                                        </MenuItem>
                                                        <MenuItem onClick={(e) => {
                                                            this.markAsRedirected()
                                                            this.handleMenuDropdownClose(e)
                                                        }} disabled={!this.props.isSelectedShipmentsReady()}>
                                                            <Tooltip title={MARK_AS_REDIRECTED}>
                                                                <IconButton aria-label={MARK_AS_REDIRECTED}>
                                                                    <Directions/>
                                                                </IconButton>
                                                            </Tooltip>
                                                            {MARK_AS_REDIRECTED}
                                                        </MenuItem>
                                                        <MenuItem onClick={(e) => {
                                                            this.handleShippedOpen(e)
                                                            this.handleMenuDropdownClose(e)
                                                        }} disabled={!this.props.isSelectedShipmentsReady()}>
                                                            <Tooltip title={MARK_AS_SHIPPED}>
                                                                <IconButton aria-label={MARK_AS_SHIPPED}>
                                                                    <AssignmentTurnedIn/>
                                                                </IconButton>
                                                            </Tooltip>
                                                            {MARK_AS_SHIPPED}
                                                        </MenuItem>
                                                        <MenuItem onClick={(e) => {
                                                            this.shipPdfOrders()
                                                            this.handleMenuDropdownClose(e)
                                                        }} disabled={!this.props.isSelectedShipmentsReady() || this.blockPrint()}>
                                                            <Tooltip title={PRINT_TO_PDF}>
                                                                <IconButton aria-label={PRINT_TO_PDF}>
                                                                    <PictureAsPdfIcon/>
                                                                </IconButton>
                                                            </Tooltip>
                                                            {PRINT_TO_PDF}
                                                        </MenuItem>
                                                        <MenuItem onClick={(e) => {
                                                            this.shipOrders()
                                                            this.handleMenuDropdownClose(e)
                                                        }}
                                                                  disabled={!this.props.isSelectedShipmentsReady() || this.props.shipmentStatus === RETURN || this.blockPrint()}>
                                                            <Tooltip title={SHIP_ORDERS}>
                                                                <IconButton aria-label={SHIP_ORDERS} onClick={() => this.printCheck(this.props.editingShipmentRate)}>
                                                                    <LocalShippingIcon/>
                                                                </IconButton>
                                                            </Tooltip>
                                                            {SHIP_ORDERS}
                                                        </MenuItem>
                                                    </MenuList>
                                                </ClickAwayListener>
                                            </Paper>
                                        </Grow>
                                    )}
                                </Popper>
                            </div> :
                            <div>
                                <Tooltip title={DELETE_SHIPMENTS}>
                                    <IconButton aria-label={DELETE_SHIPMENTS} onClick={this.deleteOrders}>
                                        <DeleteIcon/>
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title={APPLY_RULES}>
                                    <IconButton aria-label={APPLY_RULES}
                                                onClick={() => this.setState({openRuleDialog: true})}>
                                        <PlaylistPlayIcon/>
                                    </IconButton>
                                </Tooltip>
                                {!this.props.editOrderOpen ?
                                    <Tooltip title={ADD_TAG}>
                                        <IconButton aria-label={ADD_TAG}
                                            onClick={(e) => this.handleAddTagOpen(e)}>
                                            <EditAttributesIcon/>
                                        </IconButton>
                                    </Tooltip> : null
                                }
                                <Tooltip title={"Logs"}>
                                    <IconButton aria-label={"Results"}
                                                onClick={() => this.props.openLogs()}>
                                        <AssignmentLateIcon/>
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title={VALIDATE_ADDRESS}>
                                    <IconButton aria-label={APPLY_RULES}
                                        // disabled={!this.props.isSelectedShipmentsReady()}
                                                onClick={this.validateAddress}>
                                        <CheckCircleIcon />
                                    </IconButton>
                                </Tooltip>
                                <span style={{marginTop: '15px'}}>|</span>
                                <Tooltip title={APPLY_DEFAULT_SERVICE}>
                                    <IconButton aria-label={APPLY_RULES}
                                                disabled={!this.props.isSelectedShipmentsReady()}
                                                onClick={this.rateToDefault}>
                                        <MonetizationOnIcon/>
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title={APPLY_CHEAPEST_SERVICE}>
                                    <IconButton aria-label={APPLY_RULES}
                                                disabled={!this.props.isSelectedShipmentsReady()}
                                                onClick={this.rateToCheapest}>
                                        <PlayForWorkIcon/>
                                    </IconButton>
                                </Tooltip>
                                {this.props.numSelected === 1 || this.props.logsDetailsPage ?
                                    <Tooltip title={RATE_AND_COMPARE}>
                                        <IconButton aria-label={RATE_AND_COMPARE}
                                                    disabled={!this.props.isSelectedShipmentsReady()}
                                                    onClick={this.props.rateAndCompare}>
                                            <CompareIcon/>
                                        </IconButton>
                                    </Tooltip> : null}
                                <Tooltip title={"Amazon Buy Shipping"}>
                                    <IconButton aria-label={BUY_SHIPPING_SERVICES}
                                                onClick={this.props.handleBuyShippingServicesOpen}
                                                disabled={!this.props.enableBuyShipping() || !this.props.isSelectedShipmentsReady()}>
                                        <ShoppingCartIcon/>
                                    </IconButton>
                                </Tooltip>
                                <div style={{marginTop: '10px',display:'inline-block', fontSize:'15px'}}>|</div>
                                <Tooltip title={CREATE_CLONE_SHIPMENT}>
                                    <IconButton aria-label={CREATE_CLONE_SHIPMENT} onClick={this.createCloneShipment}>
                                        <FileCopy/>
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title={MARK_AS_REDIRECTED}>
                                    <IconButton aria-label={MARK_AS_REDIRECTED}
                                                disabled={!this.props.isSelectedShipmentsReadyForMarkRedirected()}
                                                onClick={this.markAsRedirected}>
                                        <Directions/>
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title={MARK_AS_SHIPPED}>
                                    <IconButton aria-label={MARK_AS_SHIPPED}
                                                disabled={!this.props.isSelectedShipmentsReadyForMarkShipped()}
                                                onClick={(e) => this.handleShippedOpen(e)}>
                                        <MoveToInbox/>
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title={MARK_AS_CANCELLED}>
                                    <IconButton aria-label={MARK_AS_CANCELLED}
                                                onClick={(e) => this.handleCancelOpen(e)}
                                    >
                                        <Cancel/>
                                    </IconButton>
                                </Tooltip>
                                <div style={{marginTop: '10px',display:'inline-block', fontSize:'15px'}}>|</div>
                                <Tooltip title={PRINT_TO_PDF}>
                                    <IconButton aria-label={PRINT_TO_PDF}
                                                disabled={!this.props.isSelectedShipmentsReady() || this.blockPrint()}
                                                onClick={(event) => {
                                                    event.showLoadingOverlay = true  // Prevents spam clicking the button
                                                    this.shipPdfOrders()
                                                }}>
                                        <PictureAsPdfIcon/>
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title={SHIP_ORDERS}>
                                    <IconButton aria-label={SHIP_ORDERS}
                                                disabled={!this.props.isSelectedShipmentsReady() || this.blockPrint()}
                                                onClick={() => this.printCheck(this.props.editingShipmentRate)}>
                                        <LocalShippingIcon/>
                                    </IconButton>
                                </Tooltip>
                            </div>
                        }
                    </>
                )
            case SHIPPED:
                return (
                    <>
                        {window.screen.width <= 800 ?
                            <div>
                                <IconButton onClick={this.toggleMenu} ref={this.anchorRef}>
                                    <MoreHoriz/>
                                </IconButton>
                                <Popper open={this.state.openMenu} anchorEl={this.anchorRef.current} role={undefined}
                                        transition disablePortal>
                                    {({TransitionProps, placement}) => (
                                        <Grow
                                            {...TransitionProps}
                                            style={{transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'}}
                                        >
                                            <Paper>
                                                <ClickAwayListener onClickAway={this.handleMenuDropdownClose}>
                                                    <MenuList autoFocusItem={this.state.openMenu} id="menu-list-grow">
                                                        <MenuItem onClick={(e) => {
                                                            this.voidOrders()
                                                            this.handleMenuDropdownClose(e)
                                                        }}>
                                                            <Tooltip title={VOID_ORDERS}>
                                                                <IconButton aria-label={VOID_ORDERS}>
                                                                    <BlockIcon/>
                                                                </IconButton>
                                                            </Tooltip>
                                                            {VOID_ORDERS}
                                                        </MenuItem>
                                                        <MenuItem onClick={(e) => {
                                                            this.markAsUnshipped()
                                                            this.handleMenuDropdownClose(e)
                                                        }}>
                                                            <Tooltip title={MARK_AS_UNSHIPPED}>
                                                                <IconButton aria-label={MARK_AS_UNSHIPPED}
                                                                            onClick={this.markAsUnshipped}>
                                                                    <CancelIcon/>
                                                                </IconButton>
                                                            </Tooltip>
                                                            {MARK_AS_UNSHIPPED}
                                                        </MenuItem>
                                                        <MenuItem onClick={(e) => {
                                                            this.reprintOrders()
                                                            this.handleMenuDropdownClose(e)
                                                        }}>
                                                            <Tooltip title={REPRINT_LABELS}>
                                                                <IconButton aria-label={REPRINT_LABELS}>
                                                                    <PrintIcon/>
                                                                </IconButton>
                                                            </Tooltip>
                                                            {REPRINT_LABELS}
                                                        </MenuItem>
                                                        <MenuItem onClick={(e) => {
                                                            this.archiveLabels()
                                                            this.handleMenuDropdownClose(e)
                                                        }}>
                                                            <Tooltip title={ARCHIVE_LABELS}>
                                                                <IconButton aria-label={ARCHIVE_LABELS}>
                                                                    <Archive/>
                                                                </IconButton>
                                                            </Tooltip>
                                                            {ARCHIVE_LABELS}
                                                        </MenuItem>
                                                        <MenuItem onClick={(e) => {
                                                            this.handleMenuDropdownClose(e)
                                                            this.createReturnShipment()
                                                        }}>
                                                            <Tooltip title={CREATE_RETURN_SHIPMENT}>
                                                                <IconButton aria-label={CREATE_RETURN_SHIPMENT}
                                                                            onClick={this.createReturnShipment}>
                                                                    <AssignmentReturn/>
                                                                </IconButton>
                                                            </Tooltip>
                                                            {CREATE_RETURN_SHIPMENT}
                                                        </MenuItem>
                                                        <MenuItem onClick={(e) => {
                                                            this.createCloneShipment()
                                                            this.handleMenuDropdownClose(e)
                                                        }}>
                                                            <Tooltip title={CREATE_CLONE_SHIPMENT}>
                                                                <IconButton aria-label={CREATE_CLONE_SHIPMENT}
                                                                            onClick={this.createCloneShipment}>
                                                                    <FileCopy/>
                                                                </IconButton>
                                                            </Tooltip>
                                                            {CREATE_CLONE_SHIPMENT}
                                                        </MenuItem>
                                                    </MenuList>
                                                </ClickAwayListener>
                                            </Paper>
                                        </Grow>
                                    )}
                                </Popper>
                            </div> :
                            <div>
                                <Tooltip title={VOID_ORDERS}>
                                    <IconButton aria-label={VOID_ORDERS} onClick={this.voidOrders}>
                                        <BlockIcon/>
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title={MARK_AS_UNSHIPPED}>
                                    <IconButton aria-label={MARK_AS_UNSHIPPED} onClick={this.markAsUnshipped}>
                                        <ScheduleIcon/>
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title={REPRINT_LABELS}>
                                    <IconButton aria-label={REPRINT_LABELS} onClick={this.reprintOrders}>
                                        <PrintIcon/>
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title={ARCHIVE_LABELS}>
                                    <IconButton
                                        aria-label={ARCHIVE_LABELS}
                                        onClick={this.archiveLabels}
                                        disabled={this.props.isSelectedShipmentsArchived()}
                                    >
                                        <Archive/>
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title={CREATE_RETURN_SHIPMENT}>
                                    <IconButton aria-label={CREATE_RETURN_SHIPMENT} onClick={this.createReturnShipment}>
                                        <AssignmentReturn/>
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title={CREATE_CLONE_SHIPMENT}>
                                    <IconButton aria-label={CREATE_CLONE_SHIPMENT} onClick={this.createCloneShipment}>
                                        <FileCopy/>
                                    </IconButton>
                                </Tooltip>
                            </div>
                        }
                    </>
                )
            case CANCELLED:
                return <div>
                    <Tooltip title={MARK_AS_UNCANCELLED}>
                        <IconButton aria-label={MARK_AS_UNCANCELLED}
                        onClick={this.markAsUncancelled}>
                            <CancelScheduleSendIcon/>
                        </IconButton>
                    </Tooltip>
                </div>
            default:
                return null
        }
    }

    handleErrorClose = () => {
        this.setState({
            open: false,
        })
    }

    handleRateDefaultClose = () => {
        this.setState({
            rateDefaultopen: false,
        })
    }
    handleRateCheapestClose = () => {
        this.setState({
            rateCheapestopen: false,
        })
    }
    handleValidateAddress = () => {
        this.setState({
            validateAddressOpen: false,
        })
    }

    togglePages = () => {
        this.props.logsTrue()
        this.props.isFromLogsFalse()
        this.props.handleEditShipmentClose()
    }

    togglePage = () => {
        this.props.handleEditShipmentClose()
        this.props.logsFalse()
    }

    handleBackClick = () => {
        if (this.props.logsDetailsPage) {
            this.props.toggleLogsDetailsPage()
        } else if (!this.props.logsDetailsPage && this.props.logsTable) {
            this.props.logsFalse()
        } else {
            this.props.handleEditShipmentClose()
        }

    }

    handleAddTagOpen = (event) => {
        let prevOpenState = this.state.tagOpen
        this.setState({
            tagOpen: !prevOpenState,
            anchorEl: event.currentTarget
        })
    }

    handleAddTagClose = () => {
        this.setState({
            tagOpen: false
        })
    }

    handleShippedOpen = (event) => {
        let prevOpenState = this.state.shippedDialogOpen
        this.setState({
            shippedDialogOpen: !prevOpenState,
            anchorEl: event.currentTarget
        })
    }

    handleShippedClose = () => {
        this.setState({
            shippedDialogOpen: false
        })
    }

    handleShippedSave = () => {
        this.setState({
            shippedDialogOpen: false
        })
        this.markAsShipped()
    }

    handleShippedDateChange = (date) => {
        this.setState({
            shipmentDate: date
        })
    }

    handleShippedNoteChange = (e) => {
        this.setState({
            shipmentNote: e.target.value
        })
    }

    handleCancelOpen = (event) => {
        let prevOpenState = this.state.cancelOpen
        this.setState({
            cancelOpen: !prevOpenState,
            anchorEl: event.currentTarget
        })
    }

    handleCancelClose = () => {
        this.setState({
            cancelOpen: false
        })
    }

    updateMessagesAndShipments = () => {
        const data = {
            fromDate: this.props.fromDateLogs,
            toDate: this.props.toDateLogs
        }
        this.props.getMessages(data)
    }

    addTagDialog = () => {
        return (
            <ClickAwayListener onClickAway={this.handleAddTagClose}>
                <Popper
                    open={this.state.tagOpen}
                    anchorEl={this.state.anchorEl}
                    style={{width: "250px", zIndex: '5', backgroundColor: "white", padding: '10px', borderRadius: '10px'}}
                    placement={"bottom-start"}
                >
                    <Autocomplete
                        multiple
                        open={this.state.tagOpen}
                        limitTags={0}
                        options={this.props.tags}
                        getOptionLabel={(entry) => entry.tagName}
                        autoHighlight={true}
                        value={this.state.tagsSelected}
                        renderOption={(option, { selected }) => (
                        <React.Fragment>
                             <Checkbox
                                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                checkedIcon={<CheckBoxIcon fontSize="small" />}
                                style={{ marginRight: 8 }}
                                checked={selected}
                                indeterminate={this.checkIndeterminate().includes(option.tagName)}
                            />
                            {option.tagName}
                        </React.Fragment>
                    )}
                    name="addTag"
                    onChange={(event, value) => {
                        if (value.length < this.state.tagsSelected.length) {
                            let result = this.state.tagsSelected.filter(o1 => !value.some(o2 => o1.tagName === o2.tagName));
                            this.saveTagChange(result[0].tagId, "rtags", false, 0, result[0].tagName)
                        } else {
                            this.saveTagChange(value[value.length - 1].tagId, "tags", false, 0, value[value.length - 1].tagName)
                        }
                    }}
                    renderInput={(params) =>
                        <TextField
                            {...params}
                            // className="country_selectField_label"
                            label={ADD_TAG}
                            size="small"
                            InputLabelProps={{ shrink: true }}
                            variant="outlined"
                            name="addTag"
                        />
                    }
                />
                </Popper>
            </ClickAwayListener>
        )
    }

    shippedDialog = () => {
        return (
            <ClickAwayListener onClickAway={this.handleShippedClose}>
                    <Popper
                        open={this.state.shippedDialogOpen}
                        anchorEl={this.state.anchorEl}
                        style={{width: "250px", zIndex: '5', backgroundColor: "white", padding: '10px',
                            borderRadius: '10px', border: '1px solid rgb(0 0 0 / 14%)'}}
                        placement={"bottom-start"}
                    >
                        <Box
                            component="form"
                            sx={{
                            '& .MuiTextField-root': { m: 1, width: '25ch' },
                            }}
                            spacing={1}
                            noValidate
                            autoComplete="off"
                        >
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                autoOk
                                name="toDate"
                                variant="inline"
                                inputVariant="outlined"
                                label="Select shipped date"
                                format="yyyy/MM/dd"
                                value={this.state.shipmentDate}
                                InputAdornmentProps={{ position: "start" }}
                                onChange={date => this.handleShippedDateChange(date)}
                            />
                        </MuiPickersUtilsProvider>
                        <TextField
                            id="outlined-multiline-flexible"
                            label="Note"
                            multiline
                            maxRows={4}
                            onChange={e => this.handleShippedNoteChange(e)}
                            variant="outlined"
                        />
                        <div className='horizontally-center-row'>
                            <Button
                                style={{width: "100px"}}
                                variant='contained'
                                onClick={this.handleShippedClose}
                            >
                                {CANCEL}
                            </Button>
                            <div className='margin-20px'/>
                            <Button
                                style={{width: "100px"}}
                                variant='contained'
                                onClick={this.handleShippedSave}
                                color="primary"
                            >
                                {SAVE}
                            </Button>
                        </div>
                    </Box>
                </Popper>
            </ClickAwayListener>
        )
    }

    cancelDialog = () => {
        return (
            <ClickAwayListener onClickAway={this.handleCancelClose}>
                <Popper
                    open={this.state.cancelOpen}
                    anchorEl={this.state.anchorEl}
                    style={{width: "250px", zIndex: '5', backgroundColor: "white", padding: '10px',
                        borderRadius: '10px', border: '1px solid rgb(0 0 0 / 14%)'}}
                    placement={"bottom-start"}
                >
                    <MarkAsCancelledDialog
                        selected = {this.props.selected}
                        editOrderOpen = {this.props.editOrderOpen}
                        handleEditShipmentClose = {this.props.handleEditShipmentClose}
                        clearSelected = {this.props.clearSelected}
                        tags ={this.props.tags}
                    />
                </Popper>
            </ClickAwayListener>
        )
    }

    buyShippingButtons = () => {
        return (
            <>
                <Tooltip title={"Exit Buy Shipping"}>
                    <IconButton aria-label={"Exit Buy Shipping"}
                                onClick={() => this.props.handleCancelBuyShippingService()}>
                        <KeyboardBackspaceIcon/>
                    </IconButton>
                </Tooltip>
            </>
        )
    }

    render() {
        return (
            <>
                <FeedbackBar
                    open={this.state.shipOpen}
                    handleClose={() => this.setState({shipOpen: false, shipMessage: ""})}
                    severity={ERROR}
                    message={this.state.shipMessage}
                />
                <FeedbackBar
                    open={this.state.printCount}
                    severity={SUCCESS}
                    message={this.state.shipMessage}
                    handleClose={() => this.setState({printCount: false, shipMessage: ""})}
                />
                <FeedbackBar
                    open={this.state.open}
                    severity={ERROR}
                    message={"No printer selected"}
                    handleClose={this.handleErrorClose}
                />
                <FeedbackBar
                    open={this.state.rateDefaultopen}
                    severity={SUCCESS}
                    message={this.props.status.statusText + " " + this.props.status.messages}
                    handleClose={this.handleRateDefaultClose}
                    canCopy={this.props.status.messages?.length > 0}
                    pageLink={() => this.props.openLogs()}
                />
                <FeedbackBar
                    open={this.state.validateAddressOpen}
                    severity={SUCCESS}
                    message={this.props.status.statusText + " " + this.props.status.messages}
                    handleClose={this.handleValidateAddress}
                    canCopy={this.props.status.messages?.length > 0}
                    pageLink={() => this.props.openLogs()}
                    />
                <FeedbackBar
                    open={this.state.rateCheapestopen}
                    severity={SUCCESS}
                    message={this.props.status.statusText + " " + this.props.status.messages}
                    handleClose={this.handleRateCheapestClose}
                    canCopy={this.props.status.messages?.length > 0}
                    pageLink={() => this.props.openLogs()}
                />

                <AutomationRuleDialog
                    open={this.state.openRuleDialog}
                    handleClose={() => this.setState({openRuleDialog: false, selectedRules: []})}
                    rules={this.props.rules}
                    orders={this.props.selected}
                    runRules={this.runRules}
                    selectedRules={this.state.selectedRules}
                    allSelected={this.state.allSelected}
                    isSelected={this.isSelected}
                    handleSelect={this.handleSelect}
                    handleSelectAll={this.handleSelectAll}
                    ruleGroup={"Shipments"}
                />

                <ShipmentPrintConfirmationDialog
                    open={this.state.printingWithNoRate}
                    handleClose={() => {this.setState({printingWithNoRate:false})}}
                    printAnyways={() => {
                        this.shipOrders()
                        this.setState({printingWithNoRate:false})
                    }}
                />
                {/* Feedback bar for Cancel order error */}
                <FeedbackBar
                    open={this.state.error}
                    handleClose={this.handleErrorClose}
                    severity={ERROR}
                    message={CANCEL_ORDER_ERROR}
                />
                {/* Feedback bar for cancel order status */}
                <FeedbackBar
                    open={this.props.open}
                    handleClose={() => this.props.setOpen(false)}
                    severity={this.props.status.success ? SUCCESS : ERROR}
                    message={this.props.status.success ? ORDERS_CANCELLED_SUCCESSFULLY : CANCEL_ORDER_REQUEST_ERROR}
                />
                {/* Feedback bar for create order status */}
                <FeedbackBar
                    open={this.props.createOrderOpen}
                    handleClose={() => this.props.setCreateOrderOpen(false)}
                    severity={this.props.status.success ? SUCCESS : ERROR}
                    message={this.props.status.success ? CREATE_ORDER_SUCCESS : CREATE_ORDER_ERROR}
                />
                {/* Feedback bar for create return status */}
                <FeedbackBar
                    open={this.props.createReturnOpen}
                    handleClose={() => this.props.setCreateReturnOpen(false)}
                    severity={this.props.status.success ? SUCCESS : ERROR}
                    message={this.props.status.success ? CREATE_RETURN_SUCCESS : CREATE_ORDER_ERROR}
                />
                {/* Feedback bar for create cloned status */}
                <FeedbackBar
                    open={this.props.createClonedOpen}
                    handleClose={() => this.props.setCreateClonedOpen(false)}
                    severity={this.props.status.success ? SUCCESS : ERROR}
                    message={this.props.status.success ? CREATE_CLONED_SUCCESS : CREATE_ORDER_ERROR}
                />
                {/* Feedback bar for archive label status */}
                <FeedbackBar
                    open={this.props.archiveOpen}
                    handleClose={() => this.props.setArchiveOpen(false)}
                    severity={this.props.archiveStatus.severity}
                    message={this.props.archiveStatus.message}
                />
                {/* Feedback bar for void order status */}
                <FeedbackBar
                    open={this.props.voidOpen}
                    handleClose={() => this.props.setVoidOpen(false)}
                    severity={this.props.voidStatus.severity}
                    message={this.props.voidStatus.message}
                />
                <Toolbar className="toolbar">
                    <div className="contextualMenu">
                        {this.props.isBuyShippingServices ? this.buyShippingButtons() :
                            <>
                                {this.props.shipmentSummary ?
                                    <>
                                    <Tooltip title={BACK_TO_MENU_TITLE}>
                                        <IconButton aria-label={BACK_TO_MENU_TITLE}
                                                    onClick={() => this.props.toggleShipmentSummary()}>
                                            <KeyboardBackspaceIcon/>
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title={"Export to Excel"}>
                                        <IconButton aria-label={"Export to Excel"}
                                                    onClick={() => this.props.exportShipmentSummaryToExcel()}>
                                            <SaveAltIcon/>
                                        </IconButton>
                                    </Tooltip> </>
                                    :
                                    <>
                                        {(this.props.editOrderOpen || this.props.logsTable) && !this.props.isRateAndCompare ?
                                            <Tooltip title={BACK_TO_MENU_TITLE}>
                                                <IconButton aria-label={BACK_TO_MENU_TITLE}
                                                            onClick={() => this.handleBackClick()}>
                                                    <KeyboardBackspaceIcon/>
                                                </IconButton>
                                            </Tooltip>
                                            : null}
                                        {(this.props.logsTable) && !this.props.isRateAndCompare && (this.props.editOrderOpen || !this.props.logsDetailsPage) ?
                                            <Tooltip title={REFRESH}>
                                                <IconButton aria-label={REFRESH}
                                                            onClick={this.updateMessagesAndShipments}>
                                                    <RefreshIcon/>
                                                </IconButton>
                                            </Tooltip>
                                            : null}
                                        <>

                                            {this.props.editOrderOpen && !this.props.isRateAndCompare ?
                                                <>
                                                    {window.screen.width > 800 ?
                                                        <Tooltip title={"Hidden Button for spacing"}>
                                                            <IconButton style={{visibility: 'hidden'}}>
                                                                <KeyboardBackspaceIcon style={{visibility: 'hidden'}}/>
                                                            </IconButton>
                                                        </Tooltip> : null
                                                    }
                                                </>
                                                : null
                                            }

                                            {this.props.numSelected > 0 || this.props.logsDetailsPage ? (
                                                this.props.isRateAndCompare ?
                                                    <Tooltip title={BACK_TO_MENU_TITLE}>
                                                        <IconButton aria-label={BACK_TO_MENU_TITLE}
                                                                    onClick={() => this.props.handleRateCancel()}>
                                                            <KeyboardBackspaceIcon/>
                                                        </IconButton>
                                                    </Tooltip>
                                                    :
                                                    <>
                                                        {!this.props.editOrderOpen && !this.props.logsDetailsPage && !this.props.fromLogsState && !this.props.logsTable ?
                                                            <div
                                                                className="contextualMenuText">{this.props.numSelected === 0 ? this.state.numSelected : this.props.numSelected} selected</div> : null}
                                                        {!this.props.logsTable || (this.props.logsDetailsPage && this.props.logsTable) ? this.displayActions() : null}
                                                        {this.state.tagOpen ? this.addTagDialog() : null}
                                                        {this.state.cancelOpen ? this.cancelDialog() : null}
                                                        {this.state.shippedDialogOpen ? this.shippedDialog() : null}
                                                        {!this.props.editOrderOpen && this.props.numSelected === 0 ?
                                                            <span style={{marginTop: '10px'}}>|</span> : null}
                                                        <div/>
                                                    </>) : null
                                            }

                                            {this.props.numSelected > 0 && this.props.loading ?
                                                <LinearProgressWithLabel
                                                    width={"20%"}
                                                    value={(this.props.printedCount / this.state.numSelected) * 100}
                                                /> : null
                                            }

                                            {this.props.title == PENDING_SHIPMENTS || this.props.title == SHIPPED_SHIPMENTS?
                                                this.props.isImporting ?
                                                    <Tooltip title={BACK_TO_MENU_TITLE}>
                                                        <IconButton aria-label={BACK_TO_MENU_TITLE}
                                                                    onClick={() => this.props.handleFileUploadCancel()}>
                                                            <KeyboardBackspaceIcon/>
                                                        </IconButton>
                                                    </Tooltip>
                                                    :
                                                    this.props.openCreateOrderDialog ?
                                                        <Tooltip title={BACK_TO_MENU_TITLE}>
                                                            <IconButton aria-label={BACK_TO_MENU_TITLE}
                                                                        onClick={() => this.props.handleCreateOrderDialogClose()}>
                                                                <KeyboardBackspaceIcon/>
                                                            </IconButton>
                                                        </Tooltip>
                                                        :
                                                        !this.props.editOrderOpen && this.props.numSelected === 0 && !this.props.logsTable ?
                                                            <>
                                                                {this.props.title === PENDING_SHIPMENTS ?
                                                                    <>
                                                                        <Tooltip title={CREATE_ORDER_TITLE}>
                                                                            <IconButton aria-label={CREATE_ORDER_TITLE}
                                                                                        onClick={() => this.props.handleCreateOrderDialogOpen()}>
                                                                                <AddCircleIcon/>
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                        <Tooltip title={IMPORT_ORDER_TITLE}>
                                                                            <IconButton aria-label={CREATE_ORDER_TITLE}
                                                                                        onClick={() => this.props.setImportingPage(true)}>
                                                                                <PublishIcon/>
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                        <Tooltip title={"Open Summary"}>
                                                                            <IconButton aria-label={"Open Summary"}
                                                                                        onClick={() => this.props.toggleShipmentSummary()}>
                                                                                <Assignment/>
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                        <Tooltip title={"Logs"}>
                                                                            <IconButton aria-label={"Results"}
                                                                                        onClick={() => this.props.openLogs()}>
                                                                                <AssignmentLateIcon/>
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                    </> :
                                                                    <Tooltip title={"Open Summary"}>
                                                                        <IconButton aria-label={"Open Summary"}
                                                                                    onClick={() => this.props.toggleShipmentSummary()}>
                                                                            <Assignment/>
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                }
                                                            </>
                                                            : null
                                                : null
                                            }
                                        </>
                                    </>
                                }
                            </>
                        }
                    </div>

                    <Typography className="titleTypography" variant="h6" id="tableTitle">
                        {this.props.isBuyShippingServices ? "Buy Shipping" : this.props.shipmentSummary ? this.props.title === UNSHIPPED ? "Pending shipments - Summary" : "Shipped shipments - Summary" : (this.props.logsTable || this.props.fromLogsState) && ( !this.props.logsDetailsPage)  ? "Pending shipments - Logs" :  this.props.logsDetailsPage ? "Pending shipments - " + this.props.orderIdFromLogs : this.props.editOrderOpen ? this.props.detailsTitle : this.props.title}
                    </Typography>
                </Toolbar>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    printedCount: printedCountSelector(state),
    rules: rulesSelector(state),
    loading: loadingSelector(state),
    open: openSelector(state),
    status: statusSelector(state),
    createOrderOpen: createOrderOpenSelector(state),
    createReturnOpen: createReturnOpenSelector(state),
    createClonedOpen: createClonedOpenSelector(state),
    voidOpen: voidOpenSelector(state),
    voidStatus: voidStatusSelector(state),
    archiveOpen: archiveOpenSelector(state),
    archiveStatus: archiveStatusSelector(state),
    carriers: carriersSelector(state),
    printedZpl: testZplSelector(state),
    printers: printersSelector(state),
})

const actionCreators = {
    printOrder,
    markAsShipped,
    markAsRedirected,
    markAsUnshipped,
    markAsUncancelled,
    updatePrintedCount,
    fetchRules,
    runRules,
    setOpen,
    cancelOrder,
    setCreateOrderOpen,
    setCreateClonedOpen,
    setCreateReturnOpen,
    reprintOrder,
    archiveLabel,
    voidOrder,
    setVoidOpen,
    setArchiveOpen,
    editOrder,
    editMultipleOrder,
    listCarriers,
    listPrinters,
    deleteShipments,
    printToPdf,
    getMessages,
}

ShipmentsTableToolbar.propTypes = {
    numSelected: PropTypes.number,
    title: PropTypes.string,
    clearSelected: PropTypes.func,
    selected: PropTypes.array,
    shipmentStatus: PropTypes.string,
    rows: PropTypes.array,
    tags: PropTypes.array,
    carriers: PropTypes.array,
    editOrderOpen: PropTypes.bool,
    handleEditShipmentClose: PropTypes.func,
    detailsTitle: PropTypes.string,
    isSelectedShipmentsArchived: PropTypes.func,
    isSelectedShipmentsReady: PropTypes.func,
    isSelectedShipmentsReadyForMarkRedirected: PropTypes.func,
    isShipmentReady: PropTypes.func,
    isShipmentReadyForMarkRedirected: PropTypes.func,
    markAsShipped: PropTypes.func,
    markAsRedirected: PropTypes.func,
    markAsUncancelled: PropTypes.func,
    createReturnShipment: PropTypes.func,
    createCloneShipment: PropTypes.func,
    markAsUnshipped: PropTypes.func,
    deleteShipments: PropTypes.func,
    printToPdf: PropTypes.func,
    openLogs: PropTypes.func,
}

ShipmentsTableToolbar.defaultProps = {
    numSelected: 0,
    title: "",
    clearSelected: () => { },
    selected: [],
    shipmentStatus: "",
    rows: [],
    tags: [],
    carriers: [],
    editOrderOpen: false,
    handleEditShipmentClose: () => { },
    detailsTitle: "",
    isSelectedShipmentsArchived: () => {},
    isSelectedShipmentsReady: () => {
    },
    isSelectedShipmentsReadyForMarkRedirected: () => {
    },
    isShipmentReady: () => {
    },
    isShipmentReadyForMarkRedirected: () => {
    },
    markAsShipped: () => {
    },
    markAsRedirected: () => {
    },
    createReturnShipment: () => {
    },
    createCloneShipment: () => {
    },
    markAsUnshipped: () => {
    },
    deleteShipments: () => {
    },
    printToPdf: () => {
    },
    openLogs: ()=> {
    }
}

export default withShipment({
    mapStateToProps,
    actionCreators
}, ShipmentsTableToolbar)