import React from "react";
import {
    Button,
    Dialog, DialogActions,
} from "@material-ui/core";
import {CANCEL_PRINT, CANCEL_RUN_RULES, PRINT_ANYWAYS, RUN_RULES} from "./constants";
import {DialogContent} from "@mui/material";

class ShipmentPrintConfirmationDialog extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    render() {
        return (
            <Dialog
                open={this.props.open}
                onClose={this.props.handleClose}
            >
                <DialogContent dividers={true}>
                    <p>This shipment has not been rated, do you wish to continue printing?</p>
                </DialogContent>

                <DialogActions>
                    <Button  onClick={()=>this.props.handleClose()} variant='contained'>
                        {CANCEL_PRINT}
                    </Button>
                    {/* Disable if no rules selected */}
                    <Button onClick={()=>this.props.printAnyways()} color="primary" variant="contained">
                        {PRINT_ANYWAYS}
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

export default ShipmentPrintConfirmationDialog