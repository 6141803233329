import React from 'react'

// Material components
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import Paper from "@material-ui/core/Paper";

// Custom components
import withShipment from "../../../withShipment";
import CustomToolbar from "../../global/CustomToolbar";
import DateFnsUtils from "@date-io/date-fns";
import EditInvoices from "./EditInvoices"
import InvoicesTable from "./InvoicesTable";

// Redux
import PropTypes from "prop-types";
import {
    getAllSupplierInvoices,
    storeAllSupplierInvoices,
    processSupplierInvoice,
    reprocessSupplierInvoice,
    getSupplierInvoicePDF,
    updateSupplierInvoice,
    deleteSupplierInvoice,
    updateSupplierInvoiceStatus,
    overwriteSupplierInvoice,
    storeSupplierInvoicePDF,
    getSupplierInvoiceAttachment,
    deleteSupplierInvoiceAttachment,
    getInvoicePurchaseOrder,
    updateSupplierInvoiceChanged,
    storeSupplierInvoiceOperationResponse,
    saveSupplierInvoicePaymentDetails,
} from "../../../redux/actions/invoices";
import { clearStatus } from "../../../redux/actions/global";
import {
    allSupplierInvoicesSelector,
    processingSelector,
    supplierInvoiceChangedSelector,
    supplierInvoiceOperationResponseSelector,
    supplierInvoicePDFSelector,
    supplierInvoicePOSelector
} from "../../../redux/selectors/invoices";
import { ERROR, SUCCESS } from "../../settings/ManageUsers/constants";

// Icons
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import VisibilityIcon from '@material-ui/icons/Visibility';
import SaveIcon from '@material-ui/icons/Save';
import RateReviewIcon from '@material-ui/icons/RateReview';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import CheckIcon from '@material-ui/icons/Check';
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

import './ChequeToPrint.css';
import './micr-e13b.ttf';

// Constants
import ErrorFeedbackBar from '../../global/ErrorFeedbackBar';
import { APPROVE_STATUS, INVOICE_ITEM_CELLS, NEEDS_REVIEW_STATUS, PAID_STATUS, DUPLICATE_STATUS } from "./constants";
import FeedbackBar from "../../feedbackBar/FeedbackBar";
import { statusSelector } from "../../../redux/selectors/global";
import {automationInfoSelector, tableSettingSelector} from '../../../redux/selectors/account';
import { getTableSetting, saveTableSetting } from "../../../redux/actions/account";
import { userInfoSelector } from '../../../redux/selectors/auth';
import {bankAccountsSelector, suppliersSelector} from '../../../redux/selectors/settings';
import {listAllBankAccounts, listSuppliers, previewBankFile} from "../../../redux/actions/settings";
import {DEFAULT_PDF_ZOOM, getByteArray} from "../../global/constants";
import {DEFAULT_ROWS_PER_PAGE} from "../../returns/constants";
import DescriptionIcon from "@material-ui/icons/Description";
import DeleteIcon from "@material-ui/icons/Delete";
import ClearIcon from "@material-ui/icons/Clear";
import ZoomOutIcon from "@material-ui/icons/ZoomOut";
import ZoomInIcon from "@material-ui/icons/ZoomIn";
import {GetApp, HourglassEmptyTwoTone, Restore} from "@material-ui/icons";
import SettingsIcon from "@material-ui/icons/Settings";
import SupplierInvoiceTemplates
    from "../../settings/InvoiceTemplates/supplierInvoiceTemplates/SupplierInvoiceTemplates";
import PaymentPopper from "../PaymentPopper";
import {INVOICE_TYPE_CARRIER, INVOICE_TYPE_FTL_CARRIER, INVOICE_TYPE_SUPPLIER} from "../constants";
import ReactToPrint from "react-to-print";
import {IconButton} from "@material-ui/core";
import PrintIcon from "@material-ui/icons/Print";
import {ComponentChequeToPrint} from "./ChequeToPrint";
import {getCompany} from "../../../redux/actions/automationRules";
import SupplierChequeForm from "./SupplierChequeForm";
import {transactionCategoriesSelector} from "../../../redux/selectors/accounting";
import {validateChequeNumber, listTransactionCategories, saveCheque} from "../../../redux/actions/accounting";
import {LTL_FTL_CARRIER, STANDARD_CARRIER} from "../carrierInvoices/constants";

class SupplierInvoices extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 0,
            rowsPerPage: DEFAULT_ROWS_PER_PAGE,
            editing: null,
            viewing: false,
            selecting: false,
            isCheque: false,
            selectedEntries: {},
            invoicePDF: new Uint8Array(0),
            searchText: '',
            scale: DEFAULT_PDF_ZOOM,
            itemError: { index: -1, id: "" },
            fromDate: this.getFromDate(),
            toDate: this.getToDate(),
            newFiles: [],
            viewingFile: {},
            deletingAttachment: false,
            openInvoiceTemplates: false,
            feedbackBarOpen:false,
            feedbackBarText:"",
            invoicesList: this.props.allInvoices,
                memoo: '',
                payToName: '',
                chequeNumber: '',
                paymentDate: null,
                amount: '',
                amountWords: '',
                category: '',
                bankAccount: null,
            supplierIds: [],
            error: false,
            companyAddress:[],
            transactionCategories:this.props.transactionCategories,
            fetchedList: true
        }
        this.hiddenFileInput = React.createRef()
        this.fileUpload = this.fileUpload.bind(this)
        this.setEditing = this.setEditing.bind(this)
        this.previewInvoice = this.previewInvoice.bind(this)
        this.save = this.save.bind(this)
        this.handleStatusClose = this.handleStatusClose.bind(this)
    }
    componentDidMount() {
        this.setState({
            fetchedList: true
        })
        this.props.getTableSetting(this.props.user.userId)
        this.props.listSuppliers()
        this.props.listAllBankAccounts()
        if (this.state.editing) {
            this.props.getInvoicePurchaseOrder(this.state.editing.poNumber)
        }
        this.props.listTransactionCategories()
        this.props.getCompany()
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.automationSettings !== this.props.automationSettings) {
            this.setState({
                companyAddress: this.props?.automationSettings?.companyAddress === undefined ? [] : this.props?.automationSettings?.companyAddress
            });
        }

        if (prevProps.transactionCategories !== this.props.transactionCategories) {
            this.setState({
                transactionCategories : this.props.transactionCategories
            })
        }
        //sort state transactionCategories by displayOrder
        if (prevState.transactionCategories !== this.state.transactionCategories) {
            this.setState({
                transactionCategories : this.state.transactionCategories.sort((a, b) => a.displayOrder - b.displayOrder)
            })
        }
        if (this.state.viewing && this.state.invoicePDF.length !== this.props.invoicePDF.length)
            this.setState({ invoicePDF: this.props.invoicePDF })

        if ((prevState.editing !== this.state.editing) && (this.state.editing) && !Object.keys(this.props.purchaseOrder).length) {
            this.props.getInvoicePurchaseOrder(this.state.editing.poNumber)
        }

        if (prevProps.allInvoices !== this.props.allInvoices) {
            this.setState({ invoicesList: this.props.allInvoices });
            // when the invoice information is updated, and we are editing an invoice, then also update this invoice
            if (this.state.editing) {
                this.setState({
                    editing: this.props.allInvoices.filter(invoice=>invoice.invoiceNumber == this.state.editing.invoiceNumber)[0]
                })
            }
        }

        if (this.props.editingInvoiceChanged) {
            if (this.state.editing) {
                this.setState({
                    editing: this.props.allInvoices.filter(invoice => invoice.invoiceNumber == this.state.editing.invoiceNumber)[0]
                })
            }
            this.props.updateSupplierInvoiceChanged()
        }

        if (prevProps.operationResponse !== this.props.operationResponse && this.props.operationResponse !== "") {
            this.setState({
                feedbackBarOpen: true,
                feedbackBarText:this.props.operationResponse
            })
            this.props.storeSupplierInvoiceOperationResponse("")
        }

        if (!prevState.editing && prevState.editing !== this.state.editing) {
            if (this.state.editing) {
                this.setState(prevState => {

                    //only show those line items with quantity > 0
                    const updatedItems = prevState.editing.supplierInvoiceItems?.filter(invoice => invoice.quantity > 0);

                    // if a lineItem have tax as a null value, assign it a 0.00, Also calulates the Amount subtotal and Tax subtotal
                    let amountTotal = 0
                    let taxTotal = 0
                    let taxChanged = false
                    updatedItems.map(item =>{
                        if (!item.tax) {
                            item.tax = "0.00"
                            taxChanged = true
                        }
                        amountTotal += parseFloat(item.amount)
                        taxTotal += parseFloat(item.tax)
                    })

                    // Check if the filtered items are different from the current state
                    return {
                        editing: {
                            ...prevState.editing, // preserve all the existing properties of editing
                            supplierInvoiceItems: updatedItems,
                            amountSubTotal: amountTotal.toFixed(2),
                            taxSubTotal: taxTotal.toFixed(2)
                        },
                    };

                    return null; // No state update
                });
            }
        }

        // when purchase order changes, then recalculate the tax for the whole invoice
        if (prevProps.purchaseOrder.poNumber && prevProps.purchaseOrder?.poNumber!== this.props.purchaseOrder?.poNumber) {
            if (this.state.editing) {
                let tempEditing = this.state.editing
                tempEditing.taxPercentage = this.props.purchaseOrder.taxPercentage
                this.setState({
                    editing: tempEditing
                }, () => {this.triggerLineItemCalculations()})
            }
        }
    }

    triggerLineItemCalculations = () => {
        let tempEditing = this.state.editing
        let newTaxSubTotal = 0;
        let newAmountSubTotal = 0;
        // recalculate for each entry and recalculate the sum and tax
        tempEditing.supplierInvoiceItems.map((item,index) => {
            this.changeEditingItem('discount',item.discount,index)
            newTaxSubTotal += parseFloat(item.tax.replace(/,/g, ''));
            newAmountSubTotal += parseFloat(item.amount.replace(/,/g, ''));
        });
        tempEditing.amountSubTotal = this.formatDollar(newAmountSubTotal.toFixed(2)); // Convert back to a string with 2 decimal places, if needed.
        tempEditing.taxSubTotal = this.formatDollar(newTaxSubTotal.toFixed(2)); // Convert back to a string with 2 decimal places, if needed.

        this.setState({
            editing: tempEditing
        })
    }
    getFromDate = () => {
        var date = new Date();
        date.setHours(0);
        date.setMinutes(0);
        date.setSeconds(0);
        date.setDate(date.getDate() - 14)
        return date;
    }

    getToDate = () => {
        var date = new Date();
        date.setHours(23);
        date.setMinutes(59);
        date.setSeconds(59);
        return date;
    }

    handleDateChange = (date, key) => {
        this.setState({
            [key]: date
        })
    }

    getPurchaseOrder = (value) => {
        this.props.getInvoicePurchaseOrder(value.trim())
    }

    setPage = (page) => {
        this.setState({
            page: page
        })
    }

    setRowsPerPage = (rowsPerPage) => {
        this.setState({
            rowsPerPage: rowsPerPage
        })
    }

    comparePDF(array1, array2) {
        if (array1.length !== array2.length) return false
        for (let i = 0; i < array1.length; i++) {
            if (array1[i] !== array2[i]) return false
        }
        return true
    }
    setEditing(value) {
        this.setState({ editing: value })
    }
    processInvoice() {
        this.hiddenFileInput.current.click()
    }
    openInvoiceTemplates() {
        this.setState({
            openInvoiceTemplates: true
        })
    }
    closeInvoiceTemplates = () => {
        this.setState({
            openInvoiceTemplates: false
        })
    }
    openIsCheque = () => {
        this.setState({
            isCheque: true
        })
    }
    closeIsCheque = () => {
        this.setState({
            isCheque: false
        })
    }
    reprocessInvoice = () => {
        let storage = localStorage.getItem("supplierInvoices")
        let fromDate = this.state.fromDate
        let toDate = this.state.toDate
        if (storage) {
            let storageObj = JSON.parse(storage);
            fromDate = storageObj?.fromDate
            toDate = storageObj?.toDate
        }
        this.props.reprocessSupplierInvoice({supplierInvoiceId: this.state.editing.supplierInvoiceId}, (invoice) => this.setEditing(invoice),
            fromDate, toDate)
    }
    changeEditing(name, value) {
        if (!this.state.editing) return
        if (name === "currencyCode") {
            let tempEditing = { ...this.state.editing }
            for (const item of tempEditing.supplierInvoiceItems) {
                item.currencyCode = value
            }
            this.setState({ editing: tempEditing })
            return
        }

        let tempEditing = this.state.editing
        let nextChange = {name: name, value: value, index: -1} // index -1 means "not an indexed value" here
        let oldValue
        while (nextChange) {

            oldValue = tempEditing[nextChange.name]
            tempEditing[nextChange.name] = nextChange.value

            if (name === 'invoiceSubTotal' && nextChange['name'] === 'invoiceSubTotal') nextChange['name'] = 'invoiceTax' // as a special case, skip updating tax if subtotal was edited manually

            nextChange = this.getNextChange(nextChange, this.state.editing, oldValue)
        }

        this.setState({
            editing: tempEditing
        })
    }
    changeEditingItem(name, value, index) {
        if (!this.state.editing) return
        let tempEditing = this.state.editing

        let nextItemChange = {name: name, value: value, index: index}
        let oldValue
        while (nextItemChange) {

            if (nextItemChange.index !== -1) {
                oldValue = tempEditing.supplierInvoiceItems[nextItemChange.index][nextItemChange.name]
                tempEditing.supplierInvoiceItems[nextItemChange.index][nextItemChange.name] = nextItemChange.value
            } else {
                oldValue = tempEditing[nextItemChange.name]
                tempEditing[nextItemChange.name] = nextItemChange.value
            }
            nextItemChange = this.getNextChange(nextItemChange, tempEditing, oldValue)
        }

        let newTaxSubTotal = 0;
        let newAmountSubTotal = 0;
        tempEditing.supplierInvoiceItems.map(item => {
            newTaxSubTotal += parseFloat(item.tax.replace(/,/g, ''));
            newAmountSubTotal += parseFloat(item.amount.replace(/,/g, ''));
        });
        tempEditing.amountSubTotal = this.formatDollar(newAmountSubTotal.toFixed(2)); // Convert back to a string with 2 decimal places, if needed.
        tempEditing.taxSubTotal = this.formatDollar(newTaxSubTotal.toFixed(2)); // Convert back to a string with 2 decimal places, if needed.


        this.setState({
            editing: tempEditing,
            itemError: this.state.itemError.index === index && this.state.itemError.id === name ? { index: -1, id: "" } : this.state.itemError
        })
    }

    getNextChange(oldNextItemChange, supplierInvoice, oldValue) {
        let value = 0.0
        let newNextItemChange

        switch (oldNextItemChange.name) {
            case 'quantity':
            case 'unitPrice':
            case 'discount':
                value = (supplierInvoice.supplierInvoiceItems[oldNextItemChange.index]['quantity'] *
                    this.parseNumber(supplierInvoice.supplierInvoiceItems[oldNextItemChange.index]['unitPrice'])) -
                    this.parseNumber(supplierInvoice.supplierInvoiceItems[oldNextItemChange.index]['discount'])
                newNextItemChange = {
                    name: 'amount',
                    value: this.formatDollar((isNaN(value) ? 0 : value).toString(), true),
                    index: oldNextItemChange.index
                }
                break
            case 'amount':
                value = (supplierInvoice?.taxPercentage / 100) * this.parseNumber(supplierInvoice.supplierInvoiceItems[oldNextItemChange.index]['amount'])
                newNextItemChange = {
                    name: 'tax',
                    value: this.formatDollar((isNaN(value) ? 0 : value).toString(), true),
                    index: oldNextItemChange.index
                }
                break
            case 'tax':
                supplierInvoice.supplierInvoiceItems.forEach((item) => value += this.parseNumber(item['amount']))
                newNextItemChange = {
                    name: 'invoiceSubTotal',
                    value: this.formatDollar((isNaN(value) ? 0 : value).toString(), true),
                    index: -1
                }
                break
            case 'invoiceSubTotal':
                supplierInvoice.supplierInvoiceItems.forEach((item) => value += this.parseNumber(item['tax']))
                newNextItemChange = {
                    name: 'invoiceTax',
                    value: this.formatDollar((isNaN(value) ? 0 : value).toString(), true),
                    index: -1
                }
                break
            case 'invoiceTax':
                value = this.parseNumber(supplierInvoice['invoiceSubTotal']) +
                    this.parseNumber(supplierInvoice['invoiceTax'])
                newNextItemChange = {
                    name: 'invoiceTotal',
                    value: this.formatDollar((isNaN(value) ? 0 : value).toString(), true),
                    index: -1
                }
                break
            case 'taxPercentage':
                this.triggerLineItemCalculations()
            default:
                return null
        }

        if(newNextItemChange['name'] === 'quantity') {
            // as a special case, quantity is saved as a number, not a string
            newNextItemChange['value'] = isNaN(value) ? 0 : value
        }

        return newNextItemChange
    }

    parseNumber = (string) => {
        return parseFloat(string?.toString().replaceAll(',',''))
    }

    formatDollar = (amount, forceTwoDecimals) => { // Regex expression taken from https://stackoverflow.com/questions/2901102
        let value

        if (forceTwoDecimals) {
            value = this.parseNumber(amount).toFixed(2)
        } else {
            // if the amount has more than two decimal places, limit it to two
            if (amount.match(/.*\.\d{2}.*/)) {
                try {
                    value = this.parseNumber(amount).toFixed(2)
                } catch {
                    value = amount.replaceAll(',','')
                }
            } else {
                value = amount.replaceAll(',','')
            }
        }

        return value.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
    }

    blurEditing = (name, value, index) => {
        let tempEditing = this.state.editing

        switch (name) {
            case 'quantity':
                tempEditing.supplierInvoiceItems[index][name] = isNaN(parseFloat(value)) ? "0" : this.parseNumber(value)
                break
            case 'unitPrice':
            case 'discount':
            case 'amount':
            case 'tax':
                tempEditing.supplierInvoiceItems[index][name] = isNaN(parseFloat(value)) ? "0.00" : this.formatDollar(value, true)
                break
            case 'invoiceSubTotal':
            case 'invoiceTax':
                tempEditing[name] = isNaN(parseFloat(value)) ? "0.00" : this.formatDollar(value, true)
                break
        }

        this.setState({
            editing: tempEditing,
        })
    }

    getSupplierTaxPercentage = (invoice) => {
        let supplier = this.props.suppliers.find((supplier) => {
            return supplier.name === invoice.supplierName
        })

        if (!supplier) {
            console.log("Error: supplier tax not found")
            return 0
        } else {
            return supplier.taxPercentage
        }
    }

    save(statusOverride, validate) {
        if (!this.state.editing) return
        let tempEditing = this.state.editing
        let newFiles = this.state.newFiles
        for (let i = 0; i < tempEditing.supplierInvoiceItems.length; ++i) {
            for (const itemCell of INVOICE_ITEM_CELLS) {
                if (itemCell.id !== "shortcode" && !tempEditing.supplierInvoiceItems[i][itemCell.id]) {
                    this.setState({ itemError: { index: i, id: itemCell.id } })
                    return
                }
                if (itemCell.addDollar) tempEditing.supplierInvoiceItems[i][itemCell.id] = tempEditing.supplierInvoiceItems[i][itemCell.id].replace(",", "")
            }
        }
        tempEditing.invoiceSubTotal = tempEditing.invoiceSubTotal.replace(",", "")
        tempEditing.invoiceTax = tempEditing.invoiceTax.replace(",", "")
        tempEditing.invoiceTotal = tempEditing.invoiceTotal.replace(",", "")
        this.props.updateSupplierInvoice({ ...tempEditing, priceCheck: true, statusOverride: statusOverride, newFiles: newFiles }, validate, () => {
        }, () => alert("Something went wrong"))
        //this.stopEditing()
        if(this.props.status.success){
            let tempFiles = Array.isArray(this.state.editing.files) ? [...this.state.editing.files] : []
            this.state.newFiles.forEach((file) => {
                tempFiles.push(file)
            })
            this.setState(prevState => ({
                editing: {
                    ...prevState.editing, // preserve all the existing properties of editing
                    files: tempFiles, // overwrite files with tempFiles
                },
            }));
            this.setState({ feedbackBarOpen:true,feedbackBarStatus: true, newFiles: [] })
        }
        else{
            this.setState({ feedbackBarOpen:false,feedbackBarStatus: false })
        }
    }

    fileUpload(e) {
        if (e.target.files.length === 0) return
        let file = e.target.files[0]
        let fileReader = new FileReader();

        let storage = localStorage.getItem("supplierInvoices")
        let fromDate
        let toDate
        if (storage) {
            let storageObj = JSON.parse(storage);
            fromDate = storageObj?.fromDate ?? this.state.fromDate
            toDate = storageObj?.toDate ?? this.state.toDate
        }
        fileReader.onload = (e) => {
            let contents = new Uint8Array(e.target.result)
            this.props.processSupplierInvoice(Array.from(contents), (invoice) => this.setEditing(invoice),
                fromDate, toDate)
        }
        fileReader.readAsArrayBuffer(file)
    }
    previewInvoice() {
        if (!this.state.editing?.invoiceNumber || !this.state.editing?.supplierName) return
        this.props.storeSupplierInvoicePDF([])
        this.props.getSupplierInvoicePDF(this.state.editing.supplierInvoiceId)
        this.setState({ viewing: true })
    }
    handleAttachmentPreview = (file, index) => {
        this.props.getSupplierInvoiceAttachment({
            path: file.path
        })
        this.setState({ viewing: true, viewingFile: file })
    }
    download = () => {
        let blob = new Blob([Uint8Array.from(this.props.invoicePDF)], { type: "application/pdf" });
        let link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = this.state.viewingFile.displayName
        link.click();
        // now clear it from memory
        window.URL.revokeObjectURL(link.href)
    }
    deleteAttachment = () => {
        this.props.deleteSupplierInvoiceAttachment({
            displayName: this.state.viewingFile.displayName,
            path: this.state.viewingFile.path,
            supplierInvoiceId:this.state.editing.supplierInvoiceId
        },this.updateInvoice)
        this.setState({ viewing: false, viewingFile: {}, deletingAttachment: false })
    }
    updateInvoice = (invoice) => {
        this.setState({
            editing: invoice
        })
    }
    setZoom(zoom) {
        let newScale = Math.min(Math.max(this.state.scale + zoom, 1), 2)
        this.setState({
            scale: newScale
        })
    }
    stopEditing() {
        this.setState({ editing: false, invoicePDF: new Uint8Array(0) })
    }
    download = () => {
        let blob = new Blob([Uint8Array.from(this.props.invoicePDF)], { type: "application/pdf" });
        let link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = this.state.editing.supplierName + "_" + this.state.editing.invoiceNumber + ".pdf"
        link.click();
        // now clear it from memory
        window.URL.revokeObjectURL(link.href)
    }
    zoomIn() {
        this.setState({ scale: Math.min(2, this.state.scale + 0.25) })
    }
    zoomOut() {
        this.setState({ scale: Math.max(1, this.state.scale - 0.25) })
    }
    changeStatus(status) {
        let supplierInvoiceIds = []
        Object.keys(this.state.selectedEntries).map(key => {
            if (this.state.selectedEntries[key]) supplierInvoiceIds.push(key)
        })
        this.updateStatus(supplierInvoiceIds, status, () => window.location.reload())
    }
    updateStatus(supplierInvoiceIds, status, callback) {
        this.props.updateSupplierInvoiceStatus({ supplierInvoiceIds: supplierInvoiceIds, status: status }, callback,
            this.state.fromDate, this.state.toDate)
        this.setState(prevState => ({
            invoicesList: prevState.invoicesList.map(
                invoice => supplierInvoiceIds.includes(invoice.supplierInvoiceId)
                    ? { ...invoice, status: status }
                    : invoice
            )
        }))
    }
    selectedAreApproved() {
        for (const key in this.state.selectedEntries) {
            let invoice = this.props.allInvoices.find(invoice => invoice.supplierInvoiceId === key)
            if (invoice?.status !== APPROVE_STATUS && this.state.selectedEntries[key]) return false
        }
        return true
    }
    overwriteInvoice() {
        this.props.overwriteSupplierInvoice({ supplierInvoiceId: this.state.editing.supplierInvoiceId }, () => window.location.reload())
    }
    handleStatusClose() {
        this.props.clearStatus()
    }

    handleSearchTextChange = (e) => {
        this.setState({
            searchText: e.target.value
        })
    }
    handleItemDelete = (index) => {
        let tempItems = [...this.state.editing.supplierInvoiceItems]
        tempItems.splice(index, 1)
        this.setState({ editing: { ...this.state.editing, supplierInvoiceItems: tempItems } })
    }
    addItem = () => {
        let newItem = { ...this.state.editing.supplierInvoiceItems[0], supplierInvoiceItemId: "" }
        INVOICE_ITEM_CELLS.map(cell => newItem[cell.id] = "")
        let tempItems = [...this.state.editing.supplierInvoiceItems, newItem]
        this.setState({ editing: { ...this.state.editing, supplierInvoiceItems: tempItems } })
    }

    getFormattedGeneralFileObject = (file) => {
        return { localURL: URL.createObjectURL(file), displayName: file.name, byteArray: getByteArray(file), type: file.type }

    }

    handleGeneralFileDelete = (file, fileIndex) => {
        let newFiles = this.state.newFiles
        //let deletedFiles = this.state.deletedFiles
        let deletedFile = (newFiles.splice(fileIndex, 1))[0]
        /*let prevFiles = this.state.files
        let deletedFiles = this.state.deletedFiles
        let prevNewFiles = this.state.newFiles*/

        //files[index] = []
        let i = 0
        for (let file1 in newFiles) {
            if (file1 === file) {
                newFiles.splice(i, 1)
            }
        }
        //newFiles[index] = []
        //if (deletedFiles[index] && deletedFiles[index].length > 0) {
        //   deletedFiles[index].push(deletedFile)
        // }
        // else {
        //      deletedFiles[index] = [deletedFile]
        // }
        // if ("localURL" in file) {
        //      this.freeFromMemory(file.localURL)
        //  }

        this.setState({
            newFiles: newFiles,
            //deletedFiles: deletedFiles,
            //filesChanged: true
        })
    }

    closeFeedbackBar = () => {
        this.setState({
            feedbackBarOpen: false,
            error: false
        })
    }

    handleGeneralFileAdd = (file) => {
        let newFiles = this.state.newFiles
        //let prevNewFiles = this.state.newFiles
        //let prevFiles = this.state.files
        let newFile = this.getFormattedGeneralFileObject(file)
        if (newFiles && newFiles.length > 0) {
            newFiles.push(newFile)
        }
        else {
            newFiles = [newFile]
        }
        this.setState({
            newFiles: newFiles,
            //filesChanged: true
        });
    }

    // for calculating total amount from same supplierName and return some data for cheque printing
    calculateTotalAmount = () => {

        const selectedInvoiceKeys = Object.keys(this.state.selectedEntries).filter(key => this.state.selectedEntries[key]);

        let selectedSupplierIds = [];
        let amount = 0;
        let payToName = '';
        let chequeNumber = '';
        let paymentDate = '';
        let memoo = '';
        let bankAccount = null;

        for (const key of selectedInvoiceKeys) {
            const invoice = this.props.allInvoices.find(invoice => invoice.supplierInvoiceId === key);

            if (invoice) {
                selectedSupplierIds.push(key)
                amount += parseFloat(invoice.invoiceTotal);
                payToName = invoice.supplierName;
                chequeNumber = invoice.referenceId;
                paymentDate = invoice.paymentDate;
                bankAccount = invoice.bankAccount;
                memoo += invoice.invoiceNumber + ", "
            }
        }

        return [amount, payToName, chequeNumber,paymentDate,memoo, selectedSupplierIds, bankAccount];
    }


    //if selected supplier has same supplierName then return false otherwise, true. this check is for printing cheque
    disableChequePrintingButton = () => {
        const selectedInvoiceKeys = Object.keys(this.state.selectedEntries).filter(key => this.state.selectedEntries[key]);
        const selectedInvoiceNames = {};
        const selectedInvoiceStatuses = {};

        for (const key of selectedInvoiceKeys) {
            const invoice = this.props.allInvoices.find(invoice => invoice.supplierInvoiceId === key);

            if (invoice) {
                selectedInvoiceNames[key] = invoice.supplierName;
                selectedInvoiceStatuses[key] = invoice.status;
            }
        }

        if (selectedInvoiceKeys.length === 1 && Object.values(selectedInvoiceStatuses)[0] === 'Approved for Payment') {
            return false;
        }

        const firstSupplierName = Object.values(selectedInvoiceNames)[0];
        const allMatch = Object.values(selectedInvoiceNames).every(name => name === firstSupplierName);
        const allApproved = Object.values(selectedInvoiceStatuses).every(status => status === 'Approved for Payment');
        return !allMatch || !allApproved;
    }




    setFetchedList = (fetched) => {
        this.setState({
            fetchedList: fetched
        })
    }

    render() {
        let headerButtons
        if (this.state.editing) {
            if (this.state.viewing && Object.keys(this.state.viewingFile).length === 0) {
                headerButtons = [{
                    icon: <KeyboardBackspaceIcon />,
                    handler: () => this.setState({ viewing: false }),
                    disabled: false,
                    title: "Back"
                },
                { icon: <GetApp />, handler: () => this.download(), disabled: this.props.invoicePDF.length === 0, title: "Download PDF" },
                { icon: <ZoomOutIcon />,
                    handler: () => this.setZoom(-0.25),
                    title: "Zoom Out", disabled: false
                },
                {
                    divider: <span style={{ padding: "5px", marginTop: "5px" }}>{this.state.scale * 100}%</span>
                },
                {
                    icon: <ZoomInIcon />,
                    handler: () => this.setZoom(0.25),
                    title: "Zoom In",
                    disabled: false
                }]
            }
            else if (this.state.viewing && !this.state.deletingAttachment) {
                headerButtons = [{
                    icon: <KeyboardBackspaceIcon />,
                    handler: () => this.setState({ viewing: false, viewingFile: {}, deletingAttachment: false }),
                    disabled: false,
                    title: "Back"
                },
                { icon: <ZoomOutIcon />,
                    handler: () => this.setZoom(-0.25),
                    title: "Zoom Out",
                    disabled: false
                },
                {
                    divider: <span style={{ padding: "5px", marginTop: "5px" }}>{this.state.scale * 100}%</span>
                },
                { icon: <ZoomInIcon />,
                    handler: () => this.setZoom(0.25),
                    title: "Zoom In",
                    disabled: false
                },
                {
                    icon: <GetApp />,
                    handler: () => this.download(),
                    title: "Download PDF" },
                {
                    icon: <DeleteIcon />,
                   handler: () => this.setState({ deletingAttachment: true }),
                   disabled: false,
                   title: "Delete"
                }]
            }
            else if (this.state.viewing && this.state.deletingAttachment) {
                headerButtons = [{
                    icon: <KeyboardBackspaceIcon />,
                    handler: () => this.setState({ viewing: false, viewingFile: {}, deletingAttachment: false }),
                    disabled: false,
                    title: "Back"
                },
                {
                    icon: <ZoomOutIcon />,
                    handler: () => this.setZoom(-0.25),
                    title: "Zoom Out",
                    disabled: false
                },
                {
                    divider: <span style={{ padding: "5px", marginTop: "5px" }}>{this.state.scale * 100}%</span>
                },
                {
                    icon: <ZoomInIcon />, handler: () => this.setZoom(0.25), title:
                        "Zoom In",
                    disabled: false
                },
                {
                    icon: <ClearIcon />,
                    handler: () => this.setState({ deletingAttachment: false }),
                    disabled: false,
                    title: "Cancel"
                    },
                {
                    icon: <DeleteIcon />,
                    handler: this.deleteAttachment,
                    disabled: false,
                    title: "Confirm Delete"
                }]
            }
            else {
                headerButtons = [{ icon: <KeyboardBackspaceIcon />, handler: () => this.stopEditing(), disabled: false, title: "Back" },
                {
                    icon: <DescriptionIcon />,
                    handler: this.previewInvoice,
                    disabled: false,
                    title: "View Invoice"
                },
                    {
                        icon: this.props.processing ? <HourglassEmptyTwoTone/> : <Restore />,
                        handler: this.reprocessInvoice,
                        disabled: false,
                        title: this.props.processing ? "Reprocessing..." : "Reprocess invoice"
                    },
                    {
                        icon: <></>,
                        handler: () => { },
                        disabled: false,
                        title: "",
                        divider: <span style={{ marginTop: '10px' }}>|</span>
                    },
                    {
                        icon: <SaveIcon />,
                        handler: () => this.save(false, false),
                        disabled: false,
                        title: "Save"
                    },
                    {
                        icon: <CheckCircleIcon />,
                        handler: () => this.save(false, true),
                        disabled: false,
                        title: "Save and Validate"
                    },
                {
                    icon: <></>,
                    handler: () => { },
                    disabled: false,
                    title: "",
                    divider: <span style={{ marginTop: '10px' }}>|</span>
                }]
                if (this.state.editing.status === DUPLICATE_STATUS) {
                    headerButtons.push({
                        icon: <CheckIcon />,
                        handler: () => this.overwriteInvoice(),
                        disabled: false,
                        title: "Overwrite"
                    })
                } else {
                    headerButtons.push(
                        {
                            icon: <RateReviewIcon />,
                            handler: () => this.updateStatus([this.state.editing.supplierInvoiceId], NEEDS_REVIEW_STATUS, (invoice) => this.setEditing(invoice)),
                            disabled: this.state.editing.status === NEEDS_REVIEW_STATUS,
                            title: "Mark for Review"
                        },
                        {
                            icon: <CheckIcon />,
                            handler: () => this.updateStatus([this.state.editing.supplierInvoiceId], APPROVE_STATUS, (invoice) => this.setEditing(invoice)),
                            disabled: this.state.editing.status === APPROVE_STATUS,
                            title: "Approve for Payment"
                        },
                        {
                            icon: <AttachMoneyIcon />,
                            handler: () => this.updateStatus([this.state.editing.supplierInvoiceId], PAID_STATUS, (invoice) => this.setEditing(invoice)),
                            disabled: this.state.editing.status !== APPROVE_STATUS,
                            title: "Mark as Paid"
                        },
                    )
                }
            }
        } else {
            if (this.state.selecting) {
                headerButtons = [{
                    icon: <RateReviewIcon />,
                    handler: () => this.changeStatus(NEEDS_REVIEW_STATUS),
                    disabled: false,
                    title: "Mark for Review"
                },
                {
                    icon: <CheckIcon />,
                    handler: () => this.changeStatus(APPROVE_STATUS),
                    disabled: false,
                    title: "Approve for Payment"
                },
                {
                    icon: <AttachMoneyIcon />,
                    handler: () => this.changeStatus(PAID_STATUS),
                    disabled: !this.selectedAreApproved(),
                    title: "Mark as Paid"
                }]
                if (this.props.user.routes.includes('accounting')) {
                    headerButtons.push(
                        {
                            icon: <CreditCardIcon/>,
                            disabled: this.props.bankAccounts.length < 1,
                            title: "Add Payment Method",
                            popper: <PaymentPopper
                                bankAccounts={this.props.bankAccounts}
                                selectedInvoiceIds={Object.keys(this.state.selectedEntries).filter(id => this.state.selectedEntries[id])}
                                invoiceType={INVOICE_TYPE_SUPPLIER}
                            />
                        }
                    )

                    headerButtons.push(   {
                        icon: <PrintIcon />,
                        handler: () => {
                            const [amount, payToName, chequeNumber,paymentDate,memoo,selectedSupplierIds, bankAccount] = this.calculateTotalAmount();
                            this.setState({
                                memoo: memoo,
                                payToName: payToName,
                                chequeNumber: chequeNumber,
                                bankAccount: bankAccount,
                                paymentDate: paymentDate,
                                amount: amount,
                                supplierIds: selectedSupplierIds
                            })
                            this.openIsCheque()
                        },
                        disabled:this.disableChequePrintingButton(),
                        title: "Print Cheque"
                    })
                }
            }
            else if (this.props.user.routes.includes('invoice-templates')) {
                headerButtons = [
                    {
                        icon:  this.props.processing ? <HourglassEmptyTwoTone/> : <AddCircleIcon />,
                        handler: () => this.processInvoice(),
                        disabled: false,
                        title: this.props.processing ? "Processing..." : "Process Invoice"
                    },
                    {
                        icon: <SettingsIcon />,
                        handler: () => this.openInvoiceTemplates(),
                        disabled: false,
                        title: "Supplier Invoice Templates"
                    },
                ]
            }
            else {
                headerButtons = [
                    {
                        icon: this.props.processing ? <HourglassEmptyTwoTone/> : <AddCircleIcon />,
                        handler: () => this.processInvoice(),
                        disabled: false,
                        title: this.props.processing ? "Processing..." : "Process Invoice"
                    }
                ]
            }
                if (this.state.selecting) {
                    headerButtons = [{
                        icon: <RateReviewIcon />,
                        handler: () => this.changeStatus(NEEDS_REVIEW_STATUS),
                        disabled: false,
                        title: "Mark for Review"
                    },
                        {
                            icon: <CheckIcon />,
                            handler: () => this.changeStatus(APPROVE_STATUS),
                            disabled: false,
                            title: "Approve for Payment"
                        },
                        {
                            icon: <AttachMoneyIcon />,
                            handler: () => this.changeStatus(PAID_STATUS),
                            disabled: !this.selectedAreApproved(),
                            title: "Mark as Paid"
                        }]
                    if (this.props.user.routes.includes('accounting')) {
                        headerButtons.push(
                            {
                                icon: <CreditCardIcon/>,
                                disabled: this.props.bankAccounts.length < 1,
                                title: "Add Payment Method",
                                popper: <PaymentPopper
                                    bankAccounts={this.props.bankAccounts}
                                    selectedInvoiceIds={Object.keys(this.state.selectedEntries).filter(id => this.state.selectedEntries[id])}
                                    invoiceType={INVOICE_TYPE_SUPPLIER}
                                />
                            }
                        )

                        headerButtons.push(   {
                            icon: <PrintIcon />,
                            handler: () => {
                                const [amount, payToName, chequeNumber,paymentDate,memoo,selectedSupplierIds, bankAccount] = this.calculateTotalAmount();
                                this.setState({
                                    memoo: memoo,
                                    payToName: payToName,
                                    chequeNumber: chequeNumber,
                                    paymentDate: paymentDate,
                                    amount: amount,
                                    supplierIds: selectedSupplierIds,
                                    bankAccount: bankAccount
                                })
                                this.openIsCheque()
                            },
                            disabled:this.disableChequePrintingButton(),
                            title: "Print Cheque"
                        })

                    }


                } else if (this.props.user.routes.includes('invoice-templates')) {
                    headerButtons = [
                        {
                            icon: <AddCircleIcon/>,
                            handler: () => this.processInvoice(),
                            disabled: false,
                            title: "Process Invoice"
                        },
                        {
                            icon: <SettingsIcon/>,
                            handler: () => this.openInvoiceTemplates(),
                            disabled: false,
                            title: "Supplier Invoice Templates"
                        },
                    ]
                } else {
                    headerButtons = [
                        {
                            icon: <AddCircleIcon />,
                            handler: () => this.processInvoice(),
                            disabled: false,
                            title: "Process Invoice"
                        }
                    ]
                }
        }
        return (<MuiPickersUtilsProvider utils={DateFnsUtils}>

                <ErrorFeedbackBar/>


                <Paper className="paper">
                    <FeedbackBar
                        open={this.state.feedbackBarOpen}
                        severity={this.state.error ? ERROR : SUCCESS}
                        message={this.state.feedbackBarText}
                        handleClose={this.closeFeedbackBar}
                    />
                    <input type="file" ref={this.hiddenFileInput} accept="application/pdf" hidden
                           onChange={this.fileUpload}/>

                    {!this.state.openInvoiceTemplates && !this.state.isCheque ?
                        <CustomToolbar
                            title={"Supplier Invoices"}
                            createTitle={"Process Supplier Invoice"}
                            backTitle={""}
                            headerButtons={headerButtons}
                        />
                        :
                        null
                    }

                    {this.state.openInvoiceTemplates ? (
                        <SupplierInvoiceTemplates
                            closeInvoiceTemplates={this.closeInvoiceTemplates}
                        />
                    ) : (
                        this.state.isCheque ? (
                            <SupplierChequeForm
                                companyAddress={this.state.companyAddress}
                                saveSupplierInvoicePaymentDetails={this.props.saveSupplierInvoicePaymentDetails}
                                saveCheque={this.props.saveCheque}
                                previewBankFile={this.props.previewBankFile}
                                updateSupplierInvoiceStatus={this.props.updateSupplierInvoiceStatus}
                                memoo={this.state.memoo}
                                payToName={this.state.payToName}
                                chequeNumber={this.state.chequeNumber}
                                bankAccount={this.state.bankAccount}
                                paymentDate={this.state.paymentDate}
                                amount={this.state.amount}
                                supplierIds={this.state.supplierIds}
                                closeIsCheque={this.closeIsCheque}
                                closeInvoiceTemplates={this.closeInvoiceTemplates}
                                transactionsCategories={this.state.transactionCategories}
                                clearStatus={this.props.clearStatus}
                                validateChequeNumber={this.props.validateChequeNumber}
                            />
                        ) : (
                            !this.state.editing ? (
                                <InvoicesTable
                                    data={this.state.invoicesList}
                                    setEditing={this.setEditing}
                                    deleteInvoice={this.props.deleteSupplierInvoice}
                                    checkedEntries={this.state.selectedEntries}
                                    setSelected={(newEntries, selecting) => this.setState({
                                        selectedEntries: newEntries,
                                        selecting: selecting
                                    })}
                                    handleSearchChange={this.handleSearchTextChange}
                                    searchText={this.state.searchText}
                                    page={this.state.page}
                                    setPage={this.setPage}
                                    rowsPerPage={this.state.rowsPerPage}
                                    setRowsPerPage={this.setRowsPerPage}
                                    tableSetting={this.props.tableSetting}
                                    saveTableSetting={this.props.saveTableSetting}
                                    suppliers={this.props.suppliers.map(s => s.name)}
                                    fetchSupplierInvoices={this.props.getAllSupplierInvoices}
                                    user={this.props.user}
                                    handleDateChange={this.handleDateChange}
                                    setFetchedList={this.setFetchedList}
                                />
                            ) : (
                                <EditInvoices
                                    editing={this.state.editing}
                                    viewing={this.state.viewing}
                                    pdfData={this.state.invoicePDF}
                                    scale={this.state.scale}
                                    addItem={this.addItem}
                                    itemError={this.state.itemError}
                                    handleChange={(name, value) => this.changeEditing(name, value)}
                                    handlePONumberChange={(value) => this.getPurchaseOrder(value)}
                                    handleItemChange={(name, value, index) => this.changeEditingItem(name, value, index)}
                                    handleItemDelete={(index) => this.handleItemDelete(index)}
                                    handleBlurField={(name, value, index) => this.blurEditing(name, value, index)}
                                    formatDollar={this.formatDollar}
                                    newFiles={this.state.newFiles}
                                    handleGeneralFileAdd={this.handleGeneralFileAdd}
                                    handleGeneralFileDelete={this.handleGeneralFileDelete}
                                    handleAttachmentPreview={this.handleAttachmentPreview}
                                    bankAccounts={this.props.bankAccounts}
                                    user={this.props.user}
                                    defaultTaxPercentage={this.props.purchaseOrder?.taxPercentage}
                                />
                            )
                        )
                    )}
                </Paper>
            </MuiPickersUtilsProvider>)
    }
}

const actionCreators = {
    getCompany,
    getAllSupplierInvoices,
    storeAllSupplierInvoices,
    processSupplierInvoice,
    reprocessSupplierInvoice,
    getSupplierInvoicePDF,
    storeSupplierInvoicePDF,
    getInvoicePurchaseOrder,
    updateSupplierInvoice,
    deleteSupplierInvoice,
    updateSupplierInvoiceStatus,
    overwriteSupplierInvoice,
    clearStatus,
    saveTableSetting,
    getTableSetting,
    listSuppliers,
    getSupplierInvoiceAttachment,
    deleteSupplierInvoiceAttachment,
    listAllBankAccounts,
    updateSupplierInvoiceChanged,
    storeSupplierInvoiceOperationResponse,
    saveCheque,
    previewBankFile,
    listTransactionCategories,
    saveSupplierInvoicePaymentDetails,
    validateChequeNumber
}

const mapStateToProps = (state) => ({
    automationSettings: automationInfoSelector(state),
    editingInvoiceChanged: supplierInvoiceChangedSelector(state),
    allInvoices: allSupplierInvoicesSelector(state),
    invoicePDF: supplierInvoicePDFSelector(state),
    purchaseOrder: supplierInvoicePOSelector(state),
    status: statusSelector(state),
    tableSetting: tableSettingSelector(state),
    user: userInfoSelector(state),
    suppliers: suppliersSelector(state),
    bankAccounts: bankAccountsSelector(state),
    operationResponse: supplierInvoiceOperationResponseSelector(state),
    processing: processingSelector(state),
    transactionCategories: transactionCategoriesSelector(state),
});

SupplierInvoices.propTypes = {
    allInvoices: PropTypes.array,
    invoicePDF: PropTypes.object,
    suppliers: PropTypes.array
}

SupplierInvoices.defaultProps = {
    allInvoices: [],
    status: {},
    invoicePDF: new Uint8Array(0),
    suppliers: []
}

export default withShipment({
    mapStateToProps,
    actionCreators
}, SupplierInvoices);