import React from 'react';

// styling
import styles from "./CreateMarketplaceDialog.module.css";

// material components
import {Button, IconButton, TextField, Tooltip} from "@material-ui/core";
import PropTypes from "prop-types";
import {AMAZON, CUSTOM_MARKETPLACE, WALMART, EBAY,} from "./constants";

import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import DateFnsUtils from "@date-io/date-fns";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";

function MarketplaceForm(props) {

    const getMarketplaceFields = (marketplaceName) => {
        switch (marketplaceName) {
            case AMAZON:
                return(
                    <>
                        <div className={styles.rowSpec}>
                            <TextField
                                required
                                className={styles.formTwoInput}
                                variant="outlined"
                                value={props.parentState.sellerId}
                                name="sellerId"
                                onChange={props.handleChange}
                                label="Seller ID"
                                InputLabelProps={{shrink: true}}
                            />
                            <TextField
                            required
                            className={styles.formTwoInput}
                            variant="outlined"
                            value={props.parentState.externalMarketplaceId}
                            name="externalMarketplaceId"
                            onChange={props.handleChange}
                            label="External Marketplace ID"
                            InputLabelProps={{shrink: true}}
                            />
                            <Tooltip
                                className='infoTooltipIcon'
                                enterTouchDelay={0}
                                leaveTouchDelay={300000}
                                enterDelay={400}
                                title={
                                    <React.Fragment>
                                        {'Seller ID:'}
                                        <br />
                                        {'Found in seller central\'s account settings or the account info section.'}
                                        <br />
                                        <br />
                                        {'Amazon Marketplace ID:'}
                                        <br />
                                        {'In Seller Central, go to the settings or look for information related to your active marketplaces. Each marketplace where you\'re registered to sell will have an associated ID.'}
                                        <br />
                                        <br />
                                        {'Refresh Token:'}
                                        <br />
                                        {'Generated when a user authorizes your application to access their Amazon data through the LWA process, not visible in the Amazon Seller Central.'}
                                        <br />
                                        <br />
                                        {'LWA Client ID and LWA Client Secret:'}
                                        <br />
                                        {'In the Amazon Developer Console, select your application and look for the security profile settings.'}
                                        <br />
                                        <br />
                                        {'End Point:'}
                                        <br />
                                        {'The exact endpoint would be in the API documentation provided by Amazon.'}
                                        <br />
                                        <br />
                                        {'Expiry Date:'}
                                        <br />
                                        {'Sellers will receive an email with a reminder to re-authorize their SP-API app 30 days before the expiration.'}

                                    </React.Fragment>
                                }
                            >
                                <IconButton aria-label='info'>
                                    <InfoOutlinedIcon variant='outlined' fontSize='small' />
                                </IconButton>
                            </Tooltip>
                        </div>

                        <div className={styles.rowSpec}>
                            <TextField
                                required
                                className={styles.formTwoColInput}
                                variant="outlined"
                                value={props.parentState.applicationName}
                                name="applicationName"
                                onChange={props.handleChange}
                                label="Application Name"
                                InputLabelProps={{shrink: true}}
                            />
                        </div>

                        <div className={styles.rowSpec}>
                            <TextField
                                required
                                className={styles.formTwoColInput}
                                variant="outlined"
                                value={props.parentState.refreshToken}
                                name="refreshToken"
                                onChange={props.handleChange}
                                label="Refresh Token"
                                InputLabelProps={{shrink: true}}
                            />
                        </div>

                        <div className={styles.rowSpec}>
                            <TextField
                                required
                                className={styles.formTwoColInput}
                                variant="outlined"
                                value={props.parentState.lwaClientId}
                                name="lwaClientId"
                                onChange={props.handleChange}
                                label="LWA Client ID"
                                InputLabelProps={{shrink: true}}
                            />
                        </div>

                        <div className={styles.rowSpec}>
                            <TextField
                                required
                                className={styles.formTwoColInput}
                                variant="outlined"
                                value={props.parentState.lwaClientSecret}
                                name="lwaClientSecret"
                                onChange={props.handleChange}
                                label="LWA Client Secret"
                                InputLabelProps={{shrink: true}}
                            />
                        </div>

                        <div className={styles.rowSpec}>
                            <TextField
                                required
                                className={styles.formTwoColInput}
                                variant="outlined"
                                value={props.parentState.endPoint}
                                name="endPoint"
                                onChange={props.handleChange}
                                label="End Point"
                                InputLabelProps={{shrink: true}}
                            />
                        </div>

                        <div className={styles.rowSpec}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    className={styles.formTwoColInput}
                                    name="expiryDate"
                                    label="Expiry Date"
                                    value={props.parentState.expiryDate ?? ""}
                                    onChange={(e, value) => props.handleChange({target: {name: "expiryDate", value}})}
                                    format="MM/dd/yyyy HH:mm:ss"
                                    variant="inline"
                                    inputVariant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                    InputAdornmentProps={{ position: "start" }}
                                    required
                                    autoOk
                                />
                            </MuiPickersUtilsProvider>
                        </div>
                    </>
                );
            case EBAY:
                return(
                    <>
                        <div className={styles.rowSpec}>
                            <TextField
                                required
                                className={styles.formTwoInput}
                                variant="outlined"
                                value={props.parentState.url}
                                name="url"
                                onChange={props.handleChange}
                                label="URL"
                                InputLabelProps={{shrink: true}}
                            />
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={()=>{props.openEbay()}}
                                className={styles.marketplace_stepper_btn}
                            >
                                {"Sign in to eBay"}
                            </Button>
                            <Tooltip
                                className='infoTooltipIcon'
                                enterTouchDelay={0}
                                leaveTouchDelay={30000}
                                enterDelay={400}
                                // leaveDelay={1000000} // for testing purposes
                                title={
                                    <React.Fragment>
                                        Login to eBay and accept their terms and conditions to authorize fetching orders
                                    </React.Fragment>
                                }
                            >
                                <IconButton aria-label='info'>
                                    <InfoOutlinedIcon variant='outlined' fontSize='small' />
                                </IconButton>
                            </Tooltip>
                        </div>
                    </>
                );
            case WALMART:
                return(
                    <>
                        <div className={styles.rowSpec}>
                            <TextField
                                required
                                className={styles.formTwoInput}
                                variant="outlined"
                                value={props.parentState.sellerId}
                                name="sellerId"
                                onChange={props.handleChange}
                                label= {props.parentState.marketplaceCountry == "Canada" ? "Consumer Id": "Client Id"}
                                InputLabelProps={{shrink: true}}
                            />
                            <TextField
                                required
                                className={styles.formTwoInput}
                                variant="outlined"
                                value={props.parentState.key}
                                name="key"
                                onChange={props.handleChange}
                                label={props.parentState.marketplaceCountry == "Canada" ? "Private Key": "Client Secret"}
                                InputLabelProps={{shrink: true}}
                            />
                            {props.parentState.marketplaceCountry == "Canada"?
                                <Tooltip
                                    className='infoTooltipIcon'
                                    enterTouchDelay={0}
                                    leaveTouchDelay={300000}
                                    enterDelay={400}
                                    title={
                                        <React.Fragment>
                                            {'Log in to the Walmart Developer Portal to get your Consumer Id, Private Key and Consumer Channel Type.'}
                                        </React.Fragment>
                                    }
                                >
                                    <IconButton aria-label='info'>
                                        <InfoOutlinedIcon variant='outlined' fontSize='small' />
                                    </IconButton>
                                </Tooltip>

                                : <Tooltip
                                    className='infoTooltipIcon'
                                    enterTouchDelay={0}
                                    leaveTouchDelay={300000}
                                    enterDelay={400}
                                    title={
                                        <React.Fragment>
                                            {'Log in to the Walmart Developer Portal to get your Consumer Id and Private Key.'}
                                        </React.Fragment>
                                    }
                                >
                                    <IconButton aria-label='info'>
                                        <InfoOutlinedIcon variant='outlined' fontSize='small' />
                                    </IconButton>
                                </Tooltip>
                            }
                        </div>
                        {props.parentState.marketplaceCountry == "Canada"?
                            <div className={styles.rowSpec}>
                                <TextField
                                    required
                                    className={styles.formTwoColInput}
                                    variant="outlined"
                                    value={props.parentState.consumerChannelType}
                                    name="consumerChannelType"
                                    onChange={props.handleChange}
                                    label="Consumer Channel Type"
                                    InputLabelProps={{shrink: true}}
                                />
                            </div>
                            : null}

                    </>
                );
            case CUSTOM_MARKETPLACE:
                return(
                    <>
                        <div className={styles.rowSpec}>
                            <TextField
                                required
                                className={styles.formTwoInput}
                                variant="outlined"
                                value={props.parentState.sellerId}
                                name="sellerId"
                                onChange={props.handleChange}
                                label="App ID"
                                InputLabelProps={{shrink: true}}
                            />
                            <TextField
                                required
                                className={styles.formTwoColInput}
                                variant="outlined"
                                value={props.parentState.token}
                                name="token"
                                onChange={props.handleChange}
                                label="Token"
                                InputLabelProps={{shrink: true}}
                            />
                        </div>
                        <div className={styles.rowSpec}>
                            <TextField
                                required
                                className={styles.formThreeInput}
                                variant="outlined"
                                value={props.parentState.routeValidate}
                                name="routeValidate"
                                onChange={props.handleChange}
                                label="Validation Route"
                                InputLabelProps={{shrink: true}}
                            />
                            <TextField
                                required
                                className={styles.formThreeInput}
                                variant="outlined"
                                value={props.parentState.routeFetch}
                                name="routeFetch"
                                onChange={props.handleChange}
                                label="Fetch Orders Route"
                                InputLabelProps={{shrink: true}}
                            />
                            <TextField
                                required
                                className={styles.formThreeInput}
                                variant="outlined"
                                value={props.parentState.routeUpdate}
                                name="routeUpdate"
                                onChange={props.handleChange}
                                label="Update Orders Route"
                                InputLabelProps={{shrink: true}}
                            />
                        </div>
                    </>
                )
            default:
                return(
                    <></>
                )
        }
    }

    return(
        <div className={styles.rowMarketplace}>
            <div>
                {getMarketplaceFields(props.parentState.marketplaceCompany)}
            </div>
        </div>
    )
}

MarketplaceForm.propTypes = {
    parentState: PropTypes.object,
    handleChange: PropTypes.func,
    errors: PropTypes.array,
    isEdit: PropTypes.bool,
    openEbay: PropTypes.func,
}

MarketplaceForm.defaultProps = {
    parentState: {},
    handleChange: ()=>{},
    errors: [],
    isEdit: ()=>{},
    openEbay: ()=>{}
}

export default MarketplaceForm;