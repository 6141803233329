import React, { useEffect, useState } from 'react';

// material components
import {
    Tabs,
    Tab,
    AppBar,
    Typography,
    Paper,
    IconButton,
    Tooltip,
} from "@material-ui/core";

// material icons
import {
    AddCircleOutline as AddIcon,
    FileCopy as CopyIcon,
    Delete as DeleteIcon,
} from '@material-ui/icons';

// styling
import '../ShipmentForm.css';

// constants
import {
    PACKAGE_DETAILS,
    DELETE_PACKAGE,
    ADD_PACKAGE,
    COPY_PACKAGE, REPRINT_LABEL
} from "./constants";
import ItemDetails from "./ItemDetails";
import PropTypes from "prop-types";
import { CARRIER_SERVICE_OPTIONS } from "../../automationrules/ConditionFilters/constants";
import {CANCELLED, SHIPPED} from "../../stages/constants";
import PrintIcon from "@material-ui/icons/Print";

// Redux
import withShipment from "../../../withShipment";
import {getSuggestedBoxTypesSelector} from "../../../redux/selectors/orders";
import {getSuggestedBoxTypes, ignoreBoxSuggestion} from "../../../redux/actions/orders";
import ShipmentFormInternationalDialogue from "../ShipmentFormInternationalDialogue";

function PackageDetails(props) {
    const [value, setValue] = useState(0);
    const [state, setState] = useState({
        packages: [{
            packaging: "",
            weight: "",
            length: "",
            width: "",
            height: "",
            declaredValue: "",
            currency: "",
            reference1: "",
            reference2: "",
            reference3: ""

        }],
    })

    useEffect(() => {

    }, [props.packages])
    useEffect(() => {
        props.getSuggestedBoxTypes(props.packages)
    }, [props.packages])

    const handleTabChange = (e, newValue) => {
        setValue(newValue);
    }


    const handleAdd = () => {
        const data = {
            id: props.shipmentId
        };
        if (props.isCreateShip)
            props.handleCreateShipPackageAdd();
        else
            props.addPackage(data);
    }

    const handleReprint = () => {
        const data = {
            id: props.shipmentId,
            index: value
        };
        const downloadPdfs = (pdfArrays) => {
            pdfArrays.forEach((array) => {
                    let blob = new Blob([new Uint8Array(array)], {type: "application/pdf"});
                    let link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = 'PDF label'
                    link.click();
                    // now clear it from memory
                    window.URL.revokeObjectURL(link.href)
                }
            )
        }
        props.reprintPackage(data, downloadPdfs)
    }

    const handleCopy = () => {
        const data = {
            id: props.shipmentId,
            index: value
        };

        if (props.isCreateShip)
            props.handleCreateShipPackageCopy(data.index)
        else
            props.copyPackage(data);
    }



    const handleDelete = () => {
        const data = {
            id: props.shipmentId,
            index: value
        };
        if (props.isCreateShip)
            props.handleCreateShipDeletePackage(value)
        else
            props.deletePackage(data);

        setValue(value - 1 === -1 ? 0 : value - 1)
    }

    const ignoreSuggestion = (boxId, products) => {
        props.ignoreBoxSuggestion({boxId: boxId, products: products})
    }

    return (
        <div className="input-form-package">
            <Typography variant="h6" component="div" className="header-package" gutterBottom>
                {PACKAGE_DETAILS}
                <Tooltip title={DELETE_PACKAGE} onClick={handleDelete}>
                    <IconButton disabled={props.isCreateShip ? false : (!props.packages || props.packages.length === 1 || props.loading || props.shipmentStatus === SHIPPED || props.shipmentStatus === CANCELLED)} style={{ float: 'right' }}>
                        <DeleteIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip title={COPY_PACKAGE} onClick={handleCopy}>
                    <IconButton disabled={props.isCreateShip ? false : props.loading || props.shipmentStatus === SHIPPED || props.shipmentStatus === CANCELLED} style={{ float: 'right' }}>
                        <CopyIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip title={ADD_PACKAGE} onClick={handleAdd}>
                    <IconButton disabled={props.isCreateShip ? false : props.loading || props.shipmentStatus === SHIPPED || props.shipmentStatus === CANCELLED} style={{ float: 'right' }}>
                        <AddIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip title={REPRINT_LABEL} onClick={handleReprint}>
                    <IconButton disabled={props.shipmentStatus !== SHIPPED && props.shipmentStatus !== CANCELLED} style={{ float: 'right' }}>
                        <PrintIcon />
                    </IconButton>
                </Tooltip>
                <hr />
            </Typography>
            <Tabs
                value={value}
                onChange={handleTabChange}
                indicatorColor="none"
                textColor="none"
                width={"25%"}
                variant="scrollable"
                style={{ maxWidth: '710px', marginLeft: '10px' }}
            >
                {
                    props.packages.map((tab, index) => (
                        <Tab
                            label={index + 1}
                            className={value !== index ? "package-tab-not-selected" : "package-tab"}
                        />
                    ))}
                {/* :
                    state.packages.map((tab, index) => (
                    <Tab
                        label={index+1}
                        className={value !== index ? "package-tab-not-selected" : "package-tab"}
                    />
                    ))*/}




            </Tabs>
            {!props.isCreateShip ?

                props.packages.map((order, index) => {
                    return (
                        <ItemDetails
                            shipmentId={props.shipmentId}
                            value={value}
                            index={index}
                            order={order}
                            packages={props.packages}
                            getSuggestedBoxTypes={props.getSuggestedBoxTypes}
                            isCreateShip={false}
                            ignoreSuggestion={ignoreSuggestion}
                            filterCarrierService={props.filterCarrierService}
                            carrierServices={props.carrierServices}
                            handleChange={props.handleChange}
                            handleFocus={props.handleFocus}
                            handleSave={props.handleSave}
                            editOrder={props.editOrder}
                            shipmentStatus={props.shipmentStatus}
                            boxes={props.boxes}
                            suggestedBoxTypes={props.suggestedBoxTypes[index]}
                            handleAutocompleteChange={props.handleAutocompleteChange}
                            handleBoxChange={props.handleBoxChange}
                            toggleCommercialInvoiceView={props.toggleCommercialInvoiceView}
                            getSampleCommercialInvoicePdf={props.getSampleCommercialInvoicePdf}
                            commericialInvoice={props.commercialInvoice}
                            getShipmentCommercialInvoice={props.getShipmentCommercialInvoice}
                            handleAutocompleteSave={props.handleAutocompleteSave}
                            orderInfo={props.orderInfo}
                            handleCheck={props.handleCheck}
                            commodityEditing={props.commodityEditing}
                            romoveCommodity={props.romoveCommodity}
                            addCommodity={props.addCommodity}
                            isShipmentReady={props.isShipmentReady}
                            scanInfo={props.scanInfos && index >= 0 && index < props.scanInfos.length ? props.scanInfos[index] : null}
                        />
                    )
                })
                :
                props.packages.map((order, index) => {
                    return (
                        <ItemDetails
                            index={index}
                            value={value}
                            isCreateShip={true}
                            handleChange={props.handleChange}
                            order={order}
                            handleAutocompleteChange={props.handleAutocompleteChange}
                            boxes={props.boxes}
                            handleBoxChange={props.handleBoxChange}

                            getSampleCommercialInvoicePdf={props.getSampleCommercialInvoicePdf}
                            commericialInvoice={props.commercialInvoice}
                            getShipmentCommercialInvoice={props.getShipmentCommercialInvoice}
                            shipmentId={props?.shipmentId}
                            handleAutocompleteSave={props.handleAutocompleteSave}
                            orderInfo={props.orderInfo}
                            handleCheck={props.handleCheck}
                            commodityEditing={props.commodityEditing}
                            romoveCommodity={props.romoveCommodity}
                            handleFocus={props.handleFocus}
                            handleSave={props.handleSave}
                            addCommodity={props.addCommodity}
                            shipmentStatus={props.shipmentStatus}
                            isShipmentReady={props.isShipmentReady}
                        />
                    )

                })



            }
        </div>
    )
}

PackageDetails.propTypes = {
    shipmentId: PropTypes.string,
    filterCarrierService: PropTypes.func,
    carrierServices: PropTypes.array,
    required: PropTypes.bool,
    errorChecking: PropTypes.bool,
    packages: PropTypes.array,
    totalItems: PropTypes.number,
    handleChange: PropTypes.func,
    handleFocus: PropTypes.func,
    handleSave: PropTypes.func,
    addPackage: PropTypes.func,
    copyPackage: PropTypes.func,
    deletePackage: PropTypes.func,
    editOrder: PropTypes.func,
    loading: PropTypes.bool,
    isCreateShip: PropTypes.bool,
    handleCreateShipPackageAdd: PropTypes.func,
    handleCreateShipDeletePackage: PropTypes.func,
    handleCreateShipPackageCopy: PropTypes.func,
    shipmentStatus: PropTypes.string,
    reprintPackage: PropTypes.func,
    boxes: PropTypes.array,
    suggestedBoxTypes: PropTypes.array,
}

PackageDetails.defaultProps = {
    shipmentId: "",
    filterCarrierService: () => { },
    carrierServices: [],
    required: false,
    errorChecking: false,
    packages: [],
    totalItems: 0,
    handleChange: () => { },
    handleFocus: () => { },
    handleSave: () => { },
    addPackage: () => { },
    copyPackage: () => { },
    deletePackage: () => { },
    editOrder: () => { },
    loading: false,
    isCreateShip: false,
    shipmentStatus: '',
    reprintPackage: ()=>{},
    handleCreateShipPackageAdd: () => { },
    handleCreateShipDeletePackage: () => { },
    handleCreateShipPackageCopy: () => { },
    boxes: [],
    suggestedBoxTypes: [],
}
const mapStateToProps = (state) => ({
    suggestedBoxTypes: getSuggestedBoxTypesSelector(state)
})

const actionCreators = {
    getSuggestedBoxTypes,
    ignoreBoxSuggestion
}

export default withShipment({
    mapStateToProps,
    actionCreators
}, PackageDetails);