import React from "react";
import TopToolbar from "../../global/subcomponents/topToolbar/TopToolbar";

import {Layout} from "../../global/Table/TablePageHelpers/LayoutObject";
import {Box, FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import TablePage from "../../global/Table/TablePage";
import { LEDGER_TABLE_CELLS, LEDGERS, LEDGERS_PAGES} from "./constants";
import {IconButton, Tab} from "@material-ui/core";
import './ledger.css';
import {TabContext, TabList, TabPanel} from "@material-ui/lab";
import LedgerTableRow from "./LedgerTableRow";
import EditIcon from "@material-ui/icons/Edit";
import Tooltip from "@material-ui/core/Tooltip";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import CloseIcon from "@material-ui/icons/Close";
import SettingsIcon from "@material-ui/icons/Settings";

class LedgerTable extends React.Component {
    constructor(props) {
        super(props)
        const savedTab = localStorage.getItem('selectedTab') || "1";
        this.state = {
            deleteId: "",
            isDeleted: false,
            listingIdBeingEdited: '',
            tableEvents: [],
            isEdit: false,
            ledgers: [],
            hideFilterBox : false,
            value: savedTab,
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.ledgers !== this.props.ledgers) {
            this.setState({ledgers:this.props.ledgers})
        }
    }

    componentDidMount() {
        const savedTab = localStorage.getItem('selectedTab') || "1";
        this.setState({ value: savedTab });
    }


    handleTabChange = (event, newValue) => {
        this.setState({ value: newValue });
        localStorage.setItem('selectedTab', newValue);
    };

    setLedgers = (ledgers) => {
        this.setState({ledgers: ledgers})
    }


    getSearchStrings = (row) => {
        let searchStrings = []
        if (row.name)
            searchStrings.push(row.name)
        if (row.balance)
            searchStrings.push(row.balance)
        return searchStrings
    }


    getContextualMenuItems = () => {
        const menuItems = [];

        // Check if currency is not "Consolidated CAD" to display Edit and Cancel options
        if (this.props.selectedCurrency !== "Consolidated CAD") {
            menuItems.push(
                this.state.isEdit && {
                    title: 'Cancel Editing',
                    icon: <CloseIcon />,
                    onClick: () => {
                        this.setHideFilterBox(false);
                        this.setState({ listingIdBeingEdited: "" });
                        this.setState({ isEdit: false });
                    },
                },
                !this.state.isEdit && {
                    title: 'Edit Ledger',
                    icon: <EditIcon />,
                    onClick: () => {
                        this.setState({ isEdit: true });
                    },
                }
            );
        }

        // Always show the settings menu next to edit/cancel
        menuItems.push({
            title: 'Settings',
            icon: <SettingsIcon />,
            onClick: () => this.props.setCurrentPage(LEDGERS_PAGES.SETTINGS, {}),
        });

        return menuItems.filter(Boolean);  // Ensure no `undefined` or `null` values
    };



    getTableHeaders = () => {
        let headers = []
        headers.push(Layout.columnHead(null,null,null))
        LEDGER_TABLE_CELLS.forEach((headCell, index) => {
            const alignment = index === LEDGER_TABLE_CELLS.length - 1 ? "left" : "left"
            headers.push(Layout.columnHead(headCell.label, headCell.id, alignment))
        })
        return headers
    }


    getTableRow = (row, getRowState, setRowState,ledgerType) => {
        return<LedgerTableRow
            row={row}
            categories={this.props.categories}
            isEdit={this.state.isEdit}
            allLedgers={this.state.ledgers}
            setLedgers={this.setLedgers}
            getRowState={getRowState}
            setRowState={setRowState}
            setCurrentPage={this.props.setCurrentPage}
            listingIdBeingEdited={this.state.listingIdBeingEdited}
            setListingIdBeingEdited={(newId) => this.setState({listingIdBeingEdited: newId})}
            deleteLedgerByYear={this.props.deleteLedgerByYear}
            updateLedger={this.props.updateLedger}
            deleteLedger={this.props.deleteLedger}
            year={this.props.selectedYear}
            currency={this.props.selectedCurrency}
            ledgerType={ledgerType}
            setHideFilterBox={this.setHideFilterBox}
            settings={this.props.settings}
        />
    }

    setHideFilterBox = (isHide) =>{
        this.setState({hideFilterBox : isHide})
    }

    renderTablePanel = (ledgerType) => {
        return (
            <div style={{ marginTop: "-80px", marginLeft: "-24px", marginRight: "-24px" }}>
                <TablePage
                  type={"ledgerTable"}
                    tableName="PricingDashboardTable"
                    eventsVariable={this.state.tableEvents}
                    setEventsVariable={(newState) => this.setState({ tableEvents: newState })}
                    tableColumnHeads={this.getTableHeaders()}
                    tableRows={this.state.ledgers.filter((ledger) => ledger.type === ledgerType)}
                    tableRow={(row, index, getRowState, setRowState) =>
                        this.getTableRow(row, getRowState, setRowState,ledgerType)
                    }
                    getRowId={(row) => row.ledgerId}
                    paginationText="Ledgers per page"
                />
                {this.state.isEdit && (
                    <Tooltip title="Add Ledger" style={{marginLeft:'16px',marginTop:'8px'}}>
                        <IconButton onClick={() => {
                            this.setState({listingIdBeingEdited: ""},()=>{
                                const newLedgers = [
                                    ...this.state.ledgers,
                                    {
                                        ledgerId: '',
                                        name: '',
                                        sumRule: '',
                                        carryForwardBalance: '',
                                        source: '',
                                        balance: 0,
                                        type: ledgerType
                                    }
                                ];
                                this.setState({ ledgers: newLedgers })
                            })
                        }
                        }
                               >
                            <AddCircleIcon
                                fontSize="small"
                                color='secondary'
                            />
                        </IconButton>
                    </Tooltip>
                )}
            </div>
        );
    };

    render() {
        return (
            <React.Fragment>
                <TopToolbar
                    pageName={LEDGERS}
                    menuItems={this.getContextualMenuItems()}
                />

                    <Box className="box-ledger"   style={{ visibility: this.state.hideFilterBox ? 'hidden' : 'visible' }}>
                        <FormControl variant="outlined" className="form-control-ledger">
                            <InputLabel id="year-label">Year</InputLabel>
                            <Select
                                className="select-year-ledger"
                                labelId="year-label"
                                value={this.props.selectedYear}
                                onChange={this.props.handleYearChange}
                                label="Year"
                            >
                                {this.props.years.map((year) => (
                                    <MenuItem key={year} value={year}>
                                        {year}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <FormControl variant="outlined" className="form-control">
                            <InputLabel id="currency-label">Currency</InputLabel>
                            <Select
                                className="select-currency-ledger"
                                labelId="currency-label"
                                value={this.props.selectedCurrency}
                                onChange={this.props.handleChange}
                                label="Currency"
                            >
                                {this.props.currency.map((currency) => (
                                    <MenuItem key={currency} value={currency}>
                                        {currency}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>



                {/* Tab Context */}
                <Box sx={{ width: '100%', typography: 'body1' }}>
                    <TabContext value={this.state.value}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList onChange={this.handleTabChange} aria-label="ledger tabs">
                                <Tab label="Assets" value="1" />
                                <Tab label="Liabilities" value="2" />
                                <Tab label="Income" value="3" />
                                <Tab label="Expenses " value="4" />
                                <Tab label="Equity" value="5" />
                            </TabList>
                        </Box>
                        <TabPanel value="1">{this.renderTablePanel("Assets")}</TabPanel>
                        <TabPanel value="2">{this.renderTablePanel("Liabilities")}</TabPanel>
                        <TabPanel value="3">{this.renderTablePanel("Income")}</TabPanel>
                        <TabPanel value="4">{this.renderTablePanel("Expenses")}</TabPanel>
                        <TabPanel value="5">{this.renderTablePanel("Equity")}</TabPanel>
                    </TabContext>
                </Box>


            </React.Fragment>
        )
    }
}

export default LedgerTable;
