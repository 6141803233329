// page name
export const TRANSACTION_PAGE_NAME = "Transactions"
export const ADD_TRANSACTION_PAGE_NAME = "Add Transaction"
export const EDIT_TRANSACTION_PAGE_NAME = "Edit Transaction"
export const TRANSACTION_SETTINGS_PAGE_NAME = "Transaction Categories"
export const TRANSACTION_CALENDER_PAGE_NAME = "Invoices Due"
export const TRANSACTION_INVOICE_PAGE_NAME = "Pending Invoices"

// transaction pages
export const TRANSACTION_PAGES = {
    TABLE: "table",
    UPDATE: "update",
    PREVIEW: "preview",
    SETTINGS: "settings",
    CALENDER: "calender",
    INVOICE: "invoice",
}

// tooltip names
export const SETTINGS = "Settings"
export const CALENDER = "Calender"
export const ADD_CATEGORY = "Add Category"
export const ADD_TRANSACTION = "Add Transaction"
export const IMPORT_TRANSACTION = "Import Transaction"
export const RUN_TRANSACTIONS_RULES = "Run Transactions Rules"
export const SAVE_TRANSACTION = "Save"
export const GO_TO_PARENT_TRANSACTION = "Go To Parent Transaction"
export const EDIT_TRANSACTION = "Edit"
export const BACK = "Back to Menu"
export const ZOOM_OUT = "Zoom Out"
export const ZOOM_IN = "Zoom In"
export const DELETE = "Delete"
export const ASSIGN_CATEGORY = "Assign Category"
export const ADD_TAX_INFO = "Add Tax Info"
export const CANCEL = "Cancel"
export const CONFIRM_DELETE = "Confirm Delete"
export const SHOW_UNCATEGORIZED_ONLY = "Show Uncategorized Only"

export const SHOW_USD = "USD"
export const SHOW_CAD = "CAD"

export const SHOW_CONSOLIDATED_CAD = "Consolidated CAD"

// transaction table cells
export const TRANSACTION_FIELDS = [
    {id: 'date', label: 'Date', align: "center"},
    {id: 'description', label: 'Description', align: "center"},
    {id: 'bankAccount', label: 'Bank Account', align: "center"},
    {id: 'type', label: 'Type', align: "center"},
    {id: 'category', label: 'Category', align: "center"},
    {id: 'amount', label: 'Amount', align: "center"},
    {id: 'taxInfo', label: 'Tax', align: "center"},
    {},
    {}
]

export const TRANSACTION_TYPE = [
    {"name": "Debit"},
    {"name": "Credit"},
];


export const CURRENCY_TYPE_OPTIONS = [
    "CAD",
    "USD",
];

// transaction category table cells
export const TRANSACTION_CATEGORY_HEAD_CELLS = [
    { id: 'name', label: 'Name', fieldType: "Textfield", options: null }
]

export const TRANSACTION_TAX_TYPES = [
    "HST", "PST", "QST", "GST"
]

export const NA_PROVINCES = [
    // Canadian Provinces and Territories
    "Alberta", "British Columbia", "Manitoba", "New Brunswick", "Newfoundland and Labrador",
    "Northwest Territories", "Nova Scotia", "Nunavut", "Ontario", "Prince Edward Island",
    "Quebec", "Saskatchewan", "Yukon",

    // U.S. States
    "Alabama", "Alaska", "Arizona", "Arkansas", "California", "Colorado", "Connecticut", "Delaware",
    "Florida", "Georgia", "Hawaii", "Idaho", "Illinois", "Indiana", "Iowa", "Kansas", "Kentucky",
    "Louisiana", "Maine", "Maryland", "Massachusetts", "Michigan", "Minnesota", "Mississippi",
    "Missouri", "Montana", "Nebraska", "Nevada", "New Hampshire", "New Jersey", "New Mexico",
    "New York", "North Carolina", "North Dakota", "Ohio", "Oklahoma", "Oregon", "Pennsylvania",
    "Rhode Island", "South Carolina", "South Dakota", "Tennessee", "Texas", "Utah", "Vermont",
    "Virginia", "Washington", "West Virginia", "Wisconsin", "Wyoming"
];

export const PARENT_REFERENCE_TYPES  = [
    "Carrier Invoice",
    "FTL Invoice",
    "Supplier Invoice",
    "Split Transaction"
]

export const CHILD_REFERENCE_TYPES  = [
    "Supplier Invoice",
    "Carrier Invoice",
    "FTL/LTL Invoice"
]

export const FTL_INVOICE = "FTL/LTL Invoice"
export const CARRIER_INVOICE = "Carrier Invoice"
export const SUPPLIER_INVOICE = "Supplier Invoice"
export const SPLIT_TRANSACTION = "Split Transaction"
export const STANDARD_CARRIER = "Standard Carrier"