export const INVENTORY = "Inventory";
export const UNITS_ON_HAND = "Units on Hand";
export const NUM_BOXES = "Number of Boxes";
export const NUM_SKIDS = "Number of Skids";
export const THRESHOLDS = "Threshold";
export const DAYS_OF_COVERAGE = "Days of Coverage";
export const EXPECTED_END_OF_DAY = "Expected End of Day"
export const FILTER = 'Filter';
export const FILTER_DIALOG_TITLE = "Filters";
export const TYPE = "Type";
export const DATE = "Date";
export const FTL = "FTL";
export const RECEIVED = "Received";


export const INVENTORY_TYPE_LIST = [
    'Damage Replacement',
    'Damage Return',
    'Damaged',
    'FTL Houston',
    'Initial',
    'Received',
    'Repackaged',
    'Returned',
    'Salvaged',
    'Shipped',
    'VC Shipped',
    "FBA Shipped",
    "Loan",
    'Sample',
    'Adjustment'];

export const FILTER_OPTIONS = [
    DAYS_OF_COVERAGE
]

export const EQUALS = "equals"
export const LESS_THAN = "is less than"
export const GREATER_THAN = "is greater than"
export const NUMERIC_COMPARISON_OPTIONS = [
    EQUALS,
    LESS_THAN,
    GREATER_THAN,
]

export const MANUAL_INVENTORY_ADJUSTMENT = "MANUAL_INVENTORY_ADJUSTMENT";
export const CREATE_MANUAL_INVENTORY_TITLE = "Inventory Entry";
export const ADD_SHORTCODE = "Add Shortcode";

export const TORONTO_DC_SHIPADDRESS_ID = "505e48c0-616a-496e-aec8-cfc35b1f1f94"; // TODO dont hardcode fetch from backend

export const SHORTCODE = "Shortcode";

export const SET_INVENTORY_THRESHOLD = "Set thresholds for specific inventory items";
export const INVENTORY_THRESHOLD_DIALOG_TITLE = "Set An Inventory Threshold";

export const SHOW_THRESHOLDS = "Show thresholds"

export const SHOW_OUT_OF_STOCK = "Show out of stock inventory"
export const SHOW_INSUFFICIENT_STOCK_ONLY = "Show insufficient stock only"
export const SHOW_LOW_INVENTORY_ONLY = "Show low inventory only"

export const OUT_OF_STOCK = "Out of Stock"
export const INSUFFICIENT_STOCK = 'Insufficient Stock'
export const LOW_INVENTORY = "Low Inventory"

export const NO_RUNNING_RATE_MESSAGE = ""
export const NO_THRESHOLD_MESSAGE = ""

export const DAY_FILTER_GAP_LIMIT_ERROR_PARTA = "The date range cannot exceed "
export const DAY_FILTER_GAP_LIMIT_ERROR_PARTB = " day(s) "

export const TO_DATE_BEFORE_FROM_DATE_ERROR = "To date should be equal or later than from date"