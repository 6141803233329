import React from 'react'
import withShipment from "../../../withShipment";
import SearchableTable from "../../global/Search/SearchableTable";
import {FormControlLabel, IconButton, Slide, TablePagination} from "@material-ui/core";
import {
    APPROVE_STATUS, CARRIER_TYPES, DEFAULT_SORT_BY_HEAD_CELL,
    NEEDS_REVIEW_STATUS,
    PAID_STATUS,
    REPROCESSING_STATUS,
    ROWS_PER_PAGE_LABEL
} from "../carrierInvoices/constants";
import Checkbox from "@material-ui/core/Checkbox";
import Tooltip from "@material-ui/core/Tooltip";
import FilterIcon from "../../shipments/FilterIcon";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import InvoiceFiltersDialog from "../InvoiceFiltersDialog";
import CustomTableHeader from "../../global/Table/CustomTableHeader";
import Table from "@material-ui/core/Table";
import GlobalTableHead from "../../global/Table/GlobalTableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import VisibilityIcon from "@material-ui/icons/Visibility";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import TwoStepDelete from "../../global/subcomponents/twoStepDelete/TwoStepDelete";
import TableContainer from "@material-ui/core/TableContainer";
import {ASC, DESC} from "../../global/constants";
import {FTL_CARRIER_INVOICE_TABLE_HEAD_CELLS} from "./constant";
import FilterTagsBar from "../../shipments/FilterTagsBar";
import EditIcon from "@material-ui/icons/Edit";
import FTLCarrierInvoiceForm from "./FTLCarrierInvoiceForm";
import PDFViewer from "../../global/PDFViewer";
import {CreditCard} from "@material-ui/icons";


class FTLCarrierInvoiceTable extends SearchableTable {
    constructor(props) {
        super(props);
        this.state = {
            invoices: [],
            filtersMap: {},
            showFilters: false,
            isSearch: false,
            searchValue: "",
            order: ASC,
            orderBy: DEFAULT_SORT_BY_HEAD_CELL,
            fromDate: this.getFromDate(),
            toDate: this.getToDate(),
            lastSearchingFiltersMap:{},
            lastSearchingFromDate:this.getFromDate(),
            lastSearchingToDate:this.getToDate(),
            deleteId: "",           // for tracking which invoice the user wants to delete
            allSelected: false,
            editing: false,
            editingInvoice: null,
            selectedStatus: [NEEDS_REVIEW_STATUS, APPROVE_STATUS, PAID_STATUS, REPROCESSING_STATUS],
        }
    }

    async componentDidMount() {
        let storedFiltersMap = localStorage.getItem("FTLCarrierInvoiceFiltersMap");
        storedFiltersMap = (storedFiltersMap && storedFiltersMap !== "undefined") ? JSON.parse(storedFiltersMap) : {};

        // Check if storedFiltersMap is an empty object by checking the length of its keys array
        const isFiltersMapEmpty = Object.keys(storedFiltersMap).length === 0;

        const fromDate = isFiltersMapEmpty ? this.getFromDate() : storedFiltersMap.fromDate;
        const toDate = isFiltersMapEmpty ? this.getToDate() : storedFiltersMap.toDate;

        this.setState({
            searchValue: this.props.searchText,
            filtersMap: storedFiltersMap,
            lastSearchingFiltersMap: storedFiltersMap,
            invoices: this.props.data,
            fromDate: fromDate,
            toDate: toDate,
            lastSearchingFromDate: fromDate,
            lastSearchingToDate: toDate,
        });

        this.props.fetchCarrierInvoices({
            fromDate: fromDate || this.getFromDate(),
            toDate: toDate || this.getToDate(),
        })
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
        if (prevProps.data !== this.props.data ) {
            this.setState({invoices: this.props.data})
        }
        if (prevState.filtersMap && prevState.filtersMap !== this.state.filtersMap) {
            localStorage.setItem("FTLCarrierInvoiceFiltersMap",JSON.stringify(this.state.filtersMap))
            const fromDate = this.state.filtersMap?.fromDate || this.getFromDate()
            const toDate =  this.state.filtersMap?.toDate || this.getToDate()

            if (fromDate !== this.state.fromDate || toDate !== this.state.toDate) {
                this.setState({
                    fromDate: fromDate,
                    toDate: toDate,
                })
            }
        }
    }

    handleRequestSort = (event, property) => {
        const isAsc = this.state.orderBy === property && this.state.order === ASC;
        this.setState({
            order: isAsc ? DESC : ASC,
            orderBy: property,
        });
    };

    getNewDates = () => {
        let filter = {...this.state.filtersMap}

        if (filter) {
            this.setState({
                filtersMap: filter,
            })
        }
    }

    saveUpdatedStatus = (filters) => {
        delete filters['fromDate']
        delete filters['toDate']
        this.props.saveTableSetting({
            userId: this.props.user.userId,
            tableSetting: null,
            changeFilters: false,
            changeFTLCarrierInvoiceFilters: true,
            ftlCarrierInvoiceFilterSetting: filters
        })
    }

    getFromDate = () => {
        var date = new Date();
        date.setHours(0);
        date.setMinutes(0);
        date.setSeconds(0);
        date.setDate(date.getDate() - 14)
        return date;
    }

    getToDate = () => {
        var date = new Date();
        date.setHours(23);
        date.setMinutes(59);
        date.setSeconds(59);
        return date;
    }

    applyOverviewFilters = (rows) => {
        if (this.state.selectedStatus) {
            rows = rows.filter((item) =>
                this.state.selectedStatus
                    .map(status => status.toLowerCase())
                    .includes(item.status.toLowerCase())
            );

        }
        const carriers = this.state.filtersMap?.carriers;
        if (carriers) {
            rows = rows.filter(row => { //TODO
                let carrierFilterMap = this.props.carriers?.map(s => ({carrierString: s.name + ", " + s.accountNumber, carrierId: s.carrierId}))
                carrierFilterMap = carrierFilterMap.filter(carrierFilterMapEntry => this.state.filtersMap.carriers.includes(carrierFilterMapEntry.carrierString))
                return carrierFilterMap?.find(carrierFilterMapEntry => carrierFilterMapEntry.carrierId === row.carrierId)
            })
        }

        return rows;
    }

    toggleFiltersDialog = () => {
        this.setState({
            showFilters: !this.state.showFilters,
            filtersMapBeforeOpenDialog: { ...this.state.filtersMap }
        })
    }

    getDisplayed = (rows) => {
        if (!rows)
            return [];
        return [...rows.slice(this.props.page * this.props.rowsPerPage, (this.props.page + 1) * this.props.rowsPerPage)]
    }

    numSelected(checkedEntries) {
        let rows = this.getDisplayed(this.stableSort(this.applyOverviewFilters(this.filterBySearch(this.state.invoices)), this.getComparator(this.state.order, this.state.orderBy)))
        return rows.reduceRight((accum, curr) => accum + (checkedEntries[curr.invoiceId] ?? 0), 0)
    }

    selectAll = (e) =>{
        this.setState({
            allSelected: !this.state.allSelected
        })
        let newChecked = {}
        let allEntries = this.getDisplayed(this.stableSort(this.filterBySearch(this.applyOverviewFilters(this.state.invoices)), this.getComparator(this.state.order, this.state.orderBy)))
        allEntries.map((entry) => newChecked[entry.invoiceId] = this.state.allSelected)
        this.props.setSelected(newChecked, !!this.numSelected(newChecked))
    }

    numSelected(checkedEntries) {
        let rows = this.getDisplayed(this.stableSort(this.applyOverviewFilters(this.filterBySearch(this.state.invoices)), this.getComparator(this.state.order, this.state.orderBy)))
        return rows.reduceRight((accum, curr) => accum + (checkedEntries[curr.invoiceId] ?? 0), 0)
    }

    onFiltersInputChange = (name, val) => {
        const filtersMap = { ...this.state.filtersMap };

        // if filter already exists, then update
        if (val !== "" && val.length !== 0) {
            filtersMap[name] = val;
        }
        else {
            delete filtersMap[name];
        }

        this.setState({
            filtersMap: filtersMap,
        });

    }

    isDefaultFromDate = (someDate) => {
        const defaultFromDate = this.getFromDate()
        return someDate.getDate() == defaultFromDate.getDate() &&
            someDate.getMonth() == defaultFromDate.getMonth() &&
            someDate.getFullYear() == defaultFromDate.getFullYear()
    }

    isToday = (someDate) => {
        const today = new Date()
        return someDate.getDate() == today.getDate() &&
            someDate.getMonth() == today.getMonth() &&
            someDate.getFullYear() == today.getFullYear()
    }

    onFiltersClose = (applyFilters, saveFilterSetting) => {
        // NOTE: changes are already applied as the user makes these changes,
        // but if the changes are cancelled, then the map is reverted to its

        // if user click on apply
        if (applyFilters) {
            this.props.fetchCarrierInvoices({
                fromDate:this.state.fromDate|| this.getFromDate(),
                toDate:this.state.toDate|| this.getToDate(),
            })
            this.setState({
                showFilters: false,
                lastSearchingFromDate: this.state.fromDate,
                lastSearchingToDate: this.state.toDate,
                lastSearchingFiltersMap: this.state.filtersMap
            })
        }
        else {
            this.setState({
                showFilters: false,
                filtersMap: this.state.lastSearchingFiltersMap,
                fromDate: this.state.lastSearchingFromDate,
                toDate: this.state.lastSearchingToDate,
            })
        }
    }

    handleDateChange = (date, key) => {
        const filtersMap = { ...this.state.filtersMap };
        if (key === 'fromDate' && !this.isDefaultFromDate(date) ||
            key === 'toDate' && !this.isToday(date)) {
            filtersMap[key] = date;
        } else {
            delete filtersMap[key]
        }
        this.setState({
            filtersMap: filtersMap,
            [key]: date
        })
    }

    onSearchValueChange = (e) => {

        this.setState({
            isSearch: e.target.value.length > 0,
            searchValue: e.target.value,
        });
        this.props.handleSearchChange(e)
    };

    handleCheck(e, invoice) {
        let newEntries = { ...this.props.checkedEntries, [invoice.invoiceId]: e.target.checked }
        this.props.setSelected(newEntries, !!this.numSelected(newEntries))
    }

    handleDelete() {
        this.props.deleteInvoice({ carrierInvoiceId: this.state.deleteId, carrierType: this.props.carrierType })
        this.setState({
            deleteId: ""
        })
    }
    handleTagClick = (event, tag) => {
        const filtersMap = { ...this.state.filtersMap };

        delete filtersMap[tag];
        this.setState({
            filtersMap:filtersMap
        },() =>{
            if (tag === "fromDate" || tag === "toDate") {
                this.props.fetchCarrierInvoices({
                    fromDate: filtersMap.fromDate || this.getFromDate(),
                    toDate: filtersMap.toDate || this.getToDate(),
                })
            }
        })
    }

    handleEdit = (invoice) => {
        this.props.setEditing(invoice)
    }

    formatAmount = (amount) => {
        if (typeof amount !== 'string' && typeof amount !== 'number') {
            return amount;
        }
        const num = parseFloat(amount);
        if (!isNaN(num)) {
            return new Intl.NumberFormat('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            }).format(num);
        }
        return amount;
    }

    handleStatusChange = (event) => {
        let filters = { ...this.state.filtersMap };
        let checked = event.target.checked
        let name = event.target.name
        let statuses = []
        if (checked) {
            statuses = [...this.state.selectedStatus, name]

            this.setState({
                selectedStatus: statuses
            });
        }
        // remove from the list
        else {
            let index = this.state.selectedStatus.indexOf(name)
            let selected = this.state.selectedStatus
            selected.splice(index, 1)
            statuses = selected
            this.setState({
                selectedStatus: selected
            });
        }
        // filters.selectedStatus = statuses

        // this.saveUpdatedStatus(filters);

        // this.onFiltersInputChange(event);
    }

    handleChangePage = (event, newPage) => {
        this.props.setPage(newPage)
    };

    handleChangeRowsPerPage = (event) => {
        this.props.setRowsPerPage(event.target.value)
        this.props.setPage(0)
    };

    render() {
        let viewOnly = !this.props.user.routes.includes('carrier-invoices-edit')
        return (
            <>
                {this.props.viewingPdf ?
                    <PDFViewer data={this.props.invoicePDF} scale={this.props.scale} />
                    : !this.props.editing ?
                    <div>
                        <div className='filter-tag-wrapper'></div>
                        <FilterTagsBar
                            filtersMap={this.state.filtersMap}
                            onClick={this.handleTagClick}
                        />
                        <CustomTableHeader
                            searchText={this.props.searchText}
                            onSearchValueChange={this.onSearchValueChange}
                            pagination={
                                <TablePagination
                                    className="table-pagination"
                                    labelRowsPerPage={ROWS_PER_PAGE_LABEL}
                                    rowsPerPageOptions={[5, 25, 50, 100, {value: this.state.invoices.length, label: "All"} ]}
                                    count={!this.state.invoices ? 0 : this.filterBySearch(this.applyOverviewFilters(this.state.invoices)).length}
                                    rowsPerPage={this.props.rowsPerPage}
                                    page={this.props.page}
                                    onChangePage={this.handleChangePage}
                                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                />
                            }
                            customFilterField={
                                <>
                                    <div>
                                        <FormControlLabel
                                            control={<Checkbox
                                                checked={this.state.selectedStatus ? this.state.selectedStatus.includes(NEEDS_REVIEW_STATUS) : false}
                                                onChange={this.handleStatusChange} name={NEEDS_REVIEW_STATUS}
                                                style={{color: '#acd685'}}/>}
                                            label={NEEDS_REVIEW_STATUS}
                                        />
                                        <FormControlLabel
                                            control={<Checkbox
                                                checked={this.state.selectedStatus ? this.state.selectedStatus.includes(APPROVE_STATUS) : false}
                                                onChange={this.handleStatusChange} name={APPROVE_STATUS}
                                                style={{color: '#acd685'}}/>}
                                            label={APPROVE_STATUS}
                                        />
                                        <FormControlLabel
                                            control={<Checkbox
                                                checked={this.state.selectedStatus ? this.state.selectedStatus.includes(PAID_STATUS) : false}
                                                onChange={this.handleStatusChange} name={PAID_STATUS}
                                                style={{color: '#acd685'}}/>}
                                            label={PAID_STATUS}
                                        />
                                        <FormControlLabel
                                            control={<Checkbox
                                                checked={this.state.selectedStatus ? this.state.selectedStatus.includes(REPROCESSING_STATUS) : false}
                                                onChange={this.handleStatusChange} name={REPROCESSING_STATUS}
                                                style={{color: '#acd685'}}/>}
                                            label={REPROCESSING_STATUS}
                                        />
                                    </div>
                                </>
                            }
                            filterIcon={
                                <Tooltip title={'Filter'} style={{ paddingTop: 10 }}>
                                    <IconButton className="filterButton" size='small' aria-label={'Filter'} onClick={this.toggleFiltersDialog} >
                                        <FilterIcon />
                                    </IconButton>
                                </Tooltip>
                            }
                            locationField={
                                <Autocomplete
                                    style={{width: '220px', marginLeft: '28px', marginBottom: '10px'}}
                                    options={CARRIER_TYPES}
                                    autoHighlight={true}
                                    className="carrierTypes"
                                    onChange={(event, value) => {this.props.changeCarrierType(value) }}
                                    value={this.props.carrierType}
                                    name="issue"
                                    disableClearable={true}
                                    renderInput={(params) =>
                                        <TextField
                                            {...params}
                                            label="Carrier Type"
                                            InputLabelProps={{ shrink: true }}
                                            variant="outlined"
                                            name="issueType"
                                            required
                                        />
                                    }
                                />
                            }
                            showFilters={this.state.showFilters}
                            filtersComponent={
                                <Slide in={this.state.showFilters} mountOnEnter unmountOnExit timeout={300}>
                                    <InvoiceFiltersDialog
                                        open={this.state.showFilters}
                                        onClose={this.onFiltersClose}
                                        onChange={this.onFiltersInputChange}
                                        listItems={this.props.carriers.map(s => s.name + ", " + s.accountNumber)}
                                        handleDateChange={this.handleDateChange}
                                        filtersMap={this.state.filtersMap}
                                        clearFilters={this.clearFiltersMap}
                                        fromDate={this.state.fromDate}
                                        toDate={this.state.toDate}
                                        fieldName={'carriers'}
                                        fieldTitle={'Carrier'}
                                    />
                                </Slide>}
                        />
                        <TableContainer>
                            <Table size='medium'>
                                <GlobalTableHead
                                    isCreatePurchaseOrderTable={false}
                                    isShipmentTable={false}
                                    isPurchaseOrderTable={false}
                                    headCells={FTL_CARRIER_INVOICE_TABLE_HEAD_CELLS}
                                    onSelectAllClick={this.selectAll}
                                    numSelected={this.numSelected(this.props.checkedEntries)}
                                    isInvoiceTable={true} // might cause issues later since isInvoiceTable was intended for supplier invoices
                                    order={this.state.order}
                                    orderBy={this.state.orderBy}
                                    onRequestSort={this.handleRequestSort}
                                    rowCount={!this.state.invoices ? 0 : this.state.invoices.length}
                                    disableCheckbox={viewOnly}
                                />
                                <TableBody>
                                    {this.getDisplayed(this.stableSort(this.filterBySearch(this.applyOverviewFilters(this.state.invoices)), this.getComparator(this.state.order, this.state.orderBy))).map((invoice, invoiceIndex) => (
                                        <TableRow hover onDoubleClick={() => this.props.handleView(invoice)} key={"invoice-" + invoiceIndex}>
                                            <TableCell>
                                                <IconButton size="small" disabled={viewOnly} onClick={() => this.handleEdit(invoice)}>
                                                    <EditIcon />
                                                </IconButton>
                                            </TableCell>
                                            <TableCell style={{ padding: "0.2rem" }}>
                                                <Checkbox
                                                    checked={this.props.checkedEntries[invoice.invoiceId] ?? false}
                                                    onChange={(e) => this.handleCheck(e, invoice)}
                                                    disabled={viewOnly}
                                                />
                                            </TableCell>
                                            {FTL_CARRIER_INVOICE_TABLE_HEAD_CELLS.map((headCell, index) => (
                                                <TableCell
                                                    style={{
                                                        textAlign: headCell.rightAlign ? "right" : (headCell.id === "totalShipments"|| headCell.id === "previouslyBilledShipments" || headCell.id === "failedShipments" ||  headCell.id === "status" ? "center" : "left"),
                                                        padding: "5px",
                                                        paddingRight: headCell.rightAlign ? "50px" : "5px",
                                                        width: headCell.id === "status" ? "200px"  : "auto",
                                                        color: (headCell.id === "status" && (invoice.status === NEEDS_REVIEW_STATUS) || (headCell.id === "failedShipments" && (invoice.failedShipments > 0))) ? "red"
                                                            : (headCell.id === "status" && invoice.status === REPROCESSING_STATUS) ? "grey" : "black",
                                                    }}
                                                    key={index}
                                                >
                                                    {(headCell.id == "invoiceTax" || headCell.id == "invoiceAmount") ?
                                                        <div style={{
                                                            display: 'flex',
                                                            justifyContent: 'flex-start', // Aligns children (items) at the start of the container.
                                                            alignItems: 'center'
                                                        }}>
                                                            <span style={{flexShrink: 0}}>$</span>
                                                            <span style={{marginLeft: 'auto'}}>{invoice[headCell.id] == "" ? "-" : this.formatAmount(invoice[headCell.id])}</span>
                                                        </div>

                                                        :

                                                        invoice[headCell.id]
                                                    }

                                                    {(headCell.id === 'status' && invoice[headCell.id] === NEEDS_REVIEW_STATUS) && !viewOnly ?
                                                        <IconButton aria-label='info'>
                                                            <Tooltip
                                                                title={invoice.errorMessage ? invoice.errorMessage.length > 0 ?
                                                                    invoice.errorMessage.map(errorText =>(
                                                                        <div style={{display: 'block', margin: '5px'}}>{errorText}</div>
                                                                    ))
                                                                    : "Error Message Not Available" : "Error Message Not Available"
                                                                }
                                                                enterDelay={50}
                                                            >
                                                                <ErrorOutlineIcon variant='outlined' fontSize='small' />
                                                            </Tooltip>
                                                        </IconButton>
                                                        : null
                                                    }

                                                    {(headCell.id === 'paymentInfo' && invoice.referenceId && invoice.paymentDate && invoice?.bankAccount?.accountNumber ) ?
                                                        <IconButton aria-label='info'>
                                                            <Tooltip
                                                                title={
                                                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                                        <div className="invoice-details">
                                                                            <div className="invoice-details-label">Reference ID:</div>
                                                                            <div className="invoice-details-value">{
                                                                                invoice.referenceId
                                                                            }</div>
                                                                        </div>
                                                                        <div className="invoice-details">
                                                                            <div className="invoice-details-label">Payment Date:</div>
                                                                            <div className="invoice-details-value">{
                                                                                invoice.paymentDate
                                                                            }</div>
                                                                        </div>
                                                                        <div className="invoice-details">
                                                                            <div className="invoice-details-label">Bank Account:</div>
                                                                            <div className="invoice-details-value">{
                                                                                invoice.bankAccount.name + " " + invoice.bankAccount.accountNumber
                                                                            }</div>
                                                                        </div>
                                                                    </div>
                                                                }
                                                                enterDelay={50}
                                                            >
                                                                <CreditCard variant='outlined' fontSize='small'/>
                                                            </Tooltip>
                                                        </IconButton>
                                                        : null
                                                    }
                                                </TableCell>
                                            ))}

                                            <TwoStepDelete
                                                rowId={invoice.invoiceId}
                                                handleDelete={(rowIdToDelete) => this.handleDelete()}
                                                deleteId={this.state.deleteId}
                                                setDeleteId={(newDeleteId) => this.setState({deleteId: newDeleteId})}
                                                disabled={viewOnly}
                                            />
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                    :
                    <FTLCarrierInvoiceForm
                        bankAccounts={this.props.bankAccounts}
                        editingInvoice={this.props.editingInvoice}
                        editSelectedInvoice={this.props.editSelectedInvoice}
                    />
                }
            </>
        )
    }
}

const actionCreators = {

}

export default withShipment({
    actionCreators
}, FTLCarrierInvoiceTable);