export const CANCEL = 'CANCEL';
export const RATE = 'RATE';
export const RATE_ALL = 'RATE ALL';
export const COMPARE = 'COMPARE';
export const COMPARE_ALL = 'COMPARE ALL';
export const APPLY = 'APPLY';
export const SET_SERVICE = 'Set Service';

export const NAME = 'name';
export const VALUE = 'value';
export const RATES = 'rates';
export const PACKAGERATES ='packageRates'

export const CARRIER_SERVICE = 'Carrier Service';

export const UNABLE_TO_RATE_THIS_SERVICE = 'Unable to rate this service';

export const ACCOUNT = 'Account';
export const LOCATION = 'Location';
export const INBOUND = 'Inbound';
export const OUTBOUND = 'Outbound';
export const SERVICE = 'Service';

export const FEDEX_RATES = [
    "Base Rate",
    "Discounts",
    "Fuel Surcharge",
    "Rural Surcharge",
    "Taxes",
    "Rate Total"
]

export const BUY_SHIPPING_SERVICES_TABLE_HEAD_CELLS = [
    {
        id: 'carrierName',
        label: "Carrier"
    },
    {
        id: 'serviceName',
        label: "Service"
    },
    {
        id: 'estimatedDeliveryDate',
        label: 'Estimated Delivery Date'
    },
    {
        id: 'rateAmount',
        label: "Rate"
    },
    {
        id: 'rateCurrency',
        label: "Currency"
    },
    {
        id: '',
        label: "Select"
    },
]