import React from "react";
import withShipment from "../../../withShipment";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import TopToolbar from "../../global/subcomponents/topToolbar/TopToolbar";
import PlaylistPlayIcon from '@material-ui/icons/PlaylistPlay';
import {
    CALENDER,
    ADD_TRANSACTION,
    TRANSACTION_PAGE_NAME,
    IMPORT_TRANSACTION,
    TRANSACTION_PAGES,
    EDIT_TRANSACTION,
    TRANSACTION_FIELDS,
    DELETE,
    CANCEL,
    CONFIRM_DELETE,
    SETTINGS,
    ASSIGN_CATEGORY,
    SHOW_UNCATEGORIZED_ONLY,
    ADD_TAX_INFO,
    RUN_TRANSACTIONS_RULES,
    SHOW_USD,
    SHOW_CAD,
    TRANSACTION_TYPE,
} from './constants';
import TablePage from "../../global/Table/TablePage";
import {Filter} from "../../global/Table/TablePageHelpers/FilterObject";
import {Layout} from "../../global/Table/TablePageHelpers/LayoutObject";
import {
    AddTaxInfo,
    applyTransactionsRules,
    assignCategory,
    deleteTransactions,
    importTransaction
} from "../../../redux/actions/accounting";
import {processingSelector} from "../../../redux/selectors/accounting";
import {
    Box,
    Chip,
    FormControl,
    FormControlLabel,
    InputLabel,
    MenuItem,
    Select,
    TableCell,
    Typography
} from "@mui/material";
import {
    Checkbox,
    IconButton,
    Tooltip
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import "./Transactions.css"
import { EditNote, Upload } from '@mui/icons-material';
import TwoStepDelete from "../../global/subcomponents/twoStepDelete/TwoStepDelete";
import {CreditCard, HourglassEmptyTwoTone} from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import ClearIcon from "@material-ui/icons/Clear";
import SettingsIcon from "@material-ui/icons/Settings";
import ImportTransactionsPopper from "./ImportTransactionsPopper";
import CategoryIcon from "@material-ui/icons/Category";
import LibraryAddIcon from '@mui/icons-material/LibraryAdd';
import CategorizeTransactionsPopper from "./CategorizeTransactionsPopper";
import AddTaxInformationPopper from "./AddTaxInformationPopper";
import AutomationRuleDialog from "../../shipments/AutomationRuleDialog";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import CircleIcon from "@mui/icons-material/Circle";
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import {
    SHOW_INSUFFICIENT_STOCK_ONLY,
    SHOW_LOW_INVENTORY_ONLY,
    SHOW_OUT_OF_STOCK,
    SHOW_THRESHOLDS
} from "../../inventory/constants";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import MenuIcon from '@material-ui/icons/Menu';
import EditRelatedProducts from '../../pricingDashboard/pricingDashboardPages/EditRelatedProducts';
import EditNotes from './EditNotes';

class TransactionsTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      deleting: false,
      deleteId: '',
      selectedEntries: [],
      isSelecting: false,
      selectedBankAccount: null,
      openRuleDialog: false,
      selectedRules: [],
      showEditNotes: false,
    };
    this.hiddenTransactionInput = React.createRef();
    this.importTransaction = this.importTransaction.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.transactions &&
      this.props.transactions.length > 0 &&
      this.props.selectedCategory &&
      this.props.selectedCategory.length > 0 &&
      (prevProps.transactions !== this.props.transactions ||
        prevProps.selectedCategory !== this.props.selectedCategory)
    ) {
      //this.calculateTotalAmount();
    }
  }

  runRules = () => {
    const data = {
      transactionIds: this.state.selectedEntries,
      rules: this.state.selectedRules,
    };

    this.props.applyTransactionsRules(
      this.state.selectedEntries,
      this.state.selectedRules
    );
    this.setState(
      {
        openRuleDialog: false,
        selectedRules: [],
      },
      () => {
        this.setState({
          selectedEntries: [],
          isSelecting: false,
        });
      }
    );
  };

  toggleEditNotesShow = () => {
    this.setState({ showEditNotes: !this.state.showEditNotes });
  };

  getPageName = () => {
    let rightHeaderButtons = [
      {
        icon: <MenuIcon />,
        handler: this.toggleEditNotesShow,
        disabled: false,
        title: 'Edit Notes',
      },
    ];
    return (
      <div>
        {TRANSACTION_PAGE_NAME}
        {rightHeaderButtons &&
          rightHeaderButtons.map((button, index) => (
            <Tooltip title={button.title} key={button.title + index}>
              <IconButton
                aria-label={button.title}
                onClick={button.handler}
                disabled={button.disabled}
              >
                {button.icon}
              </IconButton>
            </Tooltip>
          ))}
      </div>
    );
  };

  getContextualMenuItems = () => {
    let menuItems = [];
    if (!this.state.isSelecting) {
      menuItems.push(
        {
          title: SETTINGS,
          icon: <SettingsIcon />,
          onClick: () =>
            this.props.setCurrentPage(TRANSACTION_PAGES.SETTINGS, {}),
        },
        {
          title: ADD_TRANSACTION,
          icon: <AddCircleIcon />,
          disabled: this.props.bankAccounts.length < 1,
          onClick: () =>
            this.props.setCurrentPage(TRANSACTION_PAGES.UPDATE, {}),
        },
        {
          title: IMPORT_TRANSACTION,
          icon: !this.props.processing ? <Upload /> : <HourglassEmptyTwoTone />,
          disabled: this.props.bankAccounts.length < 1 || this.props.processing,
          popper: (
            <ImportTransactionsPopper
              bankAccounts={this.props.bankAccounts}
              callback={(selectedBankAccount) =>
                this.uploadImportTransactionFile(selectedBankAccount)
              }
            />
          ),
        },
        {
          title: CALENDER,
          icon: <CalendarMonthIcon />,
          onClick: () =>
            this.props.setCurrentPage(TRANSACTION_PAGES.CALENDER, {}),
        }
      );
    }

    if (this.state.isSelecting) {
      if (!this.state.deleting) {
        menuItems.push({
          title: DELETE,
          icon: <DeleteIcon />,
          onClick: () => this.setState({ deleting: true }),
        });
        menuItems.push({
          title: ASSIGN_CATEGORY,
          icon: <CategoryIcon />,
          popper: (
            <CategorizeTransactionsPopper
              categories={this.props.categories}
              callback={(selectedCategory) =>
                this.props.assignCategory(
                  selectedCategory,
                  this.state.selectedEntries,
                  () => {
                    this.setState({
                      selectedEntries: [],
                      isSelecting: false,
                    });
                  }
                )
              }
            />
          ),
        });
        menuItems.push({
          title: ADD_TAX_INFO,
          icon: <LibraryAddIcon />,
          popper: (
            <AddTaxInformationPopper
              callback={(taxInfo) =>
                this.props.AddTaxInfo(
                  taxInfo,
                  this.state.selectedEntries,
                  () => {
                    this.setState({
                      selectedEntries: [],
                      isSelecting: false,
                    });
                  }
                )
              }
            />
          ),
        });
        menuItems.push({
          title: RUN_TRANSACTIONS_RULES,
          icon: <PlaylistPlayIcon />,
          disabled: this.props.bankAccounts.length < 1 || this.props.processing,
          onClick: () => this.setState({ openRuleDialog: true }),
        });
      } else {
        menuItems.push({
          title: CANCEL,
          icon: <ClearIcon />,
          onClick: () => this.setState({ deleting: false }),
        });
        menuItems.push({
          title: CONFIRM_DELETE,
          icon: <DeleteIcon />,
          onClick: () => this.handleDeleteTransactions(),
        });
      }
    }

    return menuItems;
  };

  getTableHeaders = () => {
    let headers = [];
    headers.push(Layout.columnHead(null, null, null));
    headers.push(Layout.columnHead(null, 'selectAllCheckbox', null, null));
    TRANSACTION_FIELDS.forEach((headCell) => {
      if (headCell.id === 'taxInfo' || headCell.id === 'amount') {
        headers.push(
          Layout.columnHead(headCell.label, headCell.id, headCell.align)
        );
      } else {
        headers.push(Layout.columnHead(headCell.label, headCell.id));
      }
    });
    headers.push(Layout.columnHead(null, null, null));
    return headers;
  };

  isSelected = (item) => this.state.selectedRules.indexOf(item) !== -1;

  handleSelect = (ruleName) => {
    const selectedIndex = this.state.selectedRules.findIndex(
      (item) => item === ruleName
    );

    let selectedRules = [...this.state.selectedRules];

    if (selectedIndex === -1) {
      selectedRules = [...selectedRules, ruleName];
    } else {
      selectedRules.splice(selectedIndex, 1);
    }
    this.setState({
      selectedRules,
    });
  };

  handleFormattingTaxInfo = (taxInfos) => {
    return taxInfos
      .map((info) => `${info.province}: ${info.taxType} - $${info.taxAmount}`)
      .join('\n');
  };

  handleReferencesInfo = (references, taxInfos) => {
    return `Reference Id: ${references}`;
  };
  tableRow = (transaction) => {
    const totalTax =
      transaction.taxInfos?.reduce((sum, info) => {
        const taxAmount = info.taxAmount
          ? info.taxAmount.replace(/,/g, '')
          : '0';
        return sum + parseFloat(taxAmount);
      }, 0) || 0;

    return (
      <React.Fragment>
        <TableCell
          style={transaction.type === 'manual' ? { borderBottom: 'none' } : {}}
        >
          {transaction.type !== 'manual' ? (
            <IconButton
              size="small"
              onClick={() =>
                this.props.setCurrentPage(TRANSACTION_PAGES.UPDATE, transaction)
              }
            >
              <EditIcon />
            </IconButton>
          ) : null}
        </TableCell>
        <TableCell
          align="center"
          style={
            transaction.type === 'manual'
              ? { borderBottom: 'none' }
              : { padding: '0.2rem' }
          }
        >
          {transaction.type !== 'manual' ? (
            <FormControlLabel
              control={
                <Checkbox
                  checked={this.state.selectedEntries.includes(
                    transaction.transactionId
                  )}
                  onChange={() =>
                    this.handleSelectTransaction(transaction.transactionId)
                  }
                  style={{ marginTop: '8px' }}
                />
              }
            />
          ) : (
            <div style={{ height: '40px' }}>&nbsp;</div>
          )}
        </TableCell>
        <TableCell
          className="table-cell-left"
          style={transaction.type === 'manual' ? { borderBottom: 'none' } : {}}
        >
          {transaction?.date?.split('T')[0]}
        </TableCell>
        <TableCell
          className="table-cell-left"
          style={transaction.type === 'manual' ? { borderBottom: 'none' } : {}}
        >
          {transaction?.description}
        </TableCell>
        <TableCell
          className="table-cell-left"
          style={transaction.type === 'manual' ? { borderBottom: 'none' } : {}}
        >{`${transaction?.bankAccount?.name ?? ''} ${
          transaction?.bankAccount?.accountNumber
            ? `(${transaction?.bankAccount?.accountNumber})`
            : ''
        }`}</TableCell>
        <TableCell
          className="table-cell-left"
          style={transaction.type === 'manual' ? { borderBottom: 'none' } : {}}
        >
          {transaction.type !== 'manual' ? transaction?.type : null}
        </TableCell>
        <TableCell
          className="table-cell-left"
          style={transaction.type === 'manual' ? { borderBottom: 'none' } : {}}
        >
          {transaction?.category}
        </TableCell>

        <TableCell
          className="table-cell-left"
          style={transaction.type === 'manual' ? { borderBottom: 'none' } : {}}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              gap: '3px',
              marginRight: '15px',
            }}
          >
            <span style={{ flexShrink: 0 }}>$</span>
            <span style={{ marginLeft: 'auto' }}>
              {transaction?.amount.replace(
                /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                ','
              )}
            </span>
          </div>
        </TableCell>

        <TableCell
          className="table-cell-left"
          style={transaction.type === 'manual' ? { borderBottom: 'none' } : {}}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              gap: '3px',
              marginRight: '15px',
            }}
          >
            <span style={{ flexShrink: 0 }}>$</span>
            <span style={{ marginLeft: 'auto' }}>
              {transaction.type === 'manual'
                ? transaction.tax
                : totalTax
                    .toFixed(2)
                    .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')}
            </span>
          </div>
        </TableCell>

        <TableCell
          className="table-cell-right"
          style={transaction.type === 'manual' ? { borderBottom: 'none' } : {}}
        >
          {transaction.references?.length > 0 && (
            <IconButton aria-label="close" onClick={this.props.closeDrawer}>
              <PlaylistAddCheckIcon />
            </IconButton>
          )}
        </TableCell>

        <TableCell
          className="table-cell-left"
          style={transaction.type === 'manual' ? { borderBottom: 'none' } : {}}
        >
          {transaction.taxInfos?.length > 0 && (
            <Tooltip
              title={
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <div className="invoice-details">
                    <div className="invoice-details-label">
                      {' '}
                      {transaction.referenceId ? 'Reference ID:' : null}{' '}
                    </div>
                    <div className="invoice-details-value">
                      {transaction.referenceId}
                    </div>
                  </div>

                  <div className="tooltip-transaction">
                    {transaction.taxInfos.map((info) => (
                      <div
                        key={info.province}
                        className="tax-info-row-tooltip-transaction"
                      >
                        <div className="tax-info-tooltip-transaction">
                          {info.province} ({info.taxType}):
                        </div>
                        <div className="tax-amount-tooltip-transaction">
                          ${info.taxAmount}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              }
              enterDelay={50}
            >
              <InfoOutlinedIcon />
            </Tooltip>
          )}
        </TableCell>

        {transaction.type !== 'manual' ? (
          <TwoStepDelete
            rowId={transaction.transactionId}
            handleDelete={(rowIdToDelete) => this.handleDeleteTransactions()}
            deleteId={this.state.deleteId}
            setDeleteId={(newDeleteId) =>
              this.setState({ deleteId: newDeleteId })
            }
          />
        ) : (
          <TableCell style={{ borderBottom: 'none' }}></TableCell>
        )}
      </React.Fragment>
    );
  };

  handleSelectTransaction = (transactionId) => {
    const { selectedEntries } = this.state;
    let index = selectedEntries.indexOf(transactionId);
    if (index === -1) {
      selectedEntries.push(transactionId);
    } else {
      selectedEntries.splice(index, 1);
    }

    const isSelecting = selectedEntries.length > 0;

    this.setState({ selectedEntries, isSelecting });
  };

  handleSelectAllTransactions = (displayedTableRows) => {
    const { selectedEntries } = this.state;

    const allTransactionIds = displayedTableRows.map(
      (transaction) => transaction.transactionId
    );
    const missingTransactionIds = allTransactionIds.filter(
      (transactionId) => !selectedEntries.includes(transactionId)
    );

    let updatedSelectedEntries = [];
    if (missingTransactionIds.length > 0) {
      updatedSelectedEntries = [...selectedEntries, ...missingTransactionIds];
    }

    const isSelecting = updatedSelectedEntries.length > 0;

    this.setState({ selectedEntries: updatedSelectedEntries, isSelecting });
  };

  searchStrings = (transaction) => {
    return [
      transaction.date?.split('T')[0] ?? '',
      transaction.description ?? '',
      transaction.bankAccount?.name?.toString() ?? '',
      transaction.amount ?? '',
      transaction.amount
        ?.toString()
        .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',') ?? '',
      transaction.category ?? '',
      transaction.notes ?? '',
      transaction.referenceId ?? '',
    ];
  };

  handleDeleteTransactions = () => {
    let transactionsToDelete = [];
    if (this.state.deleteId) {
      transactionsToDelete.push(this.state.deleteId);
    } else if (this.state.selectedEntries.length > 0) {
      transactionsToDelete.push(...this.state.selectedEntries);
    }

    this.props.deleteTransactions(
      { transactions: transactionsToDelete },
      () => {
        this.setState({
          deleteId: '',
          deleting: false,
          isSelecting: false,
          selectedEntries: [],
        });
      }
    );
  };

  uploadImportTransactionFile = (selectedBankAccount) => {
    this.setState({ selectedBankAccount: selectedBankAccount });
    this.hiddenTransactionInput.current.click();
  };

  importTransaction = (e) => {
    if (e.target.files.length === 0) return;
    let file = e.target.files[0];
    let fileReader = new FileReader();

    fileReader.onload = (e) => {
      let contents = new Uint8Array(e.target.result);
      this.props.importTransaction(
        {
          content: Array.from(contents),
          bankAccount: this.state.selectedBankAccount,
        },
        () => {
          this.setState({ selectedBankAccount: null });
        }
      );
    };
    fileReader.readAsArrayBuffer(file);
  };

  render() {
    return (
      <React.Fragment>
        <input
          type="file"
          accept="text/csv"
          ref={this.hiddenTransactionInput}
          hidden
          onChange={this.importTransaction}
        />
        <TopToolbar
          pageName={this.getPageName()}
          menuItems={this.getContextualMenuItems()}
        />
        <AutomationRuleDialog
          open={this.state.openRuleDialog}
          handleClose={() =>
            this.setState({ openRuleDialog: false, selectedRules: [] })
          }
          rules={this.props.rules}
          orders={this.props.selectedEntries}
          runRules={this.runRules}
          selectedRules={this.state.selectedRules}
          ruleGroup={'Transactions'}
          isSelected={this.isSelected}
          handleSelect={this.handleSelect}
        />
        <div
          className="table-page"
        >
          <TablePage
            selectedCurrency={this.props.selectedCurrency}
            handleCurrencyChange={this.props.handleCurrencyChange}
            type="transactionTable"
            className="transaction-table"
            tableName="TransactionsTable"
            tableRows={this.props.transactions}
            tableColumnHeads={this.getTableHeaders()}
            tableRow={this.tableRow}
            handleCategory={this.props.handleCategory}
            handleSelectAll={this.handleSelectAllTransactions}
            numSelectedEntries={this.state.selectedEntries.length}
            paginationText="Transactions per page"
            hasSearchField
            searchStrings={this.searchStrings}
            defaultSortBy="date"
            filters={[
              Filter.createFilter(
                'type',
                (row) => row.type,
                null,
                Filter.relations.containedBy,
                true
              ),
              Filter.createFilter(
                'bankAccount',
                (row) => row.bankAccount,
                null,
                Filter.relations.containedBy,
                true
              ),
              Filter.createFilter(
                'category',
                (row) => row.category,
                null,
                Filter.relations.containedBy,
                true
              ),
              Filter.createFilter(
                'fromDate',
                (row) => row.date,
                null,
                Filter.relations.greaterThanEquals,
                true,
                Filter.displayValueOverrides.valueIsDate,
                new Date(
                  new Date().getFullYear(),
                  new Date().getMonth(),
                  new Date().getDate() - 7
                ).toISOString()
              ),
              Filter.createFilter(
                'toDate',
                (row) => row.date,
                null,
                Filter.relations.lessThanEquals,
                true,
                Filter.displayValueOverrides.valueIsDate,
                new Date().toISOString()
              ),
              Filter.createFilter(
                'showUncategorizedOnly',
                (row) =>
                  row.category === null ||
                  row.category === '' ||
                  row.category === 'Uncategorized',
                false,
                Filter.relations.equals,
                false
              ),
            ]}
            filterCheckboxes={[
              Layout.checkBox('showUncategorizedOnly', SHOW_UNCATEGORIZED_ONLY),
            ]}
            customFilterField={
              <React.Fragment>
                <div style={{ fontSize: '25px', paddingRight: '18px' }}>|</div>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.props.onlyInsufficientStock}
                      onChange={this.props.handleOnlyInsufficientStockChange}
                      icon={<CircleOutlinedIcon />}
                      checkedIcon={<CircleIcon />}
                    />
                  }
                  label={SHOW_INSUFFICIENT_STOCK_ONLY}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.props.onlyLowInventory}
                      onChange={this.props.handleOnlyLowInventoryChange}
                      icon={<CircleOutlinedIcon />}
                      checkedIcon={<CircleIcon />}
                    />
                  }
                  label={SHOW_LOW_INVENTORY_ONLY}
                />
              </React.Fragment>
            }
            filterDropdown={Layout.newLayout(2, [
              Layout.row([
                Layout.rowElement(
                  1,
                  'bankAccount',
                  'Account',
                  Layout.elementStyle.checkboxDropdown,
                  this.props.bankAccounts.map(
                    (account) => `${account.name} (${account.accountNumber})`
                  )
                ),
                Layout.rowElement(
                  1,
                  'type',
                  'Type',
                  Layout.elementStyle.checkboxDropdown,
                  ['Debit', 'Credit']
                ),
              ]),
              Layout.row([
                Layout.rowElement(
                  2,
                  'category',
                  'Category',
                  Layout.elementStyle.checkboxDropdown,
                  this.props.categories.map((category) => `${category.name}`)
                ),
              ]),
              Layout.row([
                Layout.rowElement(
                  1,
                  'fromDate',
                  'From Date',
                  Layout.elementStyle.dateSelect
                ),
                Layout.rowElement(
                  1,
                  'toDate',
                  'To Date',
                  Layout.elementStyle.dateSelect
                ),
              ]),
            ])}
          />
        </div>

        {this.state.showEditNotes && (
          <EditNotes
            saveNote={this.props.saveNote}
            note={this.props.note || ''}
            showEditNotes={this.state.showEditNotes}
          />
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
    processing: processingSelector(state),
})

const actionCreators = {
    deleteTransactions,
    assignCategory,
    importTransaction,
    AddTaxInfo,
    applyTransactionsRules,
}

export default withShipment({
    actionCreators,
    mapStateToProps
}, TransactionsTable);