import React from 'react'


// Material UI
import {
    Button,
    Slide,
    TextField,
} from "@material-ui/core";
import './EditNotes.css';

class EditNotes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      noteId: this.props.note?.noteId ? this.props.note?.noteId : null,
      notes: this.props.note?.notes ? this.props.note?.notes : '',
    };
  }

  handleClear = () => {
    this.setState({
      notes: '',
    });
  };

  saveChanges = () => {
    this.props.saveNote({
      noteId: this.state.noteId,
      type: 'Transactions',
      notes: this.state.notes,
    });
  };

  handleNotesChange = (event) => {
    this.setState({
      notes: event.target.value,
    });
  };

  render() {
    return (
      <>
        <div style={{ overflow: 'hidden' }}>
          <div
            className="notesSidebarHolder"
            style={{ width: this.props.showEditNotes ? '22%' : '0' }}
          >
            <Slide in={this.props.showEditNotes} direction="left">
              <div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'left',
                    marginLeft: '16px',
                    marginRight: '16px',
                    marginTop:'16px'
                  }}
                >
                  <div className="notesTransactionContainer">
                    <TextField
                      variant="outlined"
                      name="notes"
                      label="Notes"
                      InputLabelProps={{ shrink: true }}
                      value={this.state.notes || ''}
                      onChange={(e) => this.handleNotesChange(e)}
                      style={{ width: 'inherit' }}
                      multiline
                      minRows={4}
                      maxRows={Infinity}
                    />
                  </div>
                </div>
                <div
                  className="bottom-wrapper"
                  style={{ alignItems: 'center' }}
                >
                  <Button
                    variant="contained"
                    className="submit-button"
                    onClick={this.handleClear}
                  >
                    CLEAR
                  </Button>
                  <div style={{ width: '30px' }}></div>
                  <Button
                    color="primary"
                    variant="contained"
                    className="submit-button"
                    onClick={this.saveChanges}
                  >
                    SAVE
                  </Button>
                </div>
              </div>
            </Slide>
          </div>
        </div>
      </>
    );
  }
}

export default EditNotes