import React from "react";
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import ClearIcon from '@material-ui/icons/Clear';
import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import Chip from "@material-ui/core/Chip";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import Autocomplete from "@material-ui/lab/Autocomplete";

class PurchaseOrderFiltersDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false
        };
    }

    render() {
        return (
            <>
                <div>
                    <DialogTitle id="form-dialog-title">
                        <div className={'filters-title'}>
                            <div>
                                Filters
                            </div>
                            <div>
                                <Button className="clearFiltersButton" startIcon={<ClearIcon />}
                                    onClick={this.props.clearFilters}>CLEAR FILTERS</Button>
                            </div>
                        </div>
                    </DialogTitle>
                    <DialogContent className={"dialogBox"}>
                        <Autocomplete
                            multiple
                            open={this.state.open}
                            value={this.props.suppliers ?? []} // Ensure this holds the selected values
                            options={this.props.supplierOptions}
                            renderTags={(value) =>
                                value.map((option) => (
                                    <Chip
                                        variant="outlined"
                                        size="small"
                                        style={{ borderColor: "#acd685", margin: "1px 6px 1px 0", fontSize: "12px" }}
                                        label={option}
                                    />
                                ))
                            }
                            getOptionLabel={(entry) => entry}
                            style={{ width: 300, marginLeft: 10, marginBottom: 20 }}
                            renderOption={(option, { selected }) => (
                                <React.Fragment>
                                    <Checkbox
                                        icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                        checkedIcon={<CheckBoxIcon fontSize="small" />}
                                        style={{ marginRight: 8 }}
                                        checked={selected}
                                    />
                                    {option}
                                </React.Fragment>
                            )}
                            onOpen={() => this.setState({ open: true })}
                            onClose={(event, reason) => this.setState({ open: reason !== "blur" })}
                            name="supplier"
                            onChange={(event, value) => this.props.handleSupplierChange(value)}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Supplier"
                                    InputLabelProps={{ shrink: true }}
                                    variant="outlined"
                                    name="supplier"
                                />
                            )}
                        />

                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                autoOk
                                name="fromDate"
                                variant="inline"
                                inputVariant="outlined"
                                label="From"
                                format="yyyy-MM-dd"
                                value={this.props.fromDate}
                                InputAdornmentProps={{ position: "start" }}
                                onChange={date => {
                                    this.props.handleDateChange("fromDate", date)
                                }}
                                style={{ width: 300, marginLeft: 10, marginBottom: 20 }}
                            />
                            <KeyboardDatePicker
                                autoOk
                                name="toDate"
                                variant="inline"
                                inputVariant="outlined"
                                label="To"
                                format="yyyy-MM-dd"
                                // value={this.getToDate()}
                                value={this.props.toDate}
                                InputAdornmentProps={{ position: "start" }}
                                onChange={date => {
                                    this.props.handleDateChange("toDate", date)
                                }}
                                style={{ width: 300, marginLeft: 10, marginBottom: 20 }}
                            />
                        </MuiPickersUtilsProvider>
                    </DialogContent>
                    <DialogActions style={{ justifyContent: "center", marginBottom: 10 }}>
                        <Button variant='contained' onClick={() => this.props.onClose(false)}>Cancel</Button>
                        <Button variant='contained' onClick={() => this.props.onClose(true)} color="primary">Apply</Button>
                    </DialogActions>
                </div>
            </>
        )
    }
}

export default PurchaseOrderFiltersDialog;