import SearchableTable from "../../global/Search/SearchableTable";
import {
    DEFAULT_DATE_FORMAT,
    DEFAULT_ROWS_PER_PAGE,
    DEFAULT_SORT_BY_HEAD_CELL,
    INVOICE_TEMPLATE_DATA_FIELDS,
    INVOICE_TEMPLATE_DATA_SUB_FIELDS,
    INVOICE_TEMPLATE_TABLE_HEAD_CELLS,
    ROWS_PER_PAGE_LABEL
} from "../../settings/InvoiceTemplates/supplierInvoiceTemplates/constants";
import {ASC, DESC} from "../../global/constants";
import PropTypes from "prop-types";
import TemplateTable from "../../settings/InvoiceTemplates/supplierInvoiceTemplates/TemplateTable";
import EditInvoiceTemplate from "../../settings/InvoiceTemplates/supplierInvoiceTemplates/EditInvoiceTemplate";
import CustomTableHeader from "../../global/Table/CustomTableHeader";
import {IconButton, TableBody, TablePagination} from "@material-ui/core";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import GlobalTableHead from "../../global/Table/GlobalTableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import Button from "@material-ui/core/Button";
import React from "react";
import {CARRIER_INVOICE_TEMPLATE_FIELDS, EDITING_TEMPLATE} from "./constant";


class FTLCarrierInvoiceTemplateTable extends SearchableTable {
    constructor(props) {
        super(props);
        this.state = {
            page: 0,
            rowsPerPage: DEFAULT_ROWS_PER_PAGE,
            order: ASC,
            orderBy: DEFAULT_SORT_BY_HEAD_CELL,
            searchValue: "",
            deleteId: -1,
            searchText: "", // the value in the search field (updates on change)
            isSearch: false, // true if search value is not empty, false otherwise
            searchRows: 0, // number of rows displayed while search criteria is met
        }
        this.onSearchValueChange = this.onSearchValueChange.bind(this)
    }

    handleRequestSort = (event, property) => {
        const isAsc = this.state.orderBy === property && this.state.order === ASC;
        this.setState({
            order: isAsc ? DESC : ASC,
            orderBy: property,
        });
    };
    handleChangeRowsPerPage = (event) => {
        this.setState({
            rowsPerPage: parseInt(event.target.value, 10),
            page: 0,
        });
    };

    handleChangePage = (event, newPage) => {
        this.setState({
            page: newPage,
        });
    };
    onSearchValueChange = (e) => {
        this.setState({
            isSearch: e.target.value.length > 0,
            searchValue: e.target.value,
            searchText: e.target.value,
            page: 0,
        });
    }
    getDisplayed = (rows) => {
        if (!rows)
            return [];
        return [...rows.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)];
    }
    handleDelete = (template) => {
        this.props.deleteInvoiceTemplate(template.carrierId)
        this.setState({deleteIndex: ""})
    }

    handleEditTemplate(template) {
        let fields = JSON.parse(JSON.stringify(CARRIER_INVOICE_TEMPLATE_FIELDS))
        Object.keys(template.data).map(key => {
            if (key in fields) {
                fields[key].position = {...template.data[key]}
            }
        })
        fields.marker.position = {...template.marker.coordinates}
        this.props.clearPDFTemplate([])
        this.props.getPDFTemplate(template.carrierId)
        this.props.updateField("fields", fields)
        this.props.updateField("selectedCarrier", template.carrierId)
        this.props.updateField("curState", EDITING_TEMPLATE)
        this.props.updateField("entryTaxText", template.entryTaxText)
        this.props.setDateFormat(template.dateFormat ?? DEFAULT_DATE_FORMAT)
    }

    render() {
        return (
            <>
                <>
                    <CustomTableHeader
                        searchText={this.state.searchText}
                        onSearchValueChange={this.onSearchValueChange}
                        pagination={
                            <TablePagination
                                className="table-pagination"
                                labelRowsPerPage={ROWS_PER_PAGE_LABEL}
                                rowsPerPageOptions={[5, 25, 50, 100]}
                                count={!this.props.data ? 0 : this.filterBySearch(this.props.data).length}
                                rowsPerPage={this.state.rowsPerPage}
                                page={this.state.page}
                                onChangePage={this.handleChangePage}
                                onChangeRowsPerPage={this.handleChangeRowsPerPage}
                            />
                        }
                        showSupplier={this.props.showSupplier}
                    />
                    <TableContainer>
                        <Table size='medium'>
                            <GlobalTableHead
                                isCreatePurchaseOrderTable={false}
                                isShipmentTable={false}
                                headCells={INVOICE_TEMPLATE_TABLE_HEAD_CELLS}
                                order={this.state.order}
                                orderBy={this.state.orderBy}
                                onRequestSort={this.handleRequestSort}
                                rowCount={!this.props.data ? 0 : this.props.data.length}
                            />
                            <TableBody>
                                {this.getDisplayed(this.stableSort(this.filterBySearch(this.props.data), this.getComparator(this.state.order, this.state.orderBy))).map((template, index) => (
                                    <TableRow hover onDoubleClick={()=>this.handleEditTemplate(template)} key={index}>
                                        <TableCell>
                                            <IconButton onClick={()=>this.handleEditTemplate(template)}>
                                                <EditIcon/>
                                            </IconButton>
                                        </TableCell>
                                        <TableCell className='cellPadding'>
                                            {template.carrierName}
                                        </TableCell>
                                        <TableCell className='cellPadding'>
                                            {template.createDate}
                                        </TableCell>
                                        <TableCell className='cellPadding'>
                                            {template.lastUpdatedDate}
                                        </TableCell>
                                        <TableCell style={{width: "25%", padding: "5px"}}>
                                            {this.state.deleteId !== index ?
                                                <IconButton onClick={() => this.setState({ deleteId: index })}>
                                                    <DeleteIcon />
                                                </IconButton> : <div>
                                                    <Button onClick={() => this.setState({deleteId: ""})}>
                                                        Cancel
                                                    </Button>
                                                    <Button onClick={() => this.handleDelete(template)} variant="contained" startIcon={<DeleteIcon/>}>
                                                        Delete
                                                    </Button>
                                                </div>
                                            }
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </>
            </>
        )
    }
}

FTLCarrierInvoiceTemplateTable.propTypes = {
    data: PropTypes.array
}

FTLCarrierInvoiceTemplateTable.defaultProps = {
    data: []
}

export default FTLCarrierInvoiceTemplateTable;