import React from "react";
import ErrorFeedbackBar from "../../global/ErrorFeedbackBar";
import FeedbackBar from "../../feedbackBar/FeedbackBar";
import withShipment from "../../../withShipment";
import {statusSelector} from "../../../redux/selectors/global";
import {clearStatus} from "../../../redux/actions/global";
import LedgerTable from "./LedgerTable";
import {LEDGERS_PAGES} from "./constants";
import EditLedger from "./EditLedger";
import {
    ledgerSelector, loadingSelector, shippingSettingSelector,
    transactionCategoriesSelector,
    transactionLedgerSelector
} from "../../../redux/selectors/accounting";
import {
    deleteEntry,
    deleteLedger,
    deleteLedgerByYear,
    fetchTransactionsLedger, ledgerEntries,
    listAllLedgers, listShippingSettings,
    listTransactionCategories,
    saveLedger, saveShippingSummarySettings,
    saveTransactionsLedger, updateEntry, updateLedger
} from "../../../redux/actions/accounting";
import {userInfoSelector} from "../../../redux/selectors/auth";
import LedgerExchangeRatesSetting from "./LedgerExchangeRatesSetting";

class Ledger extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentPage: LEDGERS_PAGES.TABLE,
            feedbackBarOpen: false,
            error: false,
            transactionCategories:this.props.transactionCategories,
            ledgerEditing: {},
            years: ['2023','2024','2025'],
            selectedYear: "2024",
            currency: ['CAD', 'USD', "Consolidated CAD"],
            selectedCurrency: 'CAD',
            purchaseSummary: this.props.purchaseSummary,
            shippingSummary: this.props.shippingSummary,
            carriers:[],
            accountNumbers: null,
            ftlSummary: this.props.ftlSummary,
        }
    }

    componentDidMount() {
        this.props.listShippingSettings()
        this.props.listAllLedgers({currency: this.state.selectedCurrency, year: this.state.selectedYear})
        this.props.listTransactionCategories()

    }


    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.status.statusText && prevProps.status.statusText !== this.props.status.statusText){
            this.openFeedbackBar()
        }

        if (prevProps.ledgerEditing!== this.props.ledgerEditing && this.props.ledgerEditing) {
            this.setState({
                ledgerEditing: this.props.ledgerEditing
            })
        }

        //update state transactionCategories
        if (prevProps.transactionCategories !== this.props.transactionCategories) {
            this.setState({
                transactionCategories : this.props.transactionCategories
            })
        }
        //sort state transactionCategories by displayOrder
        if (prevState.transactionCategories !== this.state.transactionCategories) {
            this.setState({
                transactionCategories : this.state.transactionCategories.sort((a, b) => a.displayOrder - b.displayOrder)
            })
        }

    }


    handleChange = (event) => {
        if(this.state.currentPage === 'Edit Ledgers'){
            this.setState({  selectedCurrency: event.target.value },()=>{
                const data = {ledgerId: this.state.ledgerEditing.ledgerId, year: this.state.selectedYear,  currency: this.state.selectedCurrency}
                this.props.ledgerEntries(data)

            })
        }else{
            this.setState({ selectedCurrency: event.target.value },()=>{
                this.props.listAllLedgers({ currency: this.state.selectedCurrency, year: this.state.selectedYear})
            })
        }

    }

    handleSave = (ledgers) => {
      this.props.saveLedger({ledgers:ledgers, currency: this.state.selectedCurrency, year: this.state.selectedYear})
      this.setCurrentPage(LEDGERS_PAGES.TABLE, {})
    }

     getLastTransactionBalance = (transactions) => {
        if (transactions.length === 0) return null;
        const lastTransaction = transactions[transactions.length - 1];
        return lastTransaction.balance;
    };

    handleSaveTransactionsLedger = (transactions,ledgerId) => {
        const lastBalance = this.getLastTransactionBalance(transactions)
        const entry = {
            year: this.state.selectedYear,
            entries: transactions.map(transaction => ({
                date: transaction.date,
                balance: parseFloat(transaction.balance),
                description: transaction.description,
                debit: parseFloat(transaction.debit),
                credit: parseFloat(transaction.credit),
                currency: this.state.selectedCurrency,
                transactionId: transaction.transactionId,
                note: transaction.note || ''
            })),
            balance: lastBalance // convert to number
        };

        const data = { ledgerEntries: entry, ledgerId: ledgerId, currency: this.state.selectedCurrency, year: this.state.selectedYear}
       this.props.saveTransactionsLedger(data,()=>{
           const ledgerData = {ledgerId: ledgerId, year: this.state.selectedYear,  currency: this.state.selectedCurrency}
           this.props.ledgerEntries(ledgerData)
       })

       // this.setCurrentPage(LEDGERS_PAGES.TABLE, {})
    }

    handleYearChange = (event) => {
        if(this.state.currentPage === 'Edit Ledgers'){
            this.setState({ selectedYear: event.target.value },()=>{
                const data = {ledgerId: this.state.ledgerEditing.ledgerId, year: this.state.selectedYear,  currency: this.state.selectedCurrency}
                this.props.ledgerEntries(data)
            })
        }else{
            this.setState({ selectedYear: event.target.value },()=>{
                this.props.listAllLedgers({ currency: this.state.selectedCurrency, year: this.state.selectedYear})
            })
        }

    }


    setCurrentPage = (newPage,data) => {
        this.setState({
            currentPage: newPage,
            ledgerEditing: data,
        })
    }


    displayTable = () => {
            return (
                <LedgerTable
                    categories={this.state.transactionCategories}
                    listLedgers={this.props.listAllLedgers}
                    deleteLedgerByYear={this.props.deleteLedgerByYear}
                    years={this.state.years}
                    selectedYear={this.state.selectedYear}
                    handleYearChange={this.handleYearChange}
                    ledgers={this.props.ledgers}
                    setCurrentPage={this.setCurrentPage}
                    openFeedbackBar={this.openFeedbackBar}
                    currency={this.state.currency}
                    selectedCurrency={this.state.selectedCurrency}
                    handleChange={this.handleChange}
                    deleteLedger={this.props.deleteLedger}
                    updateLedger={this.props.updateLedger}
                    settings={this.props.shippingSettings}
                />
            )
    }

    displaySettings = () => {
        return (
            <LedgerExchangeRatesSetting
                handleSaveSettings={this.handleSaveSettings}
                settings={this.props.shippingSettings}
                setCurrentPage={this.setCurrentPage}
                openFeedbackBar={this.openFeedbackBar}
                listAllLedgers={this.props.listAllLedgers}
                selectedYear={this.state.selectedYear}
                selectedCurrency={this.state.selectedCurrency}
            />
        )

    }

    displayEditLedger = () => {
        return (
            <EditLedger
                loadingData={this.props.loadingData}
                listLedgers={this.props.listAllLedgers}
                years={this.state.years}
                handleYearChange={this.handleYearChange}
                selectedYear={this.state.selectedYear}
                ledger={this.state.ledgerEditing}
                handleSave={this.handleSaveTransactionsLedger}
                setCurrentPage={this.setCurrentPage}
                openFeedbackBar={this.openFeedbackBar}
                fetchTransactionsLedger={this.props.fetchTransactionsLedger}
                fetchTransactions={this.props.fetchTransactions}
                carrierSummary={this.props.fetchTransactions.carrierSummary}
                supplierSummary={this.props.fetchTransactions.supplierSummary}
                ftlSummary={this.props.fetchTransactions.ftlSummary}
                stockSummary={this.props.fetchTransactions.stockSummary}
                ledgerEntries={this.props.ledgerEntries}
                currency={this.state.currency}
                selectedCurrency={this.state.selectedCurrency}
                handleChange={this.handleChange}
                settings={this.props.shippingSettings}
                updateEntry={this.props.updateEntry}
                deleteEntry={this.props.deleteEntry}
                categories={this.state.transactionCategories}
            />
        )
    }

    displayCurrentPage = () => {
        switch (this.state.currentPage) {
            case LEDGERS_PAGES.TABLE:
                return this.displayTable()
            case LEDGERS_PAGES.EDIT:
                return this.displayEditLedger()
            case LEDGERS_PAGES.SETTINGS:
                return this.displaySettings()
            default:
                console.log("Error: current transactions page doesn't exist")
                return <div>We encountered an error. Please refresh the page.</div>
        }
    }

    openFeedbackBar = () => {
        this.setState({feedbackBarOpen: true})
    }

    closeFeedbackBar = () => {
        this.setState({ feedbackBarOpen: false }, () => {
            this.props.clearStatus()
        })
    }

    handleSaveSettings = (rates) => {
        this.props.saveShippingSummarySettings(rates)
        this.props.listAllLedgers({currency: this.state.selectedCurrency, year: this.state.selectedYear})
        this.setCurrentPage(LEDGERS_PAGES.TABLE, {})
    }

    render() {
        return (
            <React.Fragment>
                <ErrorFeedbackBar/>
                <FeedbackBar
                    open={this.state.feedbackBarOpen}
                    handleClose={this.closeFeedbackBar}
                    severity={this.props.status?.success ? 'success' : 'error'}
                    message={this.props.status?.statusText}
                />
                {this.displayCurrentPage()}
            </React.Fragment>
        )
    }
}


const mapStateToProps = (state) => ({
    status: statusSelector(state),
    transactionCategories: transactionCategoriesSelector(state),
    ledgers: ledgerSelector(state),
    fetchTransactions: transactionLedgerSelector(state),
    shippingSettings: shippingSettingSelector(state),
    loadingData: loadingSelector(state),
    user: userInfoSelector(state),

})


const actionCreators = {
    clearStatus,
    listTransactionCategories,
    listAllLedgers,
    saveLedger,
    saveTransactionsLedger,
    fetchTransactionsLedger,
    deleteLedgerByYear,
    ledgerEntries,
    deleteLedger,
    updateLedger,
    saveShippingSummarySettings,
    listShippingSettings,
    updateEntry,
    deleteEntry
}

export default withShipment({
    actionCreators,
    mapStateToProps
}, Ledger);